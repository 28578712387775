<!-- We need to change this in the next sprint -->
<template>
	<payment-processed-new />
</template>

<script>
export default {
	components: {
		PaymentProcessedNew: () => import('../../components/PaymentResponses/PaymentProcessedNew.vue')
	}
};
</script>
