<template>
	<v-container>
		<div class="text-h3">Account Registration</div>
		<v-avatar color="#f8faf5" size="200">
			<v-icon size="100" color="green darken-2" v-if="success">mdi-check</v-icon>
			<v-icon size="100" color="red darken-2" v-else>mdi-close</v-icon>
		</v-avatar>
		<div class="body-1">{{ message }}</div>
	</v-container>
</template>

<script>
import _ from 'lodash';

export default {
	data() {
		return {
			loading: false,
			message: null,
			success: false
		};
	},
	props: {
		transactionId: { type: String, required: true },
		data: { type: String, required: true },
		websiteUrl: { type: String, required: true }
	},
	methods: {
		handleResponse() {
			if (this.data) {
				const { success, message } = JSON.parse(this.data);
				this.success = success;
				this.message = this.handleErrorMessages(this.success, message);
				if (success || _.includes(message, 'email')) {
					window.open(this.websiteUrl);
				}
			}
		},
		handleErrorMessages(success, message) {
			if (success) {
				return 'your account was created, please check your mail for credentials';
			}
			if (this.isWordInString('email', message) || message.includes('email')) {
				this.success = true;
				return 'your account already exists, please login on the website';
			}
			if (this.isWordInString('phone', message) || message.includes('phone')) {
				this.success = true;
				return 'we apologize, but we cant create the account, phone number already exists.';
			}
			return 'we apologize but we had an issue opening your account \n\n please contact support.';
		},
		isWordInString(needle, haystack) {
			return haystack.split(' ').some((p) => p === needle);
		}
	},
	created() {
		this.handleResponse();
	}
};
</script>
<style scoped>
iframe {
	width: 100%;
	height: 500px;
}
</style>
