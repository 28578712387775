<template>
	<v-container>
		<v-row align="center">
			<v-col cols="12" align="center" style="margin-top:25px;margin-bottom:10px;">
				<transition name="fade">
					<v-progress-circular indeterminate color="green" size="120"></v-progress-circular>
				</transition>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			consent: null,
			error: null
		};
	},
	created() {
		if (this.$route.query.consent || this.$route.query.error) {
			this.consent = this.$route.query.consent;
			this.error = this.$route.query.error;
			const targetWindow = window.opener || window.parent;
			targetWindow.postMessage({ consent: this.consent, error: this.error }, '*');
			window.close();
		}
	}
};
</script>
<style scoped>
.loader {
	width: 50px;
	height: 50px;
	border: 5px solid #000;
	border-top-color: #333;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
