export function getPaymentType(paymentPageDetails) {
	const paymentMap = {
		Paypal: 'Paypal',
		Paylivre: 'Paylivre',
		FireblocksBridge: 'FireblocksBridge',
		AccentPay: 'AccentPay',
		QrPayments: 'QrPayments',
		CryptoSites: 'CryptoSites',
		StaticInfo: 'StaticInfo',
		JccGateway: 'JccGateway',
		Stripe: 'StripeSubscription',
		Starbill: 'Starbill',
		TadaPayments: 'TadaPayments',
		PgPay: 'PgPay',
		Remitex: 'Remitex',
		DsgIntl: 'DsgIntl',
		Tap: 'Tap',
		Paysepro: 'Paysepro',
		PayRetailers: 'PayRetailers',
		MercadoEasy: 'MercadoEasy',
		Transak: 'Transak',
		Fastcoin: 'Fastcoin',
		PayO: 'PayO',
		CryptoWeb: 'CryptoWeb',
		PayEasy: 'PayEasy',
		HoleEasyPay: 'HoleEasyPay',
		PlimePay: 'PlimePay',
		Todapay: 'Todapay',
		JmFinancial: 'JmFinancial',
		Fintechcashier: 'Fintechcashier',
		Astropay: 'Astropay',
		WealthPay: 'WealthPay',
		Doku: 'Doku',
		BpWallet: 'BpWallet',
		Finteca: 'Finteca',
		Arkalion: 'Arkalion',
		TazaPay: 'TazaPay',
		Crosspay: 'CrossPay',
		CricPayz: 'CricPayz',
		Yapily: 'Yapily',
		TheVoucher: 'TheVoucher',
		GoldExchange: 'GoldExchange',
		Onramper: 'Onramper',
		Multipay: 'Multipay',
		PayFuture: 'PayFuture',
		PspPro: 'PspPro',
		Revolut: 'Revolut'
	};

	const { pspType, paymentMethodName, isHostedPage, hostedPaymentUrl, isServerToServer, type, useCardComponent } = paymentPageDetails;

	if (pspType === 'Pay42' && paymentMethodName === 'Pay42 Billet') {
		return 'Pay42Billet';
	}

	if (pspType === 'Fibonatix' && isHostedPage && hostedPaymentUrl) {
		return 'IframeDynamic';
	}

	if (pspType === 'SolidPayments') {
		return isServerToServer ? 'CreditCard' : 'SolidPayments';
	}

	if (pspType === 'SmartCashew') {
		return type === 's2s' ? 'CreditCard' : 'SmartCashew';
	}

	if (pspType === 'Aliumpay') {
		return isServerToServer ? 'CreditCard' : 'Aliumpay';
	}

	if (pspType === 'Firstaccept' && isHostedPage && hostedPaymentUrl) {
		return 'IframeDynamic';
	}

	if (pspType === 'Multipay') {
		return useCardComponent ? 'CreditCard' : 'Multipay';
	}

	return paymentMap[pspType] || 'CreditCard';
}

const getComponentsPaths = (template) => {
	return {
		CreditCard: `./PaymentTypes/CreditCard${template || ''}.vue`,
		Paypal: './PaymentTypes/Paypal.vue',
		FireblocksBridge: './PaymentTypes/FireblocksBridge.vue',
		Paylivre: './PaymentTypes/Paylivre.vue',
		AccentPay: './PaymentTypes/AccentPay.vue',
		IframeDynamic: './PaymentTypes/IframeDynamic.vue',
		PaymentProcessed: './PaymentResponses/PaymentProcessed.vue',
		PaymentRejected: './PaymentResponses/PaymentRejected.vue',
		PaymentProcessedTemplate3: './PaymentResponses/PaymentProcessedTemplate3.vue',
		PaymentRejectedTemplate3: './PaymentResponses/PaymentRejectedTemplate3.vue',
		QrPayments: './PaymentTypes/QrPayments.vue',
		CryptoSites: './PaymentTypes/CryptoSites.vue',
		StaticInfo: './PaymentTypes/StaticInfo.vue',
		Pay42Billet: './PaymentTypes/Pay42Billet.vue',
		JccGateway: './PaymentTypes/JccGateway.vue',
		StripeSubscription: './PaymentTypes/StripeSubscription.vue',
		TheStripe: './PaymentTypes/TheStripe.vue',
		Starbill: './PaymentTypes/Starbill.vue',
		TadaPayments: './PaymentTypes/TadaPayments.vue',
		PgPay: './PaymentTypes/PgPay.vue',
		ThreeDAuthenticationForm: './PaymentTypes/ThreeDAuthenticationForm.vue',
		SolidPayments: './PaymentTypes/SolidPayments.vue',
		Remitex: './PaymentTypes/Remitex.vue',
		DsgIntl: './PaymentTypes/DsgIntl.vue',
		Tap: './PaymentTypes/Tap.vue',
		Paysepro: './PaymentTypes/Paysepro.vue',
		PayRetailers: './PaymentTypes/PayRetailers.vue',
		Virtualpay: './PaymentTypes/Virtualpay.vue',
		MercadoEasy: './PaymentTypes/MercadoEasy.vue',
		Transak: './PaymentTypes/Transak.vue',
		Fastcoin: './PaymentTypes/Fastcoin.vue',
		Aliumpay: './PaymentTypes/Aliumpay.vue',
		SmartCashew: './PaymentTypes/SmartCashew.vue',
		PayO: './PaymentTypes/PayO.vue',
		CryptoWeb: './PaymentTypes/CryptoWeb.vue',
		PayEasy: './PaymentTypes/PayEasy.vue',
		SolidThreeDAuth: './PaymentTypes/SolidThreeDAuth.vue',
		HoleEasyPay: './PaymentTypes/HoleEasyPay.vue',
		PlimePay: './PaymentTypes/PlimePay.vue',
		AliumThreeDAuth: './PaymentTypes/AliumThreeDAuth.vue',
		Todapay: './PaymentTypes/Todapay.vue',
		JmFinancial: './PaymentTypes/JmFinancial.vue',
		Fintechcashier: './PaymentTypes/Fintechcashier.vue',
		Astropay: './PaymentTypes/Astropay.vue',
		WealthPay: './PaymentTypes/WealthPay.vue',
		Doku: './PaymentTypes/Doku.vue',
		BpWallet: './PaymentTypes/BpWallet.vue',
		Finteca: './PaymentTypes/Finteca.vue',
		Arkalion: './PaymentTypes/Arkalion.vue',
		TazaPay: './PaymentTypes/TazaPay.vue',
		CrossPay: './PaymentTypes/Crosspay.vue',
		CricPayz: './PaymentTypes/CricPayz.vue',
		Yapily: './PaymentTypes/Yapily.vue',
		TheVoucher: './PaymentTypes/TheVoucher.vue',
		GoldExchange: './PaymentTypes/GoldExchange.vue',
		Onramper: './PaymentTypes/Onramper.vue',
		Multipay: './PaymentTypes/Multipay.vue',
		PayFuture: './PaymentTypes/PayFuture.vue',
		PspPro: './PaymentTypes/PspPro.vue',
		Revolut: './PaymentTypes/Revolut.vue'
	};
};

const requireComponent = require.context('../components', true, /Payment(Types|Responses)\/[\w-]+\.vue$/);

function loadComponent(componentPath) {
	return requireComponent(componentPath).default;
}

export function getComponents(template) {
	const componentsPaths = getComponentsPaths(template);
	return Object.keys(componentsPaths).reduce((acc, componentName) => {
		acc[componentName] = loadComponent(componentsPaths[componentName]);
		return acc;
	}, {});
}
