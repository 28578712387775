<script>
import Vue from 'vue/dist/vue.js';
import { each } from 'lodash';

export default {
	props: {
		transactionId: { type: String, required: true },
		staticInfo: { type: String, default: null },
		response: { type: String, default: null },
		agreement: { type: String, default: null }
	},
	data() {
		return {
			fileSelector: null,
			template: Vue.compile('')
		};
	},
	render(createElement) {
		const self = this;
		const base = {
			data() {
				return {
					showContent: false,
					showAgreement: true,
					src: null,
					numPages: undefined,
					agree: false,
					internalAgreement: null
				};
			},
			mounted() {
				this.removeContentEditableAttribute();
				this.internalAgreement = _.get(this.$refs, 'agreement.value', null);
				this.showContent = _.isNil(this.internalAgreement);
				this.showAgreement = !this.showContent;
			},
			methods: {
				onAccept() {
					this.showAgreement = false;
					this.showContent = true;
				},
				onDecline() {
					this.$router.go();
				},
				onClick() {
					self.$emit('submit');
				},
				addToast(e) {
					const newDiv = document.createElement('div');
					newDiv.textContent = 'Copied!';
					newDiv.style.backgroundColor = '#000';
					newDiv.style.color = '#fff';
					newDiv.style.padding = '10px';
					newDiv.style.borderRadius = '10px';
					newDiv.style.position = 'absolute';
					newDiv.style.top = `${e.target.offsetTop - 10}px`;
					newDiv.style.left = `${e.target.offsetLeft + 20}px`;
					e.target.appendChild(newDiv);
					setTimeout(() => {
						e.target.removeChild(newDiv);
					}, 1000);
				},
				async onCopy(e) {
					if (e.target.className !== 'mdi mdi-content-copy') {
						return;
					}
					const text = e.target.parentElement.innerText.split(':')[1].trim();
					await navigator.clipboard.writeText(text);
					this.addToast(e);
				},
				onChange() {
					this.fileSelector = document.querySelector('#file');
					this.fileSelector.addEventListener('change', this.onChangeCallback);
				},
				onChangeCallback(evt) {
					const [file] = evt.target.files;
					const { name: fileName, size } = file;
					const fileSize = (size / 1000).toFixed(2);
					const fileNameAndSize = `${fileName} - ${fileSize}KB`;
					document.querySelector('.file-name').textContent = fileNameAndSize;
					self.$emit('file-selected', evt);
					const label = document.querySelector('#label');
					label.style.display = "none";
				},
				removeContentEditableAttribute() {
					this.$nextTick(() => {
						const card = document.querySelectorAll('[contenteditable="true"]');
						each(card, (c) => {
							c.removeAttribute('contenteditable');
						});
					});
				}
			},
			beforeDestroy() {
				if (this.fileSelector) {
					this.fileSelector.removeEventListener('change', this.onChangeCallback);
				}
			}
		};
		const component = { ...this.template, ...base };
		return createElement(component);
	},
	created() {
		if (_.isNil(this.agreement)) {
			this.template = Vue.compile(`<div>${this.staticInfo}</div>`);
			return;
		}
		this.template = Vue.compile(
			`<div>
	<input ref="agreement" type="hidden" value="${this.agreement}" />
	<v-card v-if="showContent">
		
		${this.staticInfo}
	</v-card>
	<v-card v-if="showAgreement" >
		<iframe src="${this.agreement}" width="100%" height="600"/>
		<v-card-actions>
			<v-checkbox v-model="agree" off-icon="mdi-checkbox-blank-outline" on-icon="mdi-checkbox-marked-outline" color="green">
				<template v-slot:label>
					<div>{{ $t('paymentProcessed.iAgree') }}</div>
				</template>
			</v-checkbox>
			<v-btn class="ma-2" color="primary" @click.end="onAccept" :disabled="!agree">
				{{ $t('paymentProcessed.accept') }}
			</v-btn>
			<v-btn class="ma-2" color="red" @click.end="onDecline">
				{{ $t('paymentProcessed.decline') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</div>`
		);
	}
};
</script>

<style></style>
