<template>
	<v-container :class="$style.container">
		<v-overlay v-if="!response">
			<v-row>
				<v-col cols="auto">
					<p style="text-align: center">{{ loadingMessage }}</p>
					<br />
					<v-progress-circular indeterminate size="50" />
				</v-col>
			</v-row>
		</v-overlay>
		<iframe v-if="response" :src="response"></iframe>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			response: '',
			loadingMessage: 'We are still working on it...'
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true }
	},
	methods: {
		async setUrlResponse() {
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId);
			this.response = response;
		}
	},
	created() {
		this.setUrlResponse();
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 500px;
}
.container {
	max-width: 800px;
	margin: auto;
	width: 100%;
}
</style>
