<template>
	<v-container>
		<v-container v-if="!error">
			<v-row align="center" v-if="!completed">
				<v-col cols="12" align="center" style="margin-top: 25px; margin-bottom: 10px">
					<transition name="fade">
						<v-progress-circular indeterminate color="green" size="120" v-if="isAuthorized"></v-progress-circular>
						<v-icon v-else size="120">mdi-shield-lock</v-icon>
					</transition>
				</v-col>
				<v-container class="container">
					<h3 class="payment-title">Payment Authorization</h3>
					<p v-if="!isAuthorized" class="subtitle">A payment of {{ amount.toFixed(2) }} {{ currency }} from your {{ bank }} account will be initiated in the next step</p>
					<p v-if="isAuthorized" class="subtitle">We are waiting to hear back from {{ bank }}. Please wait a second.</p>
					<v-btn v-if="!isAuthorized" color="primary" @click="createAuthorization" :disabled="isAuthorized">Initiate Payment</v-btn>
				</v-container>
			</v-row>
		</v-container>
		<v-container v-if="completed && authStatus === 'AWAITING_DECOUPLED_AUTHORIZATION'">
			<decoupled-auth :transactionId="transactionId" :id="paymentDetails.consentId" @success="handleDecoupledAuth" />
		</v-container>
		<v-container v-if="completed && !error && authStatus === 'AUTHORIZED'">
			<status-page :transactionId="transactionId" :consent="paymentDetails.consent" :id="paymentDetails.id" />
		</v-container>
		<v-container v-if="error">
			<error-page :transactionId="transactionId" :error="errorInfo" />
		</v-container>
	</v-container>
</template>
<script>
import ApiService from '../../services/ApiService.js';

export default {
	props: {
		transactionId: { type: String, required: false },
		amount: { type: Number, required: false },
		currency: { type: String, required: false },
		reference: { type: String, required: false },
		bank: { type: String, required: false },
		payee: { type: Object, required: false }
	},
	data() {
		return {
			isAuthorized: false,
			authStatus: null,
			completed: false,
			error: false,
			errorInfo: {},
			paymentDetails: {
				id: null,
				consent: null
			}
		};
	},
	components: {
		'status-page': () => import('./StatusPage.vue'),
		'error-page': () => import('./ErrorPage.vue'),
		'decoupled-auth': () => import('./DecoupledAuth.vue')
	},
	async created() {
		await this.mountListener();
	},
	async beforeDestroy() {
		await this.unmountListener();
	},
	methods: {
		async mountListener() {
			window.addEventListener('message', this.handleMessage);
		},
		async unmountListener() {
			window.removeEventListener('message', this.handleMessage);
		},
		async handleMessage(event) {
			const { consent, error } = event.data;
			if (!_.isNil(consent)) {
				this.paymentDetails.consent = consent;
				await this.updateAuthorization();
			}
			if (!_.isNil(error)) {
				this.error = true;
				this.loading = false;
			}
		},
		openPopUp(urlToOpen) {
			window.open(urlToOpen, '_blank', 'width=800,height=600');
		},
		async createAuthorization() {
			this.isAuthorized = true;
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				selectedInstitutions: this.bank,
				action: 'create_preauthorization'
			});
			if (!response.success) {
				this.error = true;
				this.errorInfo = response.error;
				this.loading = false;
				return;
			}
			this.openPopUp(response.data.url);
		},
		async updateAuthorization() {
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				selectedInstitutions: this.bank,
				consent: this.paymentDetails.consent,
				action: 'update_preauthorization'
			});
			if (!response.success) {
				this.error = true;
				this.errorInfo = response.error;
				this.loading = false;
				return;
			}
			this.paymentDetails.id = response.data.id;
			this.authStatus = response.data.status;
			this.completed = true;
		},
		async handleDecoupledAuth(authorizationDetails) {
			const { status, consentToken } = authorizationDetails;
			if (status === 'AUTHORIZED') {
				this.authStatus = status;
				this.paymentDetails.consent = consentToken;
				this.loading = true;
				this.loading = false;
				const id = await this.createSinglePayment(consentToken);
				if (_.isNil(id)) return;
				this.paymentDetails.id = id;
				this.completed = true;
				this.loading = false;
			}
		},
		async createSinglePayment(consent) {
			this.paymentDetails.consent = consent;
			this.action = 'single_payment';
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				selectedInstitutions: this.bank,
				action: this.action,
				paymentDetails: this.paymentDetails,
				type: 'single'
			});
			if (!response.success) {
				this.error = true;
				this.errorInfo = response.error;
				return null;
			}
			return response.data.id;
		}
	}
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
