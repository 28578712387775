<template>
	<v-container @click="handleValidation">
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-container v-if="this.isWidget === false">
			<p>Payment page is being loaded on a new window.</p>
			<!-- <iframe :src="response"></iframe> -->
		</v-container>
		<v-container v-else>
			<v-container v-if="response === '/paymentProcessed'"><iframe :src="response"></iframe></v-container>
			<v-container v-else><form action="/paymentProcessed" class="paymentWidgets wpwl-form-card" data-brands="VISA MASTER"></form></v-container>
		</v-container>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			response: '',
			loading: false
		};
	},
	props: {
		transactionId: { type: String, required: true },
		isWidget: { type: Boolean, required: true },
		endpoint: { type: String, required: true }
	},
	methods: {
		appendErrorHint(selector, hintErrorClass, content) {
			const input = document.querySelector(selector);
			input.classList.add('wpwl-has-error');
			const errorHint = document.createElement('div');
			errorHint.classList.add('wpwl-hint', hintErrorClass);
			errorHint.textContent = content;
			input.parentNode.appendChild(errorHint);
		},
		modifyPayButton(selector, buttonClass, disabled) {
			const payButton = document.getElementsByClassName(selector)[0];
			payButton.classList.add(buttonClass);
			payButton.setAttribute('disabled', disabled);
		},
		handleValidation(e) {
			if (e.target.getAttribute('class') === 'wpwl-button wpwl-button-pay') {
				const cardHolder = document.getElementsByClassName('wpwl-control wpwl-control-cardHolder')[0].value;
				if (cardHolder.length < 2) {
					e.preventDefault();
					this.appendErrorHint('.wpwl-control-cardHolder', 'wpwl-hint-cardHolderError', 'Invalid card holder');
					this.modifyPayButton('wpwl-button wpwl-button-pay', 'wpwl-button-error', true);
				}
			}
		},
		async initRequest() {
			this.loading = true;
			this.response = await ApiService.publicApi.createPaymentRequest(this.transactionId, null, this.cardType);
			if (this.isWidget === false) {
				this.openPopUp(this.response);
			}
			if (this.isWidget === true && this.response !== '/paymentProcessed') {
				this.renderWidgetScript(this.response);
			}
			this.loading = false;
		},
		renderWidgetScript(checkoutId) {
			const script = document.createElement('script');
			script.src = `${this.endpoint}/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
			script.addEventListener('load', this.setLoaded);
			document.body.appendChild(script);
		},
		// eslint-disable-next-line space-before-function-paren
		openPopUp: function (urlToOpen) {
			const popup_window = window.open(
				urlToOpen,
				'myWindow',
				'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, width=800, height=700'
			);
			try {
				popup_window.focus();
			} catch (e) {
				alert('Pop-up Blocker is enabled! Please add this site to your exception list.');
			}
		}
	},
	created() {
		this.initRequest();
	}
};
</script>
<style scoped>
iframe {
	width: 100%;
	height: 500px;
}
</style>
