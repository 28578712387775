<template>
	<v-container>
		<v-row>
			<v-overlay v-if="loading">
				<v-row>
					<v-col cols="auto">
						<p style="text-align: center">Please wait. . .</p>
						<br />
						<v-progress-circular indeterminate size="50" />
					</v-col>
				</v-row>
			</v-overlay>
			<v-col cols="12" v-if="error">
				<v-container>
					<v-row>
						<v-col cols="12">
							<v-card flat>
								<transition name="fade">
									<v-icon size="140" color="red">mdi-close-circle</v-icon>
								</transition>
								<v-container class="container" style="margin-top: 25px">
									<h2 class="payment-title">Ooops!</h2>
									<p class="subtitle" style="margin-top: 10px">
										<i>
											We encountered a problem while validating your wallet address and it appears invalid.
											<br />
											If the issue persists, contact our customer support for further assistance.
											<br />
											You may now close this window, Thank You!
										</i>
									</p>
								</v-container>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-col>
			<v-col cols="12" v-if="!error">
				<v-stepper v-model="e1">
					<v-stepper-header>
						<v-stepper-step :complete="e1 > 1" step="1">Start</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step :complete="e1 > 2" step="2">Processing</v-stepper-step>
					</v-stepper-header>
					<v-stepper-items>
						<v-stepper-content step="1">
							<v-container>
								<v-row>
									<v-col cols="12">
										<input-field
											required
											clearable
											:props="{ styleType: 'outlined' }"
											v-model="customer.walletAddress"
											label="Wallet Address"
											type="special-text"
										/>
									</v-col>
								</v-row>
							</v-container>
							<v-btn color="primary" @click="createPaymentRequest" :disabled="!customer.walletAddress"> Continue </v-btn>
						</v-stepper-content>
						<v-stepper-content step="2">
							<v-card class="mb-12" color="lighten-1" height="636px">
								<iframe
									title="Onramper Widget"
									v-if="widgetLink"
									:src="widgetLink"
									frameborder="no"
									height="630px"
									width="420px"
									allow="accelerometer; autoplay; camera; gyroscope; payment; microphone"
								></iframe>
							</v-card>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			e1: 1,
			widgetLink: null,
			loading: false,
			error: false,
			customer: {
				walletAddress: null
			}
		};
	},
	props: {
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true }
	},
	methods: {
		async createPaymentRequest() {
			this.loading = true;
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, { customer: this.customer });
			const { success, data } = response;
			this.loading = false;
			if (!success) {
				this.error = true;
			} else {
				this.e1 = 2;
				this.widgetLink = data.widgetLink;
			}
		}
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 600px;
}
.container {
	max-width: 650px;
	margin: auto;
	width: 100%;
}
</style>
