<template>
	<v-container>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-row v-if="showFrame">
			<v-card class="mx-auto">
				<v-col col="12">
					<p>Payment page is being loaded on new tab.</p>
				</v-col>
			</v-card>
		</v-row>
		<v-row v-else style="col-lg-12 col-md-12 col-sm-12 text-xs-center">
			<v-card class="mx-auto" style="width: 600px">
				<v-col style="margin-top: 30px">
					<VTooltip top>
						<template v-slot:activator="{ on, attrs }">
							<input-field
								required
								clearable
								:props="{ items: paymentMethods, styleType: 'outlined' }"
								v-model="customer.paymentMethod"
								label="Payment Method"
								type="select"
								v-bind="attrs"
								v-on="on"
							/>
						</template>
						Select preferred payment method below:
					</VTooltip>
				</v-col>
				<v-col>
					<VTooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<input-field
								v-model="customer.financialAccount"
								type="special-text"
								label="Account"
								v-bind="attrs"
								v-on="on"
								:props="{ items: paymentMethods, styleType: 'outlined' }"
							/>
						</template>
						Financial account number (papara account, IBAN, etc.)
					</VTooltip>
				</v-col>
				<v-col v-if="customer.paymentMethod === 'upi'">
					<VTooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<input-field
								v-model="customer.ifsc"
								type="special-text"
								label="IFSC"
								v-bind="attrs"
								v-on="on"
								:props="{ items: paymentMethods, styleType: 'outlined' }"
							/>
						</template>
						Indian Financial System Code. It's an 11-digit code written in an alphanumeric format, and it identifies the branches in the National Electronic Funds
						Transfer (NEFT) network.
					</VTooltip>
				</v-col>
				<v-card-actions>
					<v-col>
						<v-btn text color="deep-purple accent-4" @click="onSubmit" :disabled="!customer.paymentMethod"> Continue </v-btn>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			response: '',
			customer: {
				financialAccount: '',
				paymentMethod: null,
				ifsc: null
			},
			loading: false,
			showFrame: false
		};
	},
	props: {
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true },
		paymentMethods: { type: Array, required: false }
	},
	methods: {
		async onSubmit() {
			try {
				this.loading = true;
				const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
					customer: this.customer
				});
				this.response = response;
				this.loading = false;
				this.showFrame = true;
				window.open(this.response);
			} catch (err) {
				this.loading = false;
				this.showFrame = false;
			}
		}
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 500px;
}
</style>
