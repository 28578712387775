<template>
	<p>Payment page is being loaded on new tab.</p>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			response: ''
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true }
	},
	methods: {
		async setUrlResponse() {
			try {
				const response = await ApiService.publicApi.createPaymentRequest(this.transactionId);
				this.response = response;
				window.open(this.response);
			} catch (err) {
				throw new Error(err);
			}
		}
	},
	async created() {
		this.setUrlResponse();
	}
};
</script>
<style module>
iframe {
	width: 100%;
	height: 500px;
}
.container {
	max-width: 800px;
	margin: auto;
	width: 100%;
}
</style>
