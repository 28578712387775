<template>
	<v-container>
		<!--<v-overlay v-if="loading">
			<v-row>
				<v-col cols="auto">
					<p style="text-align: center">{{ loadingMessage }}</p>
					<br />
					<v-progress-circular indeterminate size="50" />
				</v-col>
			</v-row>
		</v-overlay> -->
		<v-dialog max-width="550" persistent :value="termsDialogVisible">
			<v-card>
				<v-container>
					<v-row class="align-center pl-4 pr-4 pt-4">
						<v-col class="text-left" cols="10">
							<div :class="$style['terms-header-text']">Terms and conditions</div>
						</v-col>
						<v-col>
							<v-icon @click="termsDialogVisible=false">mdi-close</v-icon>
						</v-col>
					</v-row>
					<v-row class="pa-4">
						<v-col class="text-left">
							<div :class="$style['terms-header-body']"></div>
							Some issuing banks have restricted the use of credit cards for online entertainment. Please feel free to use one of the alternative 3rd party payment
							methods above or contact our Customer Services Representatives toll free at NA to fund your account. Please Note: Depending on your issuing bank, credit
							card transactions could be treated as a cash advance.
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
		<v-row :class="$style['loading-wrap']" v-if="loading" class="justify-space-around">
			<v-col cols="auto">
				<v-progress-circular :class="$style['blue']" class="mb-5" indeterminate size="50" />
				<br />
				<h2 :class="$style['loading-info']" style="text-align: center">Loading....</h2>
				<p :class="$style['loading-info']" style="text-align: center">Please wait, It will take a moment</p>
			</v-col>
		</v-row>
		<v-row v-if="descriptorText">
			<v-col md="12">
				<p style="font-size: 14px">
					<span class="font-italic">Descriptor: {{ descriptorText }}</span>
				</p>
			</v-col>
		</v-row>
		<v-row v-if="!loading">
			<v-col>
				<v-row>
					<v-col class="pl-0 pb-0">
						<div class="text-left" :class="[$style['payment-method-pick-text'], $style['ft-20']]">Payment Information</div>
					</v-col>
				</v-row>

				<v-row class="mt-0">
					<v-col class="pl-0 pr-0">
						<v-row>
							<v-col class="pr-0 pl-0 pt-2" cols="2">
								<Icon icon="solar:shield-check-linear" width="1.8em" height="1.8em" color="#37D476" />
							</v-col>
							<v-col class="text-left pt-1 pl-0">
								<div :class="[$style['ft-20'], $style['grey']]">{{ $t('paymentOptions.creditCard') }}</div>
								<div :class="$style['psp-text']">{{ $t('paymentOptions.using') }} {{ pspType }} {{ $t('paymentOptions.system') }}</div>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-row v-if="pspType !== 'StaticInfo'">
					<v-col class="text-left pb-0 pl-0">
						<span :class="[$style['ft-16'], $style['black']]">{{ $t('paymentOptions.pleaseEnterCardInfo') }}</span>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<!-- <div :class="$style.flipCard">
			<div :class="[$style.flipCardInner, focused ? $style.focused : '']">
				<v-img contain :src="require('../../assets/card.png')" :class="[$style.roundedCard, $style.flipCardFront]">
					<v-row justify="center" :class="[$style.cardNumber]">
						<span v-for="(number, $index) in cardNumberMask" :key="$index">
							<div class="pa-0 " :class="$style.creditCardFont">
								{{ cardNumber && cardNumber.length > $index ? cardNumber[$index] : number }}
							</div>
						</span>
					</v-row>
					<v-row cols="12" sm="12" md="12" :class="[$style.cardHolderName]">
						<v-col :class="[$style.cardText, 'pa-0']" style="text-align: left !important;">
							<span>{{ cardOwner.substring(0, 23) || $t('card holder Name') }}</span>
						</v-col>
					</v-row>
					<v-row cols="12" sm="12" md="12" :class="[$style.cardExpiryDate]">
						<v-col :class="[$style.cardText, 'pa-0']" style="display: flex;">
							<div>
								<span>{{ cardMonth || 'MM' }}</span>
								/
								<span>{{ cardYear || 'YY' }}</span>
							</div>
						</v-col>
					</v-row>
					<v-col cols="auto" :class="[$style.cardType]">
						<v-img :src="require(`../../assets/card-sprites/${cardType}.png`)" width="100px" />
					</v-col>
				</v-img>
				<v-img :src="require('../../assets/card-back.png')" :class="[$style.roundedCard, $style.flipCardBack]">
					<v-row class="mt-4 mr-4 ml-4">
						<v-col md="2" class="pa-0">
							<div :class="$style.signatureArea">
								<span>{{ getCVVMask }}</span>
							</div>
						</v-col>
					</v-row>
					<v-img :class="$style.cardTypeBack" :src="require(`../../assets/card-sprites/${cardType}.png`)" width="100px" />
				</v-img>
			</div>
		</div> -->
		<v-form v-if="!loading" v-model="validForm">
			<v-row class="mt-5">
				<v-col class="pa-0">
					<v-text-field
						required
						outlined
						dense
						v-mask="cardNumberMask"
						:label="$t('debitCard.cardNumber')"
						v-model="cardNumber"
						type="text"
						:rules="[rules.creditCardNumber]"
					>
						<template #prepend-inner>
							<span :class="{ 'red--text': !rules.creditCardNumber }"><strong>* </strong></span>
						</template>
					</v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="pl-0 pr-0">
					<input-field
						:color="'red'"
						required
						clearable
						:label="$t('debitCard.cardOwner')"
						v-model="cardOwner"
						type="text"
						:props="{
							styleType: 'outlined',
							...(allowNonLatinLetterInOwner ? { customValidator: validators.lettersAndSpaces } : {})
						}"
					/>
				</v-col>
			</v-row>

			<v-row v-if="this.country === 'Brazil'">
				<v-col class="pl-0 pr-0">
					<input-field clearable label="CPF Number" v-model="cpfNumber" type="special-text" :props="{ styleType: 'outlined' }" />
				</v-col>
			</v-row>

			<v-row>
				<v-col class="pl-0 pr-1">
					<input-field required clearable :props="{ items: months, styleType: 'outlined' }" v-model="cardMonth" :label="$t('debitCard.month')" type="select" />
				</v-col>
				<v-col class="pl-1 pr-0">
					<input-field required clearable :props="{ items: years, styleType: 'outlined' }" v-model="cardYear" :label="$t('debitCard.year')" type="select" />
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col class="pl-0 pr-0" md="5">
					<!--
						Important Note:
						`customValidator: () => true` must be passed as a prop to
						prevent default UTT password validation from rejecting CVVs.
					-->
					<!-- TODO V7 Refactoring: consider other solutions for CVV field validation -->
					<input-field
						@focus="focused = true"
						@blur="focused = false"
						:class="[$style.cvv]"
						required
						clearable
						:label="$t('debitCard.cvv')"
						type="password"
						:value="cvv"
						v-model="cvv"
						mask="####"
						:props="{ styleType: 'outlined', customValidator: validators.cvv }"
					/>
				</v-col>
			</v-row>

			<v-row>
				<v-col class="pl-0 pr-0">
					<v-checkbox required :rules="[rules.isTick]">
						<template #label>
							<span :class="$style['terms']" style="font-size: 14px">
								By making your purchase, you agree to the <a @click.stop="openTermsDialog">Terms and Conditions.</a>
							</span>
						</template>
						<template #prepend-inner>
							<span :class="{ 'red--text': !rules.isTick }"><strong>* </strong></span>
						</template>
					</v-checkbox>
				</v-col>
			</v-row>
			<v-row class="d-flex justify-center mt-5">
				<v-btn @click="onSubmit" :disabled="!validForm" :class="[$style.submitBtn]" :large="true">Make your purchase</v-btn>
			</v-row>

			<v-row v-if="extraInfo">
				<v-row class="mt-5">
					<p style="font-size: 14px">
						<span class="font-italic">{{ extraInfo }}</span>
						<br />
					</p>
					<v-col>
						<img height="60px;" width="120px" src="https://d3m29zrp0iqnc8.cloudfront.net/images/visa-verified.png" />
						<img height="60px;" width="120px" src="https://d3m29zrp0iqnc8.cloudfront.net/images/mc-card.png" />
					</v-col>
				</v-row>
			</v-row>
		</v-form>
	</v-container>
</template>

<script>
import CardValidator from 'card-validator';
import ApiService from '../../services/ApiService.js';
import VueService from '../../services/VueService.js';
const { _, moment } = VueService;
import { Icon } from '@iconify/vue2';

export default {
	components: {
		Icon
	},
	data() {
		return {
			focused: false,
			cardNumber: '',
			cardMonth: '',
			cardYear: '',
			cardOwner: '',
			cvv: '',
			cpfNumber: '',
			validForm: false,
			loading: false,
			loadingMessage: '',
			rules: {
				creditCardNumber: (value) => !!CardValidator.number(value).isValid || 'Field must contain a valid credit card number',
				isTick: (value) => value || 'you must certify that you are atleast 18 years old and you agree to the terms and conditions.'
			},
			validators: {
				cvv: (value) => {
					const cvvLength = value.length;
					if (cvvLength !== 3 && cvvLength !== 4) {
						return 'CVV must be 3 or 4 digits long';
					}
					return true;
				},
				lettersAndSpaces: (value) => {
					const lettersAndSpacesRegex = /^[\p{L} ]+$/gu;
					if (!lettersAndSpacesRegex.test(value.trim().replace(/\s+/g, ' '))) {
						return 'Field can contain only letters and spaces';
					}
					return true;
				}
			},
			termsDialogVisible: false
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		walletId: { type: String, required: false, default: null },
		pspType: { type: String, required: true },
		createdDateTimestamp: { type: Number, required: false, default: null },
		extraInfo: { type: String, required: false, default: null },
		descriptorText: { type: String, required: false, default: null },
		country: { type: String, required: false, default: null },
		source: { type: String, required: false, default: null },
		subSource: { type: String, required: false, default: null },
		isLatinOnlyInput: { type: Boolean, required: true }
	},
	computed: {
		cardType() {
			const number = this.cardNumber;
			// while on computed, set default card type as visa
			const creditCardType = this.getCreditCardNumberType() ?? 'visa';
			// add manual regex for troy card, validator couldnt support it.
			if (/^9792/.test(number)) return 'troy';
			return creditCardType;
		},
		cardNumberMask() {
			switch (this.cardType) {
				case 'maestro':
					return this.renderMaestroCardMask();
				case 'diners-club':
					return '#### ###### ####';
				default:
					return this.cardType === 'american-express' ? '#### ###### #####' : '#### #### #### ####';
			}
		},
		minCardMonth() {
			return this.cardYear === moment().year() ? moment().month() + 1 : 1;
		},
		months() {
			const allMonths = _.range(1, 13);
			return _(allMonths)
				.filter((month) => this.minCardMonth <= month)
				.map((month) => ({ text: month, value: month }))
				.value();
		},
		years() {
			const currentYear = moment().year();
			const allYears = _.range(currentYear, currentYear + 30);
			return _.map(allYears, (year) => ({ text: year, value: year }));
		},
		getCVVMask() {
			return this.cvv
				.split('')
				.map(() => '*')
				.join('');
		},
		validateInputFields() {
			return !!(!this.cardOwner || !this.cardNumber || !this.cardMonth || !this.cardYear || !this.cvv);
		},
		mobile() {
			return window.screen.width < 600;
		},
		allowNonLatinLetterInOwner() {
			const isWisewire = this.pspType === 'Wisewire';
			return isWisewire && !this.isLatinOnlyInput;
		}
	},
	watch: {
		cardMonth(newValue) {
			if (newValue > 12) this.cardMonth = '';
		}
		// cardYear() {
		// 	if (this.cardMonth < this.minCardMonth) {
		// 		this.cardMonth = '';
		// 	}
		// }
	},
	methods: {
		openTermsDialog() {
			this.termsDialogVisible = true;
		},
		async onSubmit() {
			this.loading = true;
			try {
				setTimeout(() => {
					if (this.loading) {
						this.loadingMessage = 'We are still working on it...';
					}
				}, 5000);
				const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
					cardNumber: this.cardNumber.replace(/ /g, ''),
					cardMonth: this.cardMonth,
					cardYear: this.cardYear,
					cardOwner: this.cardOwner.trim().replace(/\s+/g, ' '),
					cvv: this.cvv,
					cardBrand: this.cardType,
					cpfNumber: this.cpfNumber
				});
				this.$emit('payment-submitted', { pspType: this.pspType, response });
				this.loading = false;
			} catch (err) {
				this.loading = false;
			}
		},
		loadDeviceFingerPrint() {
			const script = document.createElement('script');
			script.src = `https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id={${this.transactionId}_${this.createdDateTimestamp}}`;
			script.addEventListener('load', this.setLoaded);
			document.body.appendChild(script);
		},
		renderMaestroCardMask() {
			const number = this.cardNumber.replace(/ /g, '');
			// card validator maestro card length = 12 to 19 digits
			switch (number.length) {
				case 12:
					return '#### #### ####';
				case 13:
					return '#### #### #### #';
				case 14:
					return '#### #### #### ##';
				case 17:
					return '#### #### #### #### #';
				case 18:
					return '#### #### #### #### ##';
				case 19:
					return '#### #### #### #### ###';
				default:
					return '#### #### #### ####';
			}
		},
		getCreditCardNumberType() {
			const number = this.cardNumber.replace(/ /g, '');
			const card = CardValidator.number(number);
			return card?.card?.type ?? null;
		},
		isNumber(evt) {
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
			const keyPressed = evt.key;
			if (!keysAllowed.includes(keyPressed)) {
				evt.preventDefault();
			}
		},
		onBack() {
			this.$emit('cancel-payment-method');
		}
	},
	created() {
		if (this.pspType === 'Pay42') {
			this.loadDeviceFingerPrint();
		}
	}
};
</script>

<style module>
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap');

.roundedCard {
	border-radius: 20px !important;
	margin: auto;
}

.creditCardFont {
	font-family: 'Source Code Pro', monospace;
	font-weight: 500;
	width: 16px;
	line-height: 1;
	color: #ffffff;
	font-size: 27px;
	margin-bottom: 35px;
	padding: 10px 15px;
	text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
}

.cardLabel {
	color: #000;
	font-family: 'Source Code Pro', monospace;
	opacity: 0.7;
	font-size: 13px;
	margin-bottom: 6px;
	text-transform: none;
}
.cardHolderName {
	position: absolute;
	bottom: 50px;
	left: 60px;
}
.cardExpiryDate {
	position: absolute;
	bottom: 50px;
	right: 60px;
}
.cardText {
	font-size: 18px;
	line-height: 1;
	white-space: pre-wrap;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
	color: white;
	text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
}

.v-input__icon--prepend-inner {
	width: 8px;
}

.flipCard {
	background-color: transparent;
	height: 240px;
}

.flipCardInner {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.6s;
	transform-style: preserve-3d;
}

.cvv:hover ~ .flipCardInner {
	transform: rotateY(180deg);
}

.focused {
	transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.flipCardFront {
	color: black;
}

.flipCardBack {
	background-color: #2980b9;
	color: white;
	transform: rotateY(180deg);
}

.cardTypeBack {
	position: absolute;
	right: 40px;
	bottom: 50px;
}

.blackArea {
	height: 60px;
	background: #000;
	width: 100%;
}
.signatureArea {
	height: 45px;
	position: absolute;
	right: 50px;
	top: 90px;
}

.stripe {
	background: greenyellow;
	margin-bottom: 5px;
	height: 1px;
}
.inputField {
	width: 100%;
	padding: 5px 8px;
	height: 40px;
	box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3) inset;
	border-radius: 5px;
	color: #1b1b1b;
	text-transform: uppercase;
	margin: 5px 0;
}

.cardHolder {
	width: 100%;
}
.cardImage {
	object-fit: cover;
	width: 100%;
}
.cardNumber {
	position: absolute;
	bottom: 55px;
	left: 30px;
}
.cardType {
	position: absolute;
	right: 10px;
	top: 10px;
}
.submitBtn {
	background: linear-gradient(285.21deg, #6814e8 0%, #9756fb 100%);
	color: #fff !important;
	width: 95%;
}
.submitBtn:disabled {
	background: linear-gradient(135deg, #bebebe 0%, #c0c0c0 100%);
	cursor: not-allowed !important;
}

.backBtn {
	border-color: #3017ac !important;
	color: #3017ac !important;
	width: 150px;
}

.loading-info {
	color: #5e5e5e;
}

.blue {
	color: #7b61ff;
}

.loading-wrap {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.payment-method-pick-text {
	color: #6814e8;
	font-weight: 500;
	margin-bottom: 28px;
}

.ft-20 {
	font-size: 20px;
}

.psp-text {
	line-height: 10px;
	color: #9999;
}

.terms,
.terms a {
	color: #555;
}

.terms a {
	text-decoration: underline;
}

.terms-header-text {
	color: #555;
	font-size: 16px;
	font-weight: 500;
	line-height: normal;
}

.terms-header-body {
	color: #555;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
}
</style>
