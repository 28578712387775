import { apiRequest } from './api.js';

const BASE_URL = '/api/public';

const getAvailablePaymentMethods = (transactionId) => apiRequest({ method: 'get', endpoint: `${BASE_URL}/getAvailablePaymentMethods`, query: { transactionId } });

const selectPaymentMethod = (transactionId, paymentMethodId) =>
	apiRequest({ method: 'post', endpoint: `${BASE_URL}/selectPaymentMethod`, body: { transactionId, paymentMethodId } });
// TODO: Change name function to createPaymentRequest.
const createPaymentRequest = async (transactionId, creditCardDetails, extraDetails = {}) =>
	apiRequest({ method: 'post', endpoint: `${BASE_URL}/createPaymentRequest`, body: { transactionId, creditCardDetails, extraDetails } });

const createPaymentBilletRequest = async (transactionId) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/createPaymentBilletRequest`, body: { transactionId } });

const getTransactionDetails = async (transactionId) => apiRequest({ method: 'get', endpoint: `${BASE_URL}/getTransactionDetails`, query: { transactionId } });

const createCustomer = (email) => apiRequest({ method: 'POST', endpoint: `${BASE_URL}/createCustomer`, body: { email } });

const initStripeSubscription = (data) => apiRequest({ method: 'POST', endpoint: `${BASE_URL}/initStripeSubscription`, body: data });

const createSubscription = (data) => apiRequest({ method: 'POST', endpoint: `${BASE_URL}/createSubscription`, body: data });

const uploadFile = async (transaction, file) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/uploadFile`, body: { transaction, file } });

const getDepositTemplate = async (subSource) => apiRequest({ method: 'get', endpoint: `${BASE_URL}/getDepositTemplate`, query: { subSource } });

const sendOtpMailNotification = async (transactionId, extraDetails = {}) =>
	apiRequest({ method: 'post', endpoint: `${BASE_URL}/sendOtpMailNotification`, body: { transactionId, extraDetails } });

const createSignMessageRequest = async (transactionId, extraDetails = {}) =>
	apiRequest({ method: 'post', endpoint: `${BASE_URL}/createSignMessageRequest`, body: { transactionId, extraDetails } });

const createRedeemRequest = async (transactionId, extraDetails = {}) =>
	apiRequest({ method: 'post', endpoint: `${BASE_URL}/createRedeemRequest`, body: { transactionId, extraDetails } });

export default {
	getAvailablePaymentMethods,
	selectPaymentMethod,
	createPaymentRequest,
	createPaymentBilletRequest,
	getTransactionDetails,
	createCustomer,
	initStripeSubscription,
	createSubscription,
	uploadFile,
	getDepositTemplate,
	sendOtpMailNotification,
	createSignMessageRequest,
	createRedeemRequest
};
