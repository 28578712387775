<template>
	<div>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>

		<form method="POST" action="https://payment.bullioncurrencies.com/entry" ref="form" target="_blank">
			<fieldset class="forFotm">
				<input type="hidden" name="merchant_code" v-model="transactionDetails.merchant_code" />
				<input type="hidden" name="amount" v-model="transactionDetails.amount" />
				<input type="hidden" name="transaction_id" v-model="transactionDetails.transaction_id" />
				<input type="hidden" name="response_url" v-model="transactionDetails.response_url" />
			</fieldset>
		</form>
		<p v-if="transactionDetails.merchant_code">The payment page loaded on new tab.</p>
	</div>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			transactionDetails: {},
			loading: false
		};
	},
	props: {
		transactionId: { type: String, required: true }
	},
	methods: {
		handleDisplayErrorMessage(message) {
			const { errorCard } = this.$refs;
			const errorCardMessage = errorCard?.querySelector('.message');
			errorCard.classList.add('errorCard.visible');
			errorCardMessage.innerText = message;
		},
		async onSubmit() {
			try {
				this.loading = true;
				this.transactionDetails = await ApiService.publicApi.createPaymentRequest(this.transactionId, null);
				this.loading = false;
			} catch (error) {
				this.loading = false;
			}
		}
	},
	async created() {
		await this.onSubmit();
		this.$refs.form.submit();
	}
};
</script>
<style>
.errorCard {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;
	padding: 0 15px;
	font-size: 13px;
	opacity: 0;
	transform: translateY(10px);
	transition-property: opacity, transform;
	transition-duration: 0.35s;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.errorCard.visible {
	opacity: 1;
	transform: none;
}
fieldset.displayNone {
	display: none;
}
.forFotm {
	display: none;
}
</style>
