<template>
	<v-container :class="$style.container" v-if="!response">
		<v-form v-model="validForm">
			<v-row>
				<v-col>
					<input-field required clearable :label="$t('paylivre.email')" v-model="email" type="email" :props="{ styleType: 'outlined' }" />
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<input-field required clearable :label="$t('paylivre.token')" v-model="token" type="text-number" :props="{ styleType: 'outlined' }" />
				</v-col>
			</v-row>
			<v-row justify="space-around" class="mt-5">
				<v-btn text color="primary" @click="onSubmit" :disabled="!validForm">
					{{ $t('debitCard.submit') }}
					<v-icon right>mdi-check</v-icon>
				</v-btn>
			</v-row>
		</v-form>
	</v-container>
	<iframe v-else :src="response"></iframe>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			email: '',
			token: '',
			validForm: false,
			response: ''
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true }
	},
	methods: {
		async onSubmit() {
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				senderToken: this.token,
				senderEmail: this.email
			});
			this.response = response;
		}
	}
};
</script>

<style module>
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap');

.container {
	max-width: 570px;
	margin: auto;
	width: 100%;
}
iframe {
	width: 100%;
	height: 500px;
}
</style>
