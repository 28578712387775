<template>
	<v-container>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-form v-model="validForm">
			<template v-if="this.directService && !showPopMessage">
				<v-row style="col-lg-12 col-md-12 col-sm-12 text-xs-center">
					<v-card class="mx-auto">
						<v-col>
							<input-field
								required
								clearable
								:props="{ items: serviceChannels, styleType: 'outlined' }"
								v-model="selectedService"
								label="Payment Method"
								type="select"
							/>
						</v-col>
						<v-card-actions>
							<v-btn text color="deep-purple accent-4" @click="onSubmit" qa-e2e="continueButton" :disabled="!validForm"> Continue </v-btn>
						</v-card-actions>
					</v-card>
				</v-row>
			</template>

			<template v-if="this.isGateway && !showPopMessage && !showQrCode">
				<v-row style="col-lg-12 col-md-12 col-sm-12 text-xs-center">
					<v-card class="mx-auto">
						<v-col>
							<input-field
								required
								clearable
								:props="{ items: gatewayChannels, styleType: 'outlined' }"
								v-model="selectedService"
								label="Payment Method"
								type="select"
							/>
						</v-col>
						<v-col>
							<input-field required v-model="cpfNumber" type="special-text" label="CPF" />
						</v-col>
						<v-card-actions>
							<v-btn text color="deep-purple accent-4" @click="onSubmit" qa-e2e="continueButton" :disabled="!validForm"> Continue </v-btn>
						</v-card-actions>
					</v-card>
				</v-row>
			</template>
		</v-form>

		<v-row style="col-lg-12 col-md-12 col-sm-12 text-xs-center" v-if="showPopMessage">
			<v-card class="mx-auto">
				<p>Payment page is being loaded on a new tab...</p>
			</v-card>
		</v-row>

		<v-row style="col-lg-12 col-md-12 col-sm-12 text-xs-center" v-if="showQrCode">
			<v-card class="mx-auto" style="width: 370px">
				<v-snackbar v-model="snackbar">
					Copied
					<template #action="{ attrs }">
						<v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
					</template>
				</v-snackbar>
				<img v-if="qrImage" :src="require(`../../assets/astropay-logo.png`)" style="margin-top: 20px; width: 200px" />
				<img v-if="qrImage" :src="qrImage" style="margin-top: 20px" />
				<p style="margin-top: 10px">QR INFORMATION:</p>
				<textarea :value="qrCode" style="width: 300px; height: 150px; margin-top: 15px; white-space: pre-wrap" ref="text"></textarea>
				<v-btn text color="deep-purple accent-4" @click="copyTextArea"> COPY TEXT </v-btn>
				<p style="color: red; margin-top: 10px"><i>this transaction is only valid for 30 minutes</i></p>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';
import VueService from '../../services/VueService.js';
const { _ } = VueService;

export default {
	data() {
		return {
			response: '',
			selectedService: '',
			cpfNumber: null,
			showPopMessage: false,
			showQrCode: false,
			loading: false,
			serviceChannels: [
				{ text: 'Akulaku', value: 'AD' },
				{ text: 'Bank Negara Indonesia', value: 'NG' },
				{ text: 'Maybank', value: 'MY' },
				{ text: 'OVO', value: 'WE' },
				{ text: 'Permata', value: 'VB' },
				{ text: 'QRIS', value: 'QS' },
				{ text: 'STICPAY', value: 'IP' }
			],
			gatewayChannels: [{ text: 'PIX', value: 'IX' }],
			validForm: false,
			qrImage: '',
			qrCode: '',
			snackbar: false
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true },
		directService: { type: Boolean, required: true },
		isGateway: { type: Boolean, required: true }
	},
	methods: {
		async onSubmit() {
			this.loading = true;
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, { selectedService: this.selectedService, cpfNumber: this.cpfNumber });
			this.response = response;
			this.loading = false;
			if (_.has(this.response, 'qr')) {
				const { qr, qr_image } = this.response;
				this.qrCode = qr;
				this.qrImage = `data:image/jpeg;charset=utf-8;base64,${qr_image}`;
				this.showQrCode = true;
			} else {
				this.showPopMessage = true;
				window.open(this.response);
			}
		},
		async copyTextArea() {
			this.$refs.text.select();
			document.execCommand('copy');
			this.snackbar = true;
		}
	},
	async created() {
		if (!this.directService && !this.isGateway) this.onSubmit();
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 500px;
}
.container {
	max-width: 800px;
	margin: auto;
	width: 100%;
}
</style>
