<template>
	<one-time-password />
</template>

<script>
export default {
	components: {
		OneTimePassword: () => import('../../components/PaymentResponses/OneTimePassword.vue')
	}
};
</script>
