<!-- We need to change this in the next sprint -->
<template>
	<payment-processed />
</template>

<script>
export default {
	components: {
		PaymentProcessed: () => import('../../components/PaymentResponses/PaymentProcessedTemplate3.vue')
	}
};
</script>
