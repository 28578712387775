<template>
	<div>
		<v-overlay v-if="loading">
			<v-row>
				<v-col cols="auto">
					<p style="text-align: center">{{ loadingMessage }}</p>
					<br />
					<v-progress-circular indeterminate size="50" />
				</v-col>
			</v-row>
		</v-overlay>
		<v-row>
			<p style="text-align: center; font-size: 20px">Please wait, while the page is being open on new tab</p>
		</v-row>
	</div>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			transactionDetails: {},
			loading: false,
			response: '',
			loadingMessage: ''
		};
	},
	props: {
		transactionId: { type: String, required: true }
	},
	methods: {
		async onSubmit() {
			try {
				this.loading = true;
				setTimeout(() => {
					if (this.loading) {
						this.loadingMessage = 'We are still working on it...';
					}
				}, 5000);
				this.response = await ApiService.publicApi.createPaymentRequest(this.transactionId, null, null);
				this.loading = false;
				window.open(this.response);
			} catch (error) {
				this.loading = false;
			}
		}
	},
	async created() {
		await this.onSubmit();
	}
};
</script>

<style>
iframe {
	width: 100%;
	height: 500px;
}
</style>
