<template>
	<v-card class="mx-auto" max-width="344" outlined>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-card-text style="display: none">
			<p class="text-h4 text--primary">
				<!-- name will be saved in base -->
				Basic
			</p>

			<p class="text-h3 text--primary">
				{{ paymentPlan }}
			</p>

			<div class="text--primary">"This is the most popular subscription plan."</div>
		</v-card-text>

		<v-card-actions class="align-center justify-center">
			<v-btn color="green" text @click="emitClick" style="display: none">
				{{ $t('stripe.subscription.selectPlan') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	data() {
		return { loading: true };
	},
	props: {
		planDetails: { type: Object, default: () => ({}) }
	},
	methods: {
		emitClick(e) {
			this.$emit('click', e);
		}
	},
	computed: {
		paymentPlan() {
			return `${this.planDetails.unit_amount} ${this.planDetails.currency}/${this.planDetails.recurring.interval}`;
		}
	},
	mounted() {
		this.emitClick();
	}
};
</script>

<style scoped>
span.currency {
	font-size: 14px;
	margin-top: 0.3em;
}
span.amount {
	font-size: 25px;
}

.mainTotalAmount {
	margin: 0 auto;
}
</style>
