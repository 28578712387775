<template>
	<v-container>
		<v-row align="center">
			<v-col cols="12" align="center" style="margin-bottom: 10px">
				<transition name="fade">
					<v-progress-circular indeterminate color="green" size="100" v-if="isPolling"></v-progress-circular>
					<v-icon v-else size="100" :color="color">{{ icon }}</v-icon>
				</transition>
			</v-col>
			<v-container class="container">
				<h3 class="payment-title">Payment Status</h3>
				<h3 class="payment-title" style="margin-top: 10px">{{ paymentStatus }}</h3>
				<p class="subtitle" style="margin-top: 10px">
					<i>{{ paymentStatusDescription }}</i>
				</p>
				<v-btn color="primary" @click="pollPaymentStatus(id, consent)">Verify Transaction Status</v-btn>
			</v-container>
		</v-row>
	</v-container>
</template>
<script>
import _ from 'lodash';
import ApiService from '../../services/ApiService.js';

export default {
	props: {
		transactionId: { type: String, required: false },
		consent: { type: String, required: false },
		id: { type: String, required: false },
		reference: { type: String, required: false },
		bank: { type: String, required: false },
		payee: { type: Object, required: false }
	},
	computed: {
		icon() {
			switch (this.code) {
				case 'ACSC':
					return 'mdi-check-circle';
				case 'RJCT':
					return 'mdi-close-circle';
				default:
					return 'mdi-timer-sand';
			}
		},
		color() {
			switch (this.code) {
				case 'ACSC':
					return 'green';
				case 'RJCT':
					return 'red';
				default:
					return 'orange';
			}
		}
	},
	data() {
		return {
			response: null,
			isPolling: false,
			code: null,
			paymentStatus: '.....',
			paymentStatusDescription: '.....'
		};
	},
	async created() {
		if (!_.isNil(this.consent)) {
			await this.pollPaymentStatus(this.id, this.consent);
		}
	},
	methods: {
		async pollPaymentStatus(id, consent) {
			this.isPolling = true;
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				action: 'poll_status',
				consent: consent,
				yapilyId: id
			});
			this.code = response.code;
			this.paymentStatus = `(${response.code}) ${this.capitalizeFirstLetter(response.name)}`;
			this.paymentStatusDescription = response.description;
			this.isPolling = false;
			return `(${response.code}) ${this.capitalizeFirstLetter(response.name)}`;
		},
		camelCaseToReadableFormat(str) {
			return str
				.replace(/([A-Z][a-z]|[A-Z]+(?=[A-Z]|$))/g, ' $1')
				.replace(/./, (m) => m.toUpperCase())
				.trim();
		},
		capitalizeFirstLetter(string) {
			return this.camelCaseToReadableFormat(string.charAt(0).toUpperCase() + string.slice(1));
		}
	}
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
