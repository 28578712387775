<template>
	<v-container>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-row v-if="this.method === 'bank' && !showNotice">
			<v-card class="mx-auto" style="width: 600px">
				<v-form v-model="validForm">
					<v-col style="margin-top: 15px">
						<p style="color: red"><i>*** Please fill up additional details ***</i></p>
					</v-col>
					<v-col>
						<input-field required clearable :props="{ min: '1920-01-01' }" v-model="customer.dob" label="Date of Birth" type="date" />
					</v-col>
					<v-col v-if="country === 'Canada'">
						<input-field required v-model="customer.zip" type="special-text" label="Zip Code" />
					</v-col>
					<v-col v-if="additionalFields && bank === 'Trustly'">
						<input-field required v-model="customer.documentNumber" type="special-text" label="Document Number" />
					</v-col>
					<v-col v-if="additionalFields && bank === 'Giropay'">
						<input-field required v-model="customer.bankAccount" type="special-text" label="Bank Account" />
					</v-col>
					<v-col v-if="additionalFields && bank === 'Giropay'">
						<input-field required v-model="customer.bankBic" type="special-text" label="Bank BIC" />
					</v-col>
					<v-col>
						<v-btn text color="deep-purple accent-4" @click="setUrlResponse" :disabled="!validForm"> Continue </v-btn>
					</v-col>
				</v-form>
			</v-card>
		</v-row>
		<v-row v-else>
			<v-card class="mx-auto">
				<v-col>
					<p>Please wait while payment page is being loaded on new tab</p>
				</v-col>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			validForm: false,
			loading: false,
			showNotice: false,
			response: '',
			customer: {
				dob: null,
				zip: null,
				documentNumber: null,
				bankAccount: null,
				bankBic: null
			}
		};
	},
	computed: {
		isValid() {
			return this.customer.dob && this.customer.zip;
		}
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true },
		method: { type: String, required: false },
		country: { type: String, required: false },
		additionalFields: { type: Boolean, required: false },
		bank: { type: String, required: false }
	},
	methods: {
		async setUrlResponse() {
			this.loading = true;
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, { customer: this.customer });
			this.response = response;
			this.loading = false;
			this.showNotice = true;
			this.openPopUp(this.response);
		},
		// eslint-disable-next-line space-before-function-paren
		openPopUp: function (urlToOpen) {
			const popup_window = window.open(
				urlToOpen,
				'myWindow',
				'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, width=800, height=700'
			);
			try {
				popup_window.focus();
			} catch (e) {
				alert('Pop-up Blocker is enabled! Please add this site to your exception list.');
			}
		}
	},
	created() {
		if (this.method !== 'bank') {
			this.showNotice = true;
			this.setUrlResponse();
		}
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 500px;
}
</style>
