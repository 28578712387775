<template>
	<v-container>
		<v-row>
			<v-col>
				<v-container>
					<v-row>
						<v-col cols="12" align="center">
							<v-progress-circular indeterminate color="green" size="120"></v-progress-circular>
						</v-col>
						<v-col>
							<h4 class="payment-title" style="margin: 10px">Please wait for a while...</h4>
							<p class="subtitle">An authorization request has been sent to your device via push notification, and we are awaiting completion.</p>
						</v-col>
					</v-row>
				</v-container>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
// import _ from 'lodash';
import ApiService from '../../services/ApiService.js';

export default {
	props: {
		transactionId: { type: String, required: false },
		id: { type: String, required: false }
	},
	data() {
		return {
			response: null
		};
	},
	async created() {
		if (!_.isNil(this.id)) {
			await this.getConsent();
		}
	},
	methods: {
		async getConsent() {
			const delay = (ms) => new Promise((res) => setTimeout(res, ms));
			await delay(60000);

			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				action: 'get_consent',
				paymentDetails: { id: this.id }
			});

			if (response.success) {
				const { status, consentToken } = response.data;
				this.$emit('success', { status, consentToken });
			}
		}
	}
};
</script>
