<template>
	<v-container>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<iframe v-if="response.redirectUrl" :src="response.redirectUrl"></iframe>
		<v-row style="col-lg-12 col-md-12 col-sm-12" v-else>
			<v-card class="mx-auto" max-width="500px;">
				<v-card-text>
					<div>{{ label }}</div>
					<v-container fluid>
						<v-checkbox>
							<template #label>
								<div>
									I agree to transfer my Account Information to
									<v-tooltip bottom>
										<template #activator="{ on }">
											<a target="_blank" :href="siteDomain" @click.stop v-on="on">
												{{ label }}
											</a>
										</template>
										Opens in new window
									</v-tooltip>
									Website to Open an Account.
								</div>
							</template>
						</v-checkbox>
						<v-checkbox>
							<template #label>
								<div>I agree to automatic withdrawal processing of funds.</div>
							</template>
						</v-checkbox>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-btn text color="deep-purple accent-4" @click="createPaymentRequest"> Continue </v-btn>
				</v-card-actions>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			response: {
				redirectUrl: ''
			},
			loading: false
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true },
		siteDomain: { type: String, required: true },
		label: { type: String, required: true }
	},
	methods: {
		async createPaymentRequest() {
			try {
				this.loading = true;
				const response = await ApiService.publicApi.createPaymentRequest(this.transactionId);
				this.response = response;
				this.loading = false;
			} catch {
				this.loading = false;
			}
		}
	}
};
</script>

<style module>
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap');

.container {
	max-width: 570px;
	margin: auto;
	width: 100%;
}
iframe {
	width: 100%;
	height: 500px;
}
</style>
