<template>
	<v-row justify="center" class="ma-0 align-center" :style="{ height: source === 'Smgg' ? '100%' : 'auto' }">
		<v-alert v-if="!transactionId" color="error" dark type="error" width="1000">Cannot open the payments page without knowing which transaction to pay.</v-alert>
		<v-alert v-else-if="difference > expiryTime" color="error" dark type="error" width="1000">Link expired.</v-alert>
		<v-alert v-else-if="(!timeStamp || !isValidLink) && !isLoading" color="error" dark type="error" width="1000">Invalid link.</v-alert>
		<v-overlay v-else-if="!paymentMethods || isLoading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-alert v-else-if="!paymentMethods.length" color="error" dark type="error" width="1000">
			Encountered a problem getting payment methods for the provided transaction, please try again later.
		</v-alert>
		<component
			:is="template"
			v-if="paymentsPageVisible"
			:transaction-id="transactionId"
			:payment-methods="paymentMethods"
			:sub-source="subSource"
			:transaction-total-amount="transactionTotalAmount"
			:is-latin-only-input="isLatinOnlyInput"
			onload="window.top.scrollTo(0,0);"
		/>
		<!-- <payments-methods-page-desktop
			v-if="paymentsPageVisible && source === 'Smgg' && !isMobile"
			:transaction-id="transactionId"
			:payment-methods="paymentMethods"
			:sub-source="subSource"
			:transaction-total-amount="transactionTotalAmount"
			onload="window.top.scrollTo(0,0);"
		/>
		<payments-methods-page
			v-if="paymentsPageVisible && source !== 'Smgg'"
			:transaction-id="transactionId"
			:payment-methods="paymentMethods"
			:sub-source="subSource"
			:transaction-total-amount="transactionTotalAmount"
			onload="window.top.scrollTo(0,0);"
		/> 
		<payments-methods-page-mobile
			v-if="paymentsPageVisible && source === 'Smgg' && isMobile"
			:transaction-id="transactionId"
			:payment-methods="paymentMethods"
			:sub-source="subSource"
			:transaction-total-amount="transactionTotalAmount"
			onload="window.top.scrollTo(0,0);"
		/> -->
	</v-row>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import ApiService from '../../services/ApiService.js';
import encryptionUtils from '../../shared/encryptionUtils.js';

const SECONDS_IN_SEVEN_DAYS = 604800;

export default {
	components: {
		PaymentsMethodsPageTemplate2Mobile: () => import('../../components/PaymentsMethodsPageTemplate2Mobile.vue'),
		PaymentsMethodsPageTemplate3Mobile: () => import('../../components/PaymentsMethodsPageTemplate3Mobile.vue'),
		PaymentsMethodsPageTemplate2: () => import('../../components/PaymentsMethodsPageTemplate2.vue'),
		PaymentsMethodsPage: () => import('../../components/PaymentsMethodsPage.vue'),
		PaymentsMethodsPageTemplate3: () => import('../../components/PaymentsMethodsPageTemplate3.vue')
	},
	data() {
		return {
			transactionId: null,
			subSource: null,
			paymentMethods: null,
			timeStamp: null,
			transactionTotalAmount: {},
			difference: null,
			isValidLink: false,
			isLoading: false,
			expiryTime: SECONDS_IN_SEVEN_DAYS,
			source: null,
			template: 'PaymentsMethodsPage',
			isLatinOnlyInput: null
		};
	},
	computed: {
		paymentsPageVisible() {
			return this.timeStamp && this.difference < this.expiryTime && this.transactionId && this.isValidLink && this.paymentMethods.length;
		},
		isMobile() {
			return window.innerWidth < 700;
		}
	},
	methods: {
		setTemplate(decodedTemplateKey, isMobile) {
			if (decodedTemplateKey === 'template3') {
				return isMobile ? 'PaymentsMethodsPageTemplate3Mobile' : 'PaymentsMethodsPageTemplate3';
			}
			if (decodedTemplateKey === 'template2') {
				return isMobile ? 'PaymentsMethodsPageTemplate2Mobile' : 'PaymentsMethodsPageTemplate2';
			}
			return this.template;
		},
		async getPaymentMethods() {
			this.isLoading = true;
			this.transactionId = decodeURIComponent(this.$router.currentRoute.query.id);
			this.subSource = decodeURIComponent(this.$router.currentRoute.query.subSource);
			this.timeStamp = decodeURIComponent(this.$router.currentRoute.query.timeStamp);
			this.isLatinOnlyInput = _.lowerCase(this.$router.currentRoute.query.isLatinOnlyInput) !== 'false';
			if (this.$router.currentRoute.query.template) {
				const decodedTemplateKey = decodeURIComponent(this.$router.currentRoute.query.template);
				this.template = this.setTemplate(decodedTemplateKey, this.isMobile);
			}

			if (this.transactionId) {
				this.paymentMethods = await ApiService.publicApi.getAvailablePaymentMethods(this.transactionId);

				this.transactionTotalAmount = await ApiService.publicApi.getTransactionDetails(this.transactionId);
				this.source = this.transactionTotalAmount.source;
				this.isLoading = false;
				const createdDateHash = await encryptionUtils.getSha256Hash(this.transactionTotalAmount.createdDate);
				this.isValidLink = createdDateHash === this.timeStamp;
				this.difference = moment().diff(this.transactionTotalAmount.createdDate, 'seconds');
				if (this.transactionTotalAmount.source !== 'BrandsMaster' || _.toLower(this.transactionTotalAmount.leadName) === 'auto qa test auto qa test') {
					this.difference = 0;
					this.isValidLink = true;
				}
			}
		}
	},
	created() {
		this.getPaymentMethods();
	}
};
</script>
