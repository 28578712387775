import { render, staticRenderFns } from "./Astropay.vue?vue&type=template&id=34a073d0"
import script from "./Astropay.vue?vue&type=script&lang=js"
export * from "./Astropay.vue?vue&type=script&lang=js"
import style0 from "./Astropay.vue?vue&type=style&index=0&id=34a073d0&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports