<template>
	<div>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>

		<form method="POST" :action="this.host" ref="form" target="_blank">
			<fieldset class="forFotm">
				<input type="hidden" name="merchant_id" v-model="data.merchant_id" />
				<input type="hidden" name="order_id" v-model="data.order_id" />
				<input type="hidden" name="amount" v-model="data.amount" />
				<input type="hidden" name="currency" v-model="data.currency" />
				<input type="hidden" name="order_desc" v-model="data.order_desc" />
				<input type="hidden" name="return_url" v-model="data.return_url" />
				<input type="hidden" name="response_url" v-model="data.response_url" />
				<input type="hidden" name="cancel_url" v-model="data.cancel_url" />
				<input type="hidden" name="server_url" v-model="data.server_url" />
				<input type="hidden" name="locale" v-model="data.locale" />
				<input type="hidden" name="currency2currency" v-model="data.currency2currency" />
				<input type="hidden" name="client_expense" v-model="data.client_expense" />
				<input type="hidden" name="signature" v-model="data.signature" />
			</fieldset>
		</form>
		<p v-if="data.merchant_id">The payment page loaded on new tab.</p>
	</div>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			data: {},
			loading: false
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true },
		host: { type: String, required: true }
	},
	methods: {
		async setUrlResponse() {
			this.loading = true;
			this.data = await ApiService.publicApi.createPaymentRequest(this.transactionId);
			this.loading = false;
		}
	},
	async created() {
		await this.setUrlResponse();
		this.$refs.form.submit();
	}
};
</script>

<style>
.errorCard {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;
	padding: 0 15px;
	font-size: 13px;
	opacity: 0;
	transform: translateY(10px);
	transition-property: opacity, transform;
	transition-duration: 0.35s;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.errorCard.visible {
	opacity: 1;
	transform: none;
}
fieldset.displayNone {
	display: none;
}
.forFotm {
	display: none;
}
</style>
