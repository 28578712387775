<template>
	<v-container class="pa-0">
		<v-row :class="$style['text-wrapper']">
			<v-col sm="4" md="4" :class="$style['left-col']">
				<div class="w-100" style="width: 100%; line-height: 1">
					<div>
						<v-avatar size="50" v-if="pageTemplate.logo">
							<img :src="pageTemplate.logo" alt="John" />
						</v-avatar>
						<div :class="$style['mb-30']" class="d-flex flex-column align-center ml-2">
							<span :class="$style['ft-32']" class="text-uppercase text-bold white--text">{{ $t('paymentOptions.checkout') }}</span>
						</div>
					</div>
					<div class="mt-5">
						<div :class="$style['payment-info']" class="d-flex w-100 flex-column text-left" style="width: 100%">
							<div @click="onFirstStep" class="pb-0" :class="[step >= 1 ? $style['active'] : '', $style['item']]">
								<div :class="[step >= 1 ? $style['active-text'] : '', $style['menu-item']]">
									<div class="d-flex align-center">
										<Icon width="1.2em" height="1.2em" class="mr-2" icon="iconoir:credit-cards" />
										<!--<v-icon :color="step >= 1 ? '#fff': '#B58AF7'" icon="fa-check" class="pr-2" size="22"></v-icon> -->
										<span style="font-size: 16px !important; font-weight: 400">{{ $t('paymentOptions.paymentMethod') }}</span>
									</div>

									<div :class="[$style['payment-method'], $style['pl-40']]" v-if="activePaymentMethod !== 'default'">{{ getPaymentMethod }}</div>
								</div>
							</div>
							<div
								@click="onSecondStep"
								class="pb-0"
								:class="[(activePaymentMethod !== 'default' && getPspType) || statusPending ? $style['active'] : '', $style['item']]"
							>
								<div :class="[(activePaymentMethod !== 'default' && getPspType) || statusPending ? $style['active-text'] : '', $style['menu-item']]">
									<div class="d-flex align-center">
										<Icon width="1.2em" height="1.2em" class="mr-2" icon="solar:card-2-linear" />
										<!--<v-icon :color="activePaymentMethod !== 'default' && getPspType ? '#fff': '#B58AF7'" class="pr-2" size="22">mdi-card-bulleted</v-icon>-->
										<span style="font-size: 16px !important; font-weight: 400">{{ $t('paymentOptions.paymentInformation') }}</span>
									</div>
									<div :class="[$style['payment-method'], $style['pl-40']]" v-if="statusPending">{{ getPspType }}</div>
								</div>
							</div>
							<div class="pb-0" :class="[statusPending ? $style['active'] : '', $style['item']]">
								<div :class="[statusPending ? $style['active-text'] : '', $style['menu-item']]">
									<div class="d-flex align-center">
										<Icon width="1.2em" height="1.2em" class="mr-2" icon="mynaui:bookmark-check" />
										<!--<v-icon :color="statusPending ? '#fff': '#B58AF7'" class="pr-2" size="22">mdi-bookmark-check-outline</v-icon>-->
										<span style="font-size: 16px !important; font-weight: 400">{{ $t('paymentOptions.paymentStatus') }}</span>
									</div>
									<div :class="[$style['payment-method'], $style['pl-40']]" v-if="statusPending">{{ $t('paymentOptions.pending') }}</div>
								</div>
							</div>
							<div v-if="!statusPending" :class="[$style['item'], $style['pb-50']]">
								<div :class="[$style['menu-item'], $style['no-border']]">
									<div class="d-flex align-center"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="mb-3" :class="$style['total-cost']">{{ $t('paymentOptions.totalPurchaseCost') }}</div>
					<div v-if="Object.keys(transactionTotalAmount).length" style="font-weight: 600; font-size: 20px">
						{{ transactionTotalAmount.amount.toFixed(2) }} {{ transactionTotalAmount.currency }}
					</div>
				</div>
			</v-col>
			<v-col sm="8" md="8" :class="$style['right-col']" class="d-flex">
				<v-container class="d-flex align-center" v-if="loading && activePaymentMethod === 'wire'">
					<v-row class="justify-space-around">
						<v-col cols="auto">
							<v-progress-circular :class="$style['blue']" class="mb-5" indeterminate size="50" />
							<br />
							<h2 :class="$style['loading-info']" style="text-align: center">Loading....</h2>
							<p :class="$style['loading-info']" style="text-align: center">Please wait, It will take a moment</p>
						</v-col>
					</v-row>
				</v-container>
				<div v-else style="width: 100%" :class="$style['card-form-wrp']" class="pa-5">
					<v-container v-if="activePaymentMethod === 'default'">
						<v-row align="center" justify="center">
							<v-col>
								<v-row class="justify-center" :class="$style['mt-100']">
									<div :class="[$style['gray-text'], $style['fw-400']]" class="mb-5">
										<div class="d-flex justify-center align-center">
											<div :class="[$style['ft-24'], $style['gray-text']]" cols="9" sm="10" md="11">
												{{ $t('paymentOptions.pickYouPreferredPaymentChannel') }}
											</div>
										</div>
									</div>
								</v-row>
								<v-row class="justify-center mt-5">
									<v-col cols="12" md="4" class="ma-5">
										<v-card class="pa-3" :class="$style['card-shadow']" @click="selectPaymentMethod('credit_card')" rounded="lg">
											<div>
												<Icon color="#777777" icon="solar:card-linear" width="4em" height="4em" />
												<!--<v-icon color="#777777" size="50">mdi-credit-card</v-icon> -->
											</div>
											<v-card-text class="pb-2" :class="[$style['card-text-color'], $style['ft-18']]">
												{{ $t('paymentOptions.creditCard') }}
											</v-card-text>
										</v-card>
									</v-col>
									<v-col v-if="doesMethodExists('StaticInfo')" class="ma-5" cols="12" md="4">
										<v-card class="pa-3" :class="$style['card-shadow']" @click="selectPaymentMethod('wire')" rounded="lg">
											<div>
												<Icon color="#777777" icon="solar:card-transfer-linear" width="4em" height="4em" />
											</div>
											<v-card-text class="pb-2" :class="[$style['card-text-color'], $style['ft-18']]">
												{{ $t('paymentOptions.wireTransfer') }}
											</v-card-text>
										</v-card>
									</v-col>
									<v-col v-if="doesMethodExists('FireblocksBridge')" class="ma-5" cols="12" md="4">
										<v-card class="pa-3" :class="$style['card-shadow']" @click="selectPaymentMethod('fireblocks_bridge')" rounded="lg">
											<div>
												<v-icon size="50">mdi-shield-lock</v-icon>
											</div>
											<v-card-text class="pb-2" :class="[$style['card-text-color'], $style['ft-18']]"> Fireblocks Bridge </v-card-text>
										</v-card>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-container>
					<div v-if="hideDisplay && !showPaymentCard">
						<div v-if="activePaymentMethod === 'credit_card'">
							<!-- <v-row :class="$style['text-title']" class="my-5">
									<v-col cols="12" sm="12" md="12">
										<h1 class="text-uppercase" style="font-size: 20px">{{ cryptoSite }}</h1>
									</v-col>
								</v-row> -->
							<v-row :class="$style['plr-60']" class="mb-5">
								<v-col>
									<v-card class="pl-5 pr-5" :class="[$style['plr-70'], $style['box-shadow']]">
										<v-row class="align-center">
											<v-col class="text-left d-flex align-center">
												<Icon color="#777777" icon="solar:card-linear" width="1.4em" height="1.4em" />
												<span :class="[$style['card-text-color'], $style['ft-18']]" class="ml-2">{{ getPaymentMethod }}</span>
											</v-col>
											<v-col class="text-right">
												<span @click="onChange" :class="[$style['purple-color'], $style['fw-500'], $style['pointer'], $style['ft-18']]" class="pointer">{{
													$t('paymentOptions.change')
												}}</span>
											</v-col>
										</v-row>
									</v-card>
								</v-col>
							</v-row>
							<v-row :class="$style['plr-100']" no-gutters class="d-flex flex-row" style="overflow-y: scroll; max-height: 550px">
								<v-col
									v-for="paymentMethod in paymentMethods.filter((item) => item.psp !== 'StaticInfo' && item.psp !== 'FireblocksBridge')"
									:key="paymentMethod.id"
									cols="12"
									sm="12"
									md="5"
									class="ma-4"
								>
									<v-card
										:loading="choosingPaymentMethod === paymentMethod.id"
										:disabled="!!choosingPaymentMethod"
										:style="pageTemplate.style"
										@click="choosePaymentMethod(paymentMethod.id)"
										:qa-e2e="paymentMethod.name"
										style="border-radius: 15px"
										class="mx-1 my-2"
										:class="$style['box-shadow']"
									>
										<v-img :src="paymentMethod.image" height="100px" contain />
										<div :class="$style['method-text']" style="width: 100%; font-size: 14px; font-weight: 400" class="text-capitalize pa-1">
											{{ paymentMethod.name }}
										</div>
									</v-card>
								</v-col>
							</v-row>
						</div>
						<div v-if="activePaymentMethod === 'wire'">
							<!-- <v-row :class="$style['text-title']" class="my-5">
									<v-col cols="12" sm="12" md="12">
										<h1 class="text-uppercase" style="font-size: 20px">{{ cryptoSite }}</h1>
									</v-col>
								</v-row> -->
							<v-row :class="$style['plr-60']" class="mb-5">
								<v-col>
									<v-card class="pl-5 pr-5" :class="$style['box-shadow']">
										<v-row class="align-center">
											<v-col class="text-left d-flex align-center">
												<Icon color="#777777" icon="solar:card-transfer-linear" width="1.4em" height="1.4em" />
												<span :class="[$style['card-text-color'], $style['ft-18']]" class="ml-2">{{ getPaymentMethod }}</span>
											</v-col>
											<v-col class="text-right">
												<span @click="onChange" :class="[$style['purple-color'], $style['fw-500'], $style['pointer'], $style['ft-18']]" class="pointer">{{
													$t('paymentOptions.change')
												}}</span>
											</v-col>
										</v-row>
									</v-card>
								</v-col>
							</v-row>
							<v-row :class="$style['plr-100']" no-gutters class="d-flex flex-row" style="overflow-y: scroll; max-height: 550px">
								<v-col
									class="ma-4"
									v-for="paymentMethod in paymentMethods.filter((item) => item.psp === 'StaticInfo')"
									:key="paymentMethod.id"
									cols="12"
									sm="12"
									md="5"
								>
									<v-card
										:loading="choosingPaymentMethod === paymentMethod.id"
										:disabled="!!choosingPaymentMethod"
										:style="pageTemplate.style"
										@click="choosePaymentMethod(paymentMethod.id)"
										:qa-e2e="paymentMethod.name"
										style="border-radius: 15px"
										class="mx-1 my-2"
										:class="$style['box-shadow']"
									>
										<v-img :src="paymentMethod.image" height="100px" contain />
										<div :class="$style['method-text']" style="width: 100%; font-size: 14px; font-weight: 400" class="text-capitalize pa-1">
											{{ paymentMethod.name }}
										</div>
									</v-card>
								</v-col>
							</v-row>
						</div>
						<div v-if="activePaymentMethod === 'fireblocks_bridge'">
							<v-row :class="[$style['text-title'], $style['plr-60']]" class="my-5">
								<v-col cols="12" sm="12" md="12">
									<h1 class="text-uppercase" style="font-size: 20px">{{ cryptoSite }}</h1>
								</v-col>
							</v-row>
							<v-row :class="$style['plr-100']" no-gutters class="d-flex flex-row" style="overflow-y: scroll; max-height: 550px">
								<v-col
									class="ma-4"
									v-for="paymentMethod in paymentMethods.filter((item) => item.psp === 'FireblocksBridge')"
									:key="paymentMethod.id"
									cols="12"
									sm="12"
									md="5"
								>
									<v-card
										:loading="choosingPaymentMethod === paymentMethod.id"
										:disabled="!!choosingPaymentMethod"
										:style="pageTemplate.style"
										@click="choosePaymentMethod(paymentMethod.id)"
										:qa-e2e="paymentMethod.name"
										style="border-top-right-radius: 15px; border-top-left-radius: 15px"
										class="mx-1 my-2"
									>
										<v-img :src="paymentMethod.image" height="100px" contain />
										<div :class="$style['method-text']" style="width: 100%; font-size: 14px; font-weight: 400; color: white" class="text-capitalize pa-1">
											{{ paymentMethod.name }}
										</div>
									</v-card>
								</v-col>
							</v-row>
						</div>
					</div>
					<div :class="$style['plr-100']" v-if="showPaymentCard">
						<div v-if="paymentPageDetails && !loading && paymentPageDetails.pspType === 'StaticInfo'">
							<v-row v-if="!statusPending">
								<v-col cols="6">
									<v-row>
										<v-col cols="2 pr-0">
											<Icon icon="solar:shield-check-outline" width="1.8em" height="1.8em" color="#37D476" />
										</v-col>
										<v-col class="text-left pt-1">
											<div :class="[$style['ft-24'], $style['grey']]" class="ft-24">{{ $t('paymentOptions.wireTransfer') }}</div>
											<div :class="$style['psp-text']">
												{{ $t('paymentOptions.using') }} {{ paymentPageDetails.pspType }} {{ $t('paymentOptions.system') }}
											</div>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</div>
						<payments-page
							v-if="paymentPageDetails && !paymentProcessed && !paymentRejected"
							:payment-page-details="paymentPageDetails"
							@submit="uploadFile"
							@file-selected="onFileSelected"
							@cancel-payment-method="onPaymentMethodCanceled"
							@payment-finished="onPaymentFinished"
							@payment-submited="onPaymentSubmit"
						/>
						<component v-else :is="getComponent" />
					</div>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapMutations } from 'vuex';
import ApiService from '../services/ApiService.js';
import VueService from '../services/VueService.js';
import { Icon } from '@iconify/vue2';

export default {
	components: {
		PaymentsPage: () => import('./PaymentsPageTemplate3.vue'),
		PaymentProcessed: () => import('./PaymentResponses/PaymentProcessedTemplate3.vue'),
		PaymentRejected: () => import('./PaymentResponses/PaymentRejectedTemplate3.vue'),
		Icon
	},
	props: {
		transactionId: { type: String, required: true },
		subSource: { type: String, required: false },
		paymentMethods: { type: Array, required: true },
		transactionTotalAmount: { type: Object, required: false },
		isLatinOnlyInput: { type: Boolean, required: true }
	},
	data() {
		return {
			choosingPaymentMethod: false,
			paymentPageDetails: null,
			pageTemplate: {},
			hideDisplay: true,
			showPaymentCard: false,
			activePaymentMethod: 'default',
			cryptoSite: null,
			step: 1,
			statusPending: false,
			loading: false,
			paymentProcessed: false,
			paymentRejected: false
		};
	},
	async mounted() {
		await this.setPageTemplate();
		this.cryptoSite = this.$route.query.subSource;
		this.setIsNewPage(true);
	},
	computed: {
		getPaymentMethod() {
			if (this.activePaymentMethod === 'credit_card') {
				return 'Credit card';
			}
			if (this.activePaymentMethod === 'wire') {
				return 'Wire transfer';
			}
			return '';
		},
		getPspType() {
			if (this.paymentPageDetails) {
				return this.paymentPageDetails.pspType;
			}
			return '';
		},
		getComponent() {
			if (this.paymentProcessed) {
				return 'PaymentProcessed';
			}
			if (this.paymentRejected) {
				return 'PaymentRejected';
			}
			return '';
		}
	},
	methods: {
		...mapMutations({
			setIsNewPage: 'setIsNewPage'
		}),
		doesMethodExists(method) {
			return this.paymentMethods.filter((item) => item.psp === method)[0];
		},
		async setPageTemplate() {
			this.pageTemplate = await ApiService.publicApi.getDepositTemplate(this.subSource);
		},
		async choosePaymentMethod(paymentMethodId, setPending = false) {
			if (setPending) {
				this.statusPending = true;
			}
			try {
				this.choosingPaymentMethod = true;
				this.showPaymentCard = true;
				this.paymentPageDetails = await ApiService.publicApi.selectPaymentMethod(this.transactionId, paymentMethodId);
				if (this.subSource) {
					this.paymentPageDetails.subSource = this.subSource;
				}
				this.paymentPageDetails.isLatinOnlyInput = this.isLatinOnlyInput;
				//	this.statusPending = true;
			} catch (error) {
				if (process.env.NODE_ENV === 'development') {
					console.log(error);
				}
			}
			this.choosingPaymentMethod = false;
		},
		onPaymentMethodCanceled() {
			this.paymentPageDetails = null;
			this.choosingPaymentMethod = false;
			this.showPaymentCard = false;
		},
		async uploadFile() {
			if (!this.fileObject || !this.fileObject.name) {
				return;
			}
			this.loading = true;
			try {
				await ApiService.publicApi.uploadFile({ transactionId: this.paymentPageDetails.transactionId }, { fileName: this.fileObject.name, base64: this.base64 });
				VueService.showToastMessage({ message: this.$t('staticInfo.fileUpload.success'), type: 'success' });
				//	this.paymentPageDetails = null;
				this.statusPending = true;
				this.loading = false;
				this.paymentProcessed = true;
			} catch (error) {
				console.error(error);
				VueService.showToastMessage({ message: this.$t('staticInfo.fileUpload.error'), type: 'error' });
				this.loading = false;
				this.paymentRejected = true;
			}
		},
		async onFileSelected(evt) {
			[this.fileObject] = evt.target.files;
			this.base64 = await this.createBase64(this.fileObject);
		},
		createBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(file);
			});
		},
		async selectPaymentMethod(selectedMethod) {
			this.showPaymentCard = false;
			this.hideDisplay = true;
			this.choosingPaymentMethod = false;
			this.paymentPageDetails = null;
			this.activePaymentMethod = selectedMethod;
		},
		onChange() {
			this.activePaymentMethod = 'default';
		},
		onFirstStep() {
			this.activePaymentMethod = 'default';
			this.hideDisplay = true;
			this.showPaymentCard = false;
			this.statusPending = false;
		},
		onSecondStep() {
			//	this.activePaymentMethod = 'default';
			this.showPaymentCard = false;
			this.hideDisplay = true;
			this.choosingPaymentMethod = false;
			this.paymentPageDetails = null;
			this.statusPending = false;
		},
		onPaymentFinished(details) {
			this.step = 2;
			this.statusPending = true;
		},
		onPaymentSubmit() {
			this.statusPending = true;
			this.loading = false;
		}
	}
};
</script>

<style module scoped>
.title-container {
	display: flex;
	width: 100%;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	align-content: center;
	gap: 10px;
}

.title-container > img {
	flex: 0 0 12%;
}

.container {
	max-width: 200px;
	min-width: 100px;
}

.titleWrapper {
	background-image: linear-gradient(135deg, var(--primaryColor), var(--secondaryColor), var(--tertiaryColor)) !important;
}

.text-wrapper {
	height: 720px;
	width: 100%;
	overflow: hidden;
	border-radius: 20px;
	margin: 100px 0px;
	box-shadow: 4px 5px 15px -2px #e0e0e0;
}

.left-col {
	height: 100%;
	background: linear-gradient(285.21deg, #6814e8 0%, #9756fb 100%);
	/* background: green; */
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	color: #e1e1e1;
	display: flex;
	align-items: center;
	padding: 95px 30px 70px 30px;
	position: relative;
	flex-direction: column;
	justify-content: space-between;
}

.text-title {
	background: linear-gradient(90.55deg, #260466 0%, #260492 100%);
	width: 100%;
	margin: auto;
	color: white;
	text-align: left;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}

.right-col {
	height: 100%;
	position: relative;
	background: #fff;
	overflow-y: auto;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	padding-top: 30px;
	/* padding: 100px; */
}

.pick-method {
	/* position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); */
	height: 100%;
	font-size: 20px;
}

.method-text {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	height: 28px;
	color: #4c4c4c;
	overflow: hidden;
}

.ft-18 {
	font-size: 18px;
}

.ft-32 {
	font-size: 32px;
}

.br-4 {
	border-radius: 4px;
}

.img {
	display: flex;
	margin-right: 3px;
	text-align: center;
	align-items: center;
	justify-items: center;
}

.gray-text {
	color: #777777;
}

.card-text-color {
	color: #4c4c4c !important;
}

.card-shadow {
	box-shadow: 0 3px 1px -2px rgba(76, 93, 76, 0.1), 0 2px 2px 0 rgba(0, 0, 0, -1), 0 5px 4px 0 rgba(0, 0, 0, 0.12) !important;
}

.total-cost {
	font-size: 20px;
}

.payment-info div {
	padding: 8px 0 12px 10px;
}

.payment-info {
	padding: 10px 30px 50px 35px;
}

.payment-info .active {
	border-left: 2px solid #f9f9f9 !important;
}

.payment-info .active-text {
	color: #fff;
}

.payment-info .item {
	border-left: 2px solid #985bf5;
}

.payment-info h6 {
	padding: 8px;
	cursor: pointer;
}

.ft-18 {
	font-size: 18px;
}

.ft-24 {
	font-size: 24px;
}

.fw-400 {
	font-weight: 400;
}

.fw-500 {
	font-weight: 500;
}

.pointer {
	cursor: pointer;
}

.purple-color {
	color: #6814e8;
}

.payment-method {
	font-size: 20px;
	color: #f5f5f5;
}

.menu-item {
	color: #b58af7;
	border-bottom: 1px solid #975bf0;
	padding: 25px;
	margin-left: 12px;
}

.no-border {
	border-bottom: none;
}

.grey {
	color: #777777;
}

.loading-info {
	color: #5e5e5e;
}

.blue {
	color: #7b61ff;
}

.pl-40 {
	padding-left: 40px !important;
}

.mt-100 {
	margin-top: 100px;
}

.plr-100 {
	padding: 0 100px;
}

.plr-60 {
	padding: 0 60px;
}

.plr-70 {
	padding: 0 70px;
}

/* width */
::-webkit-scrollbar {
	width: 2px;
}

.mb-25 {
	margin-bottom: 25px;
}

.mb-30 {
	margin-bottom: 30px;
}

.pb-50 {
	padding-bottom: 50px;
}

.card-form-wrp {
	position: relative;
}

.psp-text {
	line-height: 10px;
	font-size: 14px;
}

.box-shadow {
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) !important;
}
/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
@media (min-width: 601px) and (max-width: 1264px) {
	.left-col {
		padding: 20px;
	}
}

@media (min-width: 960px) and (max-width: 1025px) {
	.text-wrapper {
		height: 100vh;
	}
}

@media (min-width: 1904px) {
	:global(.container) {
		max-width: 1185px !important;
	}
}
</style>
