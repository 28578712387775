<template>
	<v-container fluid>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-row style="margin-bottom: 10px">
			<v-card class="mx-auto" style="width: 750px">
				<img :src="require('../../assets/the-vouchers.png')" style="width: 250px; height: 60px; margin-top: 10px; margin-bottom: 10px" />
			</v-card>
		</v-row>
		<v-row>
			<v-card class="mx-auto" style="width: 750px">
				<v-card-title>
					<v-tabs v-model="tab" fixed-tabs dark>
						<v-tab href="#tab-1">PURCHASE VOUCHER</v-tab>
						<v-tab href="#tab-2">REDEEM VOUCHER</v-tab>
					</v-tabs>
				</v-card-title>
				<v-card-text>
					<v-tabs-items v-model="tab">
						<v-tab-item value="tab-1" id="tab-1">
							<v-card flat>
								<v-card-text>
									<v-container>
										<v-row style="col-lg-12 col-md-12 col-sm-12" v-if="!renderWireInfo">
											<v-card flat class="mx-auto" style="width: 700px">
												<v-col cols="12">
													<input-field
														required
														clearable
														:props="{ items: listOfCountries, styleType: 'outlined' }"
														v-model="customer.originFunds"
														label="Origin of Funds"
														type="select"
													/>
												</v-col>
												<v-col cols="12">
													<input-field
														clearable
														label="Payer's ID/SSN"
														type="specialtext"
														:props="{ styleType: 'outlined' }"
														v-model="customer.personalNumber"
													/>
												</v-col>
												<v-card-actions class="d-flex justify-center">
													<v-btn text color="deep-purple accent-4" @click="purchaseRequest" :disabled="!customer.originFunds"> Confirm </v-btn>
												</v-card-actions>
											</v-card>
										</v-row>

										<v-row style="col-lg-12 col-md-12 col-sm-12" v-if="renderWireInfo && purchaseResponse.success">
											<v-card flat style="width: 700px">
												<v-card-text>
													<p style="text-align: left; margin: 2px; font-size: 14px">
														<i>
															Below, you will find the necessary payment instructions. Please follow the instructions carefully to ensure prompt and
															accurate processing of your payment.
														</i>
													</p>
													<br />
													<p style="text-align: left; margin: 2px; font-size: 18px"><b>Wire Payment Instructions</b></p>
													<br />
													<template v-for="(value, key) in purchaseResponse.data">
														<p style="text-align: left; margin: 2px; font-size: 15px" v-if="value !== ''" :key="key">
															<b>{{ toTitleCase(key.replace('_', ' ')) }}:</b> {{ decodeURI(value) }}
														</p>
													</template>
													<v-divider />
													<p style="text-align: right; margin: 5px; font-size: 15px">
														<b>Total Amount: {{ amount.toFixed(2) }} {{ currency }}</b>
													</p>
													<br />
													<p style="text-align: left; margin: 2px; font-size: 14px">
														<i> Once payment is confirmed, you will receive an email containing a voucher token for redemption. </i>
													</p>
												</v-card-text>
											</v-card>
										</v-row>

										<v-row style="col-lg-12 col-md-12 col-sm-12" v-if="renderWireInfo && !purchaseResponse.success">
											<v-card flat style="width: 700px">
												<v-card flat class="mx-auto" style="width: 700px">
													<v-row align="center">
														<v-col cols="12" align="center" style="margin-bottom: 10px; margin-top: 10px">
															<transition name="fade">
																<v-icon size="140" color="red" v-if="!purchaseResponse.success">mdi-close-circle</v-icon>
																<v-icon size="140" color="green" v-else>mdi-check-circle</v-icon>
															</transition>
														</v-col>
														<v-container class="container">
															<h2 class="payment-title">RESULT</h2>
															<h3 class="payment-title" style="margin-top: 10px">{{ purchaseResponse.data.ReplyDesc }}</h3>
															<p class="subtitle" style="margin-top: 10px">
																<i v-if="!purchaseResponse.success">
																	We encountered a problem while attempting to fetch the wire instructions. If the issue persists, contact our
																	customer support for further assistance. <br />
																	You may now close this window, Thank You!
																</i>
															</p>
														</v-container>
													</v-row>
												</v-card>
											</v-card>
										</v-row>
									</v-container>
								</v-card-text>
							</v-card>
						</v-tab-item>
						<v-tab-item value="tab-2" id="tab-2">
							<v-card flat>
								<v-container>
									<v-row style="col-lg-12 col-md-12 col-sm-12" v-if="!renderStatus">
										<v-card flat class="mx-auto" style="width: 700px">
											<v-col cols="12">
												<input-field
													clearable
													label="Voucher Token"
													type="specialtext"
													:props="{ styleType: 'outlined' }"
													v-model="customer.voucherToken"
												/>
											</v-col>
											<v-card-actions class="d-flex justify-center">
												<v-btn text color="deep-purple accent-4" @click="redeemRequest"> Redeem </v-btn>
											</v-card-actions>
										</v-card>
									</v-row>
									<v-row style="col-lg-12 col-md-12 col-sm-12" v-if="renderStatus">
										<v-card flat class="mx-auto" style="width: 700px">
											<v-row align="center">
												<v-col cols="12" align="center" style="margin-bottom: 10px; margin-top: 10px">
													<transition name="fade">
														<v-icon size="140" color="red" v-if="!redeemResponse.success">mdi-close-circle</v-icon>
														<v-icon size="140" color="green" v-else>mdi-check-circle</v-icon>
													</transition>
												</v-col>
												<v-container class="container">
													<h2 class="payment-title">RESULT</h2>
													<h3 class="payment-title" style="margin-top: 10px">{{ redeemResponse.data.replyMessage }}</h3>
													<p class="subtitle" style="margin-top: 10px">
														<i v-if="!redeemResponse.success">
															We encountered a problem while attempting to redeem your voucher. Please verify the voucher token you have entered and
															try again. If the issue persists, contact our customer support for further assistance.
															<br />
															<br />
															You may now close this window, Thank You!
														</i>
														<i v-if="redeemResponse.success">
															We are pleased to inform you that your voucher token redemption was successful, and the corresponding amount will be
															reflected in your account shortly.
															<br />
															<br />
															You may now close this window, Thank You!
														</i>
													</p>
												</v-container>
											</v-row>
										</v-card>
									</v-row>
								</v-container>
							</v-card>
						</v-tab-item>
					</v-tabs-items>
				</v-card-text>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			loading: false,
			tab: null,
			purchaseResponse: null,
			redeemResponse: null,
			renderWireInfo: false,
			renderStatus: false,
			customer: {
				originFunds: null,
				personalNumber: null,
				voucherToken: null
			}
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true },
		listOfCountries: { type: Array, required: true }
	},
	methods: {
		toTitleCase(str) {
			return str.replace(/\w\S*/g, (txt) => {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
			});
		},
		async purchaseRequest() {
			try {
				this.loading = true;
				const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, { customer: this.customer });
				this.purchaseResponse = response;
				this.renderWireInfo = true;
				this.loading = false;
			} catch (err) {
				throw new Error(err);
			}
		},
		async redeemRequest() {
			try {
				this.loading = true;
				const response = await ApiService.publicApi.createRedeemRequest(this.transactionId, { customer: this.customer });
				this.redeemResponse = response;
				this.renderStatus = true;
				this.loading = false;
			} catch (err) {
				throw new Error(err);
			}
		}
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 500px;
}
.container {
	max-width: 800px;
	margin: auto;
	width: 100%;
}
</style>
