<template>
	<v-container>
		<v-container v-if="result === '/paymentProcessed'">
			<iframe :src="result"></iframe>
		</v-container>
		<v-container v-else>
			<iframe name="resultIframe" id="resultIframe"></iframe>
			<iframe name="preconForm" id="preconForm" hidden></iframe>

			<form name="preconditionForm" ref="preconditionForm" :action="preconditions[0].url" method="POST" target="preconForm">
				<div v-for="(precondition, index) in preconditions" :key="index">
					<input v-for="(parameter, paramIndex) in precondition.parameters" :key="paramIndex" type="hidden" :name="parameter.name" :value="parameter.value" />
				</div>
			</form>

			<form name="redirectForm" ref="redirectForm" :action="redirect.url" method="POST" target="resultIframe">
				<input v-for="(param, index) in redirect.parameters" :key="index" type="hidden" :name="param.name" :value="param.value" />
			</form>
		</v-container>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			data: null,
			result: null,
			redirect: {},
			preconditions: []
		};
	},
	props: {
		response: { type: String, required: true }
	},
	methods: {
		async transformSource() {
			if (this.response !== '/paymentProcessed') {
				const { redirect, preconditions } = this.response;
				this.redirect = redirect;
				this.preconditions = preconditions;
			} else {
				this.result = this.response;
			}
		}
	},
	async created() {
		await this.transformSource();
		this.$refs.preconditionForm.submit();
		this.$refs.redirectForm.submit();
	}
};
</script>
<style scoped>
iframe {
	width: 100%;
	height: 500px;
}
</style>
