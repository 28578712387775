<template>
	<v-container>
		<v-row align="center">
			<v-col cols="12" align="center" style="margin-bottom: 5px">
				<v-icon size="130" color="red">mdi-close-circle-outline</v-icon>
			</v-col>
			<v-container class="container" v-if="tracingId">
				<h3 class="payment-title">{{ description }}</h3>
				<h3 class="payment-title" style="margin-top: 10px">Tracing Id: {{ tracingId }}</h3>
				<p class="subtitle" style="margin-top: 10px">
					<i>Oops! It appears there's been an issue with your transaction. Kindly copy and forward the Tracing ID to our support team for further assistance.</i>
				</p>
			</v-container>
			<v-container class="container" v-else>
				<h3 class="payment-title">Authorization Failed</h3>
				<p class="subtitle" style="margin-top: 10px">
					<i>Oops! It appears there's been an issue with your authorization.</i>
				</p>
			</v-container>
		</v-row>
	</v-container>
</template>
<script>
export default {
	props: {
		transactionId: { type: String, required: false },
		error: { type: Object, required: false }
	},
	data() {
		return {
			response: null
		};
	},
	computed: {
		tracingId() {
			return this?.error?.tracingId ?? null;
		},
		description() {
			return `${this.error.status} (${this.error.code})`;
		}
	}
};
</script>
