<!-- We need to change this in the next sprint -->
<template>
	<payment-rejected />
</template>

<script>
export default {
	components: {
		PaymentRejected: () => import('../../components/PaymentResponses/PaymentRejected.vue')
	}
};
</script>
