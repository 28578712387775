<template>
	<div>
		<!--<div class="otp-wrap mb-30">
			<v-row>
				<v-col cols="4">
					<v-row>
						<v-col cols="2">
							<v-icon color="#37D476" class="pr-2" size="32">mdi-shield-check-outline</v-icon>
						</v-col>
						<v-col class="text-left pt-1">
							<div class="ft-24 text--grey">Credit card</div>
							<div>Using psp system</div>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</div> -->
		<div class="mb-60 mt-5">
			<v-row>
				<v-col cols="12">
					<div class="secure-checkout ft-20">Secure Checkout</div>
				</v-col>
			</v-row>
		</div>
		<div :class="[isMobile ? 'text-left': 'text-center']">
			<div class="fw-500 dark-text ft-20 mb-20">
				2 Factor Authentication
			</div>
			<div class="ft-18">
				A code was sent to your phone number, Please type and submit to continue
			</div>
		</div>
		<div class="otp-input-container mb-20 mt-20">
			<input :style="{'width': isMobile ? '100%': ''}" placeholder="Enter OTP" type="text" v-model="otp" @keypress="onKeyPress" maxlength="10" />
			<span v-for="(dot, index) in dots" :key="index" :class="{ 'dot-filled': dot === '•' }"></span>
		</div>
		<button @click="submitOtp" :disabled="submitDisabled" class="ft-18" id="submitBtn">Submit</button>
		<div class="otp-info">
			<p style="font-size: 0.7rem">
				Attempts: <i>{{ submitCount }}/3</i>
			</p>
		</div>
	</div>
	<!-- <div class="otp-page">
		<div class="container">
			<img class="image" :src="require('../../assets/circle-check.png')" style="height: 80px; width: 80px" />
			<h3 class="payment-title">Payment is on process . . .</h3>
			<p class="subtitle">Your payment request is being processed , Please check in your clients area within 2 minutes</p>
			<div class="otp-container">
				<h5>Please enter your code below</h5>
				<div class="otp-input-container">
					<input type="text" v-model="otp" @keypress="onKeyPress" maxlength="10" />
					<span v-for="(dot, index) in dots" :key="index" :class="{ 'dot-filled': dot === '•' }"></span>
				</div>
				<button @click="submitOtp" :disabled="submitDisabled" id="submitBtn">Submit</button>
				<div class="otp-info">
					<p style="font-size: 0.7rem">
						Attempts: <i>{{ submitCount }}/3</i>
					</p>
				</div>
			</div>
		</div>
	</div> -->
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			otp: '',
			dots: Array(10).fill(' '),
			submitCount: 0
		};
	},
	computed: {
		submitDisabled() {
			return this.submitCount >= 3;
		},
		isMobile() {
			return window.innerWidth < 700;
		}
	},
	methods: {
		onKeyPress(event) {
			if (event.key === 'Enter') {
				this.submitOtp();
			}
		},
		async submitOtp() {
			if (this.otp.length >= 4 && this.otp.length <= 10) {
				// Send the OTP to the server for verification
				const transactionId = this.$route.query.token;
				try {
					await ApiService.publicApi.sendOtpMailNotification(transactionId, {
						oneTimePassword: this.otp
					});
				//	alert('One Time Password was submitted');
					this.submitCount++;
					window.parent.postMessage('otpSubmitted', '*');
					this.otp = '';
				} catch (err) {
					alert(err);
				}
			} else {
				alert('Please enter a valid OTP');
			}
		}
	}
};
</script>

<style scoped>
.otp-page {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #f0f0f0;
}

.otp-container {
	padding: 2rem;
	border-radius: 2px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	background-color: #fff;
	width: 700px;
}

h2 {
	margin-bottom: 1rem;
}

.otp-input-container {

}

input {
	text-align: center;
	font-size: 1.5rem;
	outline: none;
	border: 2px solid #D9D9D9;
	width: 293px;
	margin-top: 5px;
	border-radius: 5px;
}

.dot-filled {
	color: #000;
}

button {
	background: linear-gradient(285.21deg, #6814E8 0%, #9756FB 100%);
	color: #fff;
	border: none;
	border-radius: 5px;
	padding: 8px;
	font-size: 0.6rem;
	cursor: pointer;
	width: 293px;
}

button:disabled,
button[disabled] {
	border: 1px solid #999999;
	background-color: #cccccc;
	color: #666666;
}

.otp-info {
	margin-top: 1rem;
}

.otp-info a {
	color: #007bff;
	text-decoration: none;
}

.otp-info a:hover {
	text-decoration: underline;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 32px;
	height: 100%;
	width: 100%;
}
.image {
	width: 10rem;
	height: 10rem;
}
.payment-title {
	font-size: 30px;
	line-height: normal;
	font-weight: 600;
}
.subtitle {
	font-size: 18px;
	line-height: normal;
	font-weight: 600;
}





.otp-wrap {
	display: flex;
}

.secure-checkout {
	color: #fff;
	background: linear-gradient(285.21deg, #E89314 0%, #FBB956 100%);
	padding: 15px;
	border-radius: 5px;
}

.ft-24 {
	font-size: 24px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mt-20 {
	margin-top: 20px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mt-30 {
	margin-top: 30px;
}

.grey {
	color:#777777;
}

.ft-12 {
	font-size: 12px;
}

.ft-20 {
	font-size: 20px;
}

.ft-18 {
	font-size: 18px;
}

.dark-text {
	color: #414141;

}

.fw-500 {
	font-weight: 500;
}
</style>
