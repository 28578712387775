import { render, staticRenderFns } from "./PaymentProcessedNew.vue?vue&type=template&id=342c3a0e&scoped=true"
var script = {}
import style0 from "./PaymentProcessedNew.vue?vue&type=style&index=0&id=342c3a0e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "342c3a0e",
  null
  
)

export default component.exports