<template>
	<v-container>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<!-- Credit Card Hosted Payment Page Block -->
		<v-row
			v-if="['card', 'cardKZT', 'cardRUB', 'cardFSKZT', 'cardSBP', 'cardp2pRUB'].includes(paymentMethod) && !showFrame"
			style="col-lg-12 col-md-12 col-sm-12 text-xs-center"
		>
			<v-card class="mx-auto">
				<v-col>
					<input-field required clearable :props="{ items: currenciesList, styleType: 'outlined' }" v-model="selectedCurrency" label="Deposit Currency" type="select" />
				</v-col>
				<v-card-actions>
					<v-btn text color="deep-purple accent-4" @click="onSubmit" qa-e2e="continueButton"> Continue </v-btn>
				</v-card-actions>
			</v-card>
		</v-row>
		<!-- Pix Hosted Payment Page Block -->
		<v-row v-else-if="paymentMethod === 'pix' && !showFrame" style="col-lg-12 col-md-12 col-sm-12 text-xs-center">
			<v-card class="mx-auto">
				<v-col>
					<input-field required v-model="documentId" type="special-text" label="Document Id" />
				</v-col>
				<v-col>
					<input-field required v-model="bankAccount" type="special-text" label="Bank Account" />
				</v-col>
				<v-col>
					<input-field required v-model="bankBranch" type="special-text" label="Bank Branch" />
				</v-col>
				<v-col>
					<input-field required v-model="bankCode" type="special-text" label="Bank Code" />
				</v-col>
				<v-col>
					<input-field required clearable :props="{ items: pixCurrencies, styleType: 'outlined' }" v-model="selectedCurrency" label="Deposit Currency" type="select" />
				</v-col>
				<v-card-actions>
					<v-btn text color="deep-purple accent-4" @click="onSubmit" qa-e2e="continueButton"> Continue </v-btn>
				</v-card-actions>
			</v-card>
		</v-row>

		<!-- Response Block -->
		<v-row v-else style="col-lg-12 col-md-12 col-sm-12 text-xs-center">
			<v-card class="mx-auto" v-if="paymentMethod === 'pix'">
				<v-card class="mx-auto" style="width: 370px" v-if="showQrText">
					<v-snackbar v-model="snackbar">
						Copied
						<template #action="{ attrs }">
							<v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
						</template>
					</v-snackbar>
					<iframe v-if="response" :src="response"></iframe>
					<p>QR INFORMATION:</p>
					<textarea :value="qrInformation" style="width: 300px; height: 200px" ref="text"></textarea>
					<v-btn text color="deep-purple accent-4" @click="copyTextArea"> COPY TEXT </v-btn>
				</v-card>
			</v-card>
			<v-card class="mx-auto" v-else>
				<v-col>
					<p>Please wait while payment page is being loaded on new tab</p>
				</v-col>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import _ from 'lodash';
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			response: '',
			selectedCurrency: '',
			selectedMethod: '',
			qrInformation: null,
			documentId: null,
			bankAccount: null,
			bankBranch: null,
			bankCode: null,
			loading: false,
			showFrame: false,
			showQrText: false,
			allCurrencies: ['USD', 'EUR'],
			snackbar: false,
			sanctionedSource: [
				'BTGadmin',
				'KXadmin',
				'FIATadmin',
				'VHadmin',
				'advancedcourses.net',
				'worldofcapital.net',
				'courseshall.net',
				'courseskingdom.net',
				'advanced-academy.net',
				'courses-zone.com',
				'incomearena.net'
			]
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true },
		country: { type: String, required: true },
		paymentMethod: { type: String, required: true },
		subSource: { type: String, required: true }
	},
	computed: {
		currenciesList() {
			return _.map(this.allCurrencies, (currency) => ({ text: currency, value: currency }));
		},
		pixCurrencies() {
			const currencies = ['BRL'];
			return _.map(currencies, (currency) => ({ text: currency, value: currency }));
		}
	},
	methods: {
		async onSubmit() {
			try {
				this.loading = true;
				const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
					selectedCurrency: this.selectedCurrency,
					documentId: this.documentId,
					bankAccount: this.bankAccount,
					bankBranch: this.bankBranch,
					bankCode: this.bankCode,
					selectedMethod: this.paymentMethod
				});

				if (['card', 'cardKZT', 'cardRUB', 'cardFSKZT', 'cardSBP', 'cardp2pRUB'].includes(this.paymentMethod)) {
					this.response = response;
					window.open(this.response);
				}

				if (this.paymentMethod === 'pix') {
					this.response = _.get(response, 'qr');
					this.showQrText = true;
					this.qrInformation = _.get(response, 'info');
				}
				this.loading = false;
				this.showFrame = true;
			} catch (err) {
				this.loading = false;
				this.showFrame = false;
			}
		},
		async copyTextArea() {
			await navigator.clipboard.writeText(this.$refs.text.value);
			this.snackbar = true;
		}
	},
	created() {
		if (_.includes(this.sanctionedSource, this.subSource)) {
			this.allCurrencies = ['USD', 'EUR', 'INR'];
		}

		if (['cardKZT', 'cardFSKZT'].includes(this.paymentMethod)) {
			this.allCurrencies = ['KZT'];
		}

		if (['cardp2pRUB', 'cardRUB', 'cardSBP'].includes(this.paymentMethod)) {
			this.allCurrencies = ['RUB'];
		}
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 400px;
}
</style>
