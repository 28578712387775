<template>
	<v-container class="pa-0">
		<!-- <v-row
			:style="`--primaryColor:${pageTemplate.primaryColor};--secondaryColor:${pageTemplate.secondaryColor};--tertiaryColor:${pageTemplate.tertiaryColor};`"
			:class="$style.titleWrapper"
		>
			<v-col class="white--text text-h4" cols="4" sm="4" md="4">
				<div :class="$style['title-container']">
					<img v-if="pageTemplate.logo" :src="pageTemplate.logo" style="object-fit:contain; width: 100px; height:60px" alt="" />
					<span>{{ pageTemplate.title || this.$t('home.selectMethodTitle') }}</span>
				</div>
			</v-col>
		</v-row> -->
		<v-row :class="$style['text-wrapper']" class="ma-0">
			<v-col sm="4" md="4" :class="$style['left-col']" class="d-flex">
				<div class="d-flex w-100 py-10 justify-center align-center" style="width: 100%; line-height: 1">
					<v-avatar size="50" v-if="pageTemplate.logo">
						<img :src="pageTemplate.logo" alt="John" />
					</v-avatar>
					<!-- <img v-if="pageTemplate.logo" :src="pageTemplate.logo" style="object-fit:contain; width: 60px; height:60px" alt="" /> -->
					<div class="d-flex flex-column align-start ml-2">
						<span :class="$style['ft-18']" class="white--text text-uppercase">Hello</span>
						<span :class="$style['ft-32']" class="text-uppercase text-bold">{{ transactionTotalAmount.leadName }}</span>
					</div>
				</div>
				<v-divider class="mb-10" style="border-bottom: 1px solid rgba(255, 255, 255, 0.3); width: 40%"></v-divider>
				<div class="d-flex w-100 flex-column text-left" style="width: 100%">
					<h6 style="font-size: 20px !important; font-weight: 400">Pick your preferred method</h6>
					<v-radio-group v-model="activePaymentMethod" @change="selectPaymentMethod">
						<div :class="$style['br-4']" class="white justify-start mb-2 text-capitalize pa-3">
							<v-radio label="Credit Card" value="credit_card"></v-radio>
						</div>
						<div v-if="doesMethodExists('StaticInfo')" :class="$style['br-4']" class="white justify-start mb-2 text-capitalize pa-3">
							<v-radio label="Wire Transfer" value="wire"></v-radio>
						</div>
						<div v-if="doesMethodExists('FireblocksBridge')" :class="$style['br-4']" class="white justify-start mb-2 text-capitalize pa-3">
							<v-radio label="Fireblocks Bridge" value="fireblocks_bridge"></v-radio>
						</div>
					</v-radio-group>
				</div>
				<v-divider class="mt-10" style="border-bottom: 1px solid rgba(255, 255, 255, 0.3); width: 40%"></v-divider>
				<div>
					<div style="font-size: 24px">Amount</div>
					<p v-if="Object.keys(transactionTotalAmount).length" style="font-weight: 600; font-size: 20px">
						{{ transactionTotalAmount.amount.toFixed(2) }} {{ transactionTotalAmount.currency }}
					</p>
				</div>
				<div class="pa-2" style="overflow-y: auto">
					<p style="font-size: 12px; line-height: 14.06px" class="white--text text-left">
						{{ $t('home.customerServiceDesc') }}
					</p>
					<!--<p style="font-size: small;" class="white--text">{{ $t('home.copyright') }}</p> -->
				</div>
			</v-col>
			<v-col sm="8" md="8" :class="$style['right-col']" class="d-flex justify-center">
				<div style="width: 77%" class="card-form-wrp">
					<div v-if="activePaymentMethod === 'default'" :class="$style['pick-method']" class="d-flex align">
						<div class="d-flex justify-center align-center">
							<h1 class="black--text font-weight-bold max-w-4" cols="9" sm="10" md="11">Please pick your payment method to continue</h1>
						</div>
					</div>
					<div v-if="hideDisplay && !showPaymentCard">
						<div v-if="activePaymentMethod === 'credit_card'">
							<v-row :class="$style['text-title']" class="my-5">
								<v-col cols="12" sm="12" md="12">
									<h1 class="text-uppercase" style="font-size: 20px">{{ cryptoSite }}</h1>
								</v-col>
							</v-row>
							<v-row no-gutters class="d-flex flex-row" style="overflow-y: scroll; max-height: 450px">
								<v-col
									v-for="paymentMethod in paymentMethods.filter((item) => item.psp !== 'StaticInfo' && item.psp !== 'FireblocksBridge')"
									:key="paymentMethod.id"
									cols="12"
									sm="12"
									md="4"
								>
									<v-card
										:loading="choosingPaymentMethod === paymentMethod.id"
										:disabled="!!choosingPaymentMethod"
										:shaped="pageTemplate.shaped"
										:outlined="pageTemplate.outlined"
										:style="pageTemplate.style"
										@click="choosePaymentMethod(paymentMethod.id)"
										:qa-e2e="paymentMethod.name"
										style="border-top-right-radius: 15px; border-top-left-radius: 15px"
										class="mx-1 my-2"
									>
										<v-img :src="paymentMethod.image" height="100px" contain />
										<div :class="$style['method-text']" style="width: 100%; font-size: 14px; font-weight: 400; color: white" class="text-capitalize pa-1">
											{{ paymentMethod.name }}
										</div>
									</v-card>
								</v-col>
							</v-row>
						</div>
						<div v-if="activePaymentMethod === 'wire'">
							<v-row :class="$style['text-title']" class="my-5">
								<v-col cols="12" sm="12" md="12">
									<h1 class="text-uppercase" style="font-size: 20px">{{ cryptoSite }}</h1>
								</v-col>
							</v-row>
							<v-row no-gutters class="d-flex flex-row" style="overflow-y: scroll; max-height: 450px">
								<v-col v-for="paymentMethod in paymentMethods.filter((item) => item.psp === 'StaticInfo')" :key="paymentMethod.id" cols="12" sm="12" md="4">
									<v-card
										:loading="choosingPaymentMethod === paymentMethod.id"
										:disabled="!!choosingPaymentMethod"
										:shaped="pageTemplate.shaped"
										:outlined="pageTemplate.outlined"
										:style="pageTemplate.style"
										@click="choosePaymentMethod(paymentMethod.id)"
										:qa-e2e="paymentMethod.name"
										style="border-top-right-radius: 15px; border-top-left-radius: 15px"
										class="mx-1 my-2"
									>
										<v-img :src="paymentMethod.image" height="100px" contain />
										<div :class="$style['method-text']" style="width: 100%; font-size: 14px; font-weight: 400; color: white" class="text-capitalize pa-1">
											{{ paymentMethod.name }}
										</div>
									</v-card>
								</v-col>
							</v-row>
						</div>
						<div v-if="activePaymentMethod === 'fireblocks_bridge'">
							<v-row :class="$style['text-title']" class="my-5">
								<v-col cols="12" sm="12" md="12">
									<h1 class="text-uppercase" style="font-size: 20px">{{ cryptoSite }}</h1>
								</v-col>
							</v-row>
							<v-row no-gutters class="d-flex flex-row" style="overflow-y: scroll; max-height: 450px">
								<v-col v-for="paymentMethod in paymentMethods.filter((item) => item.psp === 'FireblocksBridge')" :key="paymentMethod.id" cols="12" sm="12" md="4">
									<v-card
										:loading="choosingPaymentMethod === paymentMethod.id"
										:disabled="!!choosingPaymentMethod"
										:shaped="pageTemplate.shaped"
										:outlined="pageTemplate.outlined"
										:style="pageTemplate.style"
										@click="choosePaymentMethod(paymentMethod.id)"
										:qa-e2e="paymentMethod.name"
										style="border-top-right-radius: 15px; border-top-left-radius: 15px"
										class="mx-1 my-2"
									>
										<v-img :src="paymentMethod.image" height="100px" contain />
										<div :class="$style['method-text']" style="width: 100%; font-size: 14px; font-weight: 400; color: white" class="text-capitalize pa-1">
											{{ paymentMethod.name }}
										</div>
									</v-card>
								</v-col>
							</v-row>
						</div>
					</div>
					<div v-if="showPaymentCard">
						<payments-page
							v-if="paymentPageDetails"
							:payment-page-details="paymentPageDetails"
							@submit="uploadFile"
							@file-selected="onFileSelected"
							@cancel-payment-method="onPaymentMethodCanceled"
						/>
					</div>
					<!--<div class="align-center mb-10 d-flex text--black" style="position: absolute; bottom: 0;width: 60%;right: 110px;">
						<h6 style="width: 30%;text-align: right;">Powered by:</h6>
						<v-img style="width:50%;" :height="30" contain :aspect-ratio="16 / 9" :src="require('../assets/cards-logo.png')"></v-img>
					</div> -->
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapMutations } from 'vuex';
import ApiService from '../services/ApiService.js';
import VueService from '../services/VueService.js';

export default {
	components: {
		PaymentsPage: () => import('./PaymentsPageTemplate2.vue')
	},
	props: {
		transactionId: { type: String, required: true },
		subSource: { type: String, required: false },
		paymentMethods: { type: Array, required: true },
		transactionTotalAmount: { type: Object, required: false },
		isLatinOnlyInput: { type: Boolean, required: true }
	},
	data() {
		return {
			choosingPaymentMethod: false,
			paymentPageDetails: null,
			pageTemplate: {},
			hideDisplay: true,
			showPaymentCard: false,
			activePaymentMethod: 'default',
			cryptoSite: null
		};
	},
	async mounted() {
		await this.setPageTemplate();
		this.cryptoSite = this.$route.query.subSource;
		this.setIsNewPage(true);
	},
	methods: {
		...mapMutations({
			setIsNewPage: 'setIsNewPage'
		}),
		doesMethodExists(method) {
			return this.paymentMethods.filter((item) => item.psp === method)[0];
		},
		async setPageTemplate() {
			this.pageTemplate = await ApiService.publicApi.getDepositTemplate(this.subSource);
		},
		async choosePaymentMethod(paymentMethodId) {
			try {
				this.choosingPaymentMethod = true;
				this.showPaymentCard = true;
				this.paymentPageDetails = await ApiService.publicApi.selectPaymentMethod(this.transactionId, paymentMethodId);
				if (this.subSource) {
					this.paymentPageDetails.subSource = this.subSource;
				}
				this.paymentPageDetails.isLatinOnlyInput = this.isLatinOnlyInput;
			} catch (error) {
				if (process.env.NODE_ENV === 'development') {
					console.log(error);
				}
			}
			this.choosingPaymentMethod = false;
		},
		onPaymentMethodCanceled() {
			this.paymentPageDetails = null;
			this.choosingPaymentMethod = false;
			this.showPaymentCard = false;
		},
		async uploadFile() {
			try {
				await ApiService.publicApi.uploadFile({ transactionId: this.paymentPageDetails.transactionId }, { fileName: this.fileObject.name, base64: this.base64 });
				VueService.showToastMessage({ message: this.$t('staticInfo.fileUpload.success'), type: 'success' });
				this.paymentPageDetails = null;
			} catch (error) {
				console.error(error);
				VueService.showToastMessage({ message: this.$t('staticInfo.fileUpload.error'), type: 'error' });
			}
		},
		async onFileSelected(evt) {
			[this.fileObject] = evt.target.files;
			this.base64 = await this.createBase64(this.fileObject);
		},
		createBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(file);
			});
		},
		async selectPaymentMethod() {
			this.showPaymentCard = false;
			this.hideDisplay = true;
			this.choosingPaymentMethod = false;
			this.paymentPageDetails = null;
		}
	}
};
</script>

<style module scoped>
.title-container {
	display: flex;
	width: 100%;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	align-content: center;
	gap: 10px;
}

.title-container > img {
	flex: 0 0 12%;
}

.container {
	max-width: 200px;
	min-width: 100px;
}

.titleWrapper {
	background-image: linear-gradient(135deg, var(--primaryColor), var(--secondaryColor), var(--tertiaryColor)) !important;
}

.text-wrapper {
	background: #d8d8d8;
	height: 100vh;
	width: 100%;
	overflow: hidden;
	border-radius: 20px;
}

.left-col {
	height: 100%;
	background: linear-gradient(179.94deg, #260466 0%, #260492 100%);
	/* background: green; */
	border-radius: 20px;
	color: #e1e1e1;
	display: flex;
	align-items: center;
	padding: 50px;
	position: relative;
	flex-direction: column;
	justify-content: space-between;
}

.text-title {
	background: linear-gradient(90.55deg, #260466 0%, #260492 100%);
	width: 100%;
	margin: auto;
	color: white;
	text-align: left;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}

.right-col {
	height: 100%;
	position: relative;
	background: linear-gradient(296.83deg, #d8d8d8 0%, rgba(216, 216, 216, 0.6) 100%), linear-gradient(179.71deg, #d8d8d8 0%, rgba(216, 216, 216, 0.6) 100%);
	overflow-y: auto;
	/* padding: 100px; */
}

.pick-method {
	/* position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); */
	height: 100%;
	font-size: 20px;
}

.method-text {
	background: linear-gradient(180deg, #270588 0%, #270590 100%);
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	height: 28px;
}

.ft-18 {
	font-size: 18px;
}

.ft-32 {
	font-size: 32px;
}

.br-4 {
	border-radius: 4px;
}

.img {
	display: flex;
	margin-right: 3px;
	text-align: center;
	align-items: center;
	justify-items: center;
}

/* width */
::-webkit-scrollbar {
	width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
@media (min-width: 601px) and (max-width: 1264px) {
	.left-col {
		padding: 20px;
	}
}

@media (min-width: 960px) and (max-width: 1025px) {
	.text-wrapper {
		height: 100vh;
	}
}

@media (min-width: 1904px) {
	:global(.container) {
		max-width: 1185px !important;
	}
}
</style>
