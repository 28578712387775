<template>
	<v-container>
		<v-container v-if="!response.success">
			<iframe :src="response.data.redirectUrl"></iframe>
		</v-container>
		<v-container v-else>
			<iframe name="resultIframe" id="resultIframe"></iframe>
			<form name="threeDform" ref="threeDform" :action="response.data.ACSUrl" method="POST" target="resultIframe">
				<input type="hidden" name="PaReq" :value="response.data.Payload" />
				<input type="hidden" name="TermUrl" :value="response.data.ValidateUrl" />
				<input type="hidden" name="MD" :value="response.data.requestID" />
			</form>
		</v-container>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			data: null,
			result: null,
			redirect: null
		};
	},
	props: {
		response: { type: Object, required: true }
	},
	methods: {
		async transformSource() {
			if (this.response !== '/paymentProcessed') {
				this.redirect = JSON.parse(this.response);
			} else {
				this.result = this.response;
			}
		}
	},
	async mounted() {
		if (this.response.success) {
			this.$refs.threeDform.submit();
		}
	}
};
</script>
<style scoped>
iframe {
	width: 100%;
	height: 500px;
}
</style>
