<template>
	<v-container>
		<v-row v-if="showText">
			<v-card class="mx-auto">
				<v-col>
					<p v-if="!pop3dPage">Please wait while payment page is being loaded on new tab</p>
					<p v-else>Please wait while 3D Auth page is loaded on new window.</p>
				</v-col>
			</v-card>
		</v-row>
		<v-row v-else style="col-lg-12 col-md-12 col-sm-12 text-xs-center">
			<iframe frameBorder="0" :src="data"></iframe>
		</v-row>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
	data() {
		return {
			data: '',
			showText: false
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		response: { type: String, required: true },
		isHostedPage: { type: Boolean, required: false, default: false },
		hostedPaymentUrl: { type: String, required: false, default: '' },
		pop3dPage: { type: Boolean, required: false, default: false }
	},
	computed: {
		...mapState({
			isNewPage: 'isNewPage'
		})
	},
	methods: {
		transformSource() {
			const isProcessedPayment = this.response === '/paymentProcessed';
			if (isProcessedPayment) {
				const isNewProcessedPage = this.isNewPage && isProcessedPayment;
				this.data = isNewProcessedPage ? '/paymentProcessedNew' : this.response;
				return;
			}
			if (this.isHostedPage) {
				this.showText = true;
				window.open(this.hostedPaymentUrl);
				return;
			}
			if (this.pop3dPage) {
				this.showText = true;
				this.openPopUp(this.response);
				return;
			}
			if (!this.isHostedPage && this.response.includes('<!DOCTYPE html>')) {
				this.data = `data:text/html;charset=utf-8,${this.response}`;
				return;
			}

			const isNewRejectedPage = this.isNewPage && this.response === '/paymentRejected';
			this.data = isNewRejectedPage ? '/paymentRejectedNew' : this.response;
		},
		openPopUp(urlToOpen) {
			const popup_window = window.open(
				urlToOpen,
				'myWindow',
				'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, fullscreen=yes'
			);
			try {
				popup_window.focus();
			} catch (error) {
				alert('Pop-up Blocker is enabled! Please add this site to your exception list.');
			}
		}
	},
	created() {
		this.transformSource();
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 500px;
}
</style>
