<template>
	<section>
		<the-stripe
			@payment-processed="onPaymentProcessed"
			@payment-rejected="onPaymentRejected"
			@subscription-initialized="onSubscriptionInitialized"
			:transaction-id="transactionId"
			:transaction="transaction"
			:country-code="countryCode"
			:is-subscription="isSubscriptionSelected"
			:public-key="publicKey"
			:recurring="{ interval: recurringInterval }"
			:is-subscribed="isSubscribed"
			:client-secret-subscription="clientSecretSubscription"
		></the-stripe>
		<v-row v-if="isSubscriptionSelected && paymentPlan && !isSubscribed">
			<subscription-plan-item :plan-details="paymentPlan" @click="selectSubScription" />
		</v-row>
	</section>
</template>

<script>
import TheStripe from './TheStripe.vue';
import SubscriptionPlanItem from '../Subscription/SubscriptionPlanItem.vue';
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			paymentMode: 'subscription',
			recurringInterval: 'month',
			isSubscription: true,
			isSelectedSubscrition: false,
			validForm: false,
			paymentPlan: null,
			isSubscribed: false,
			clientSecretSubscription: ''
		};
	},
	props: {
		transactionId: { type: String },
		transaction: { type: Object, required: true },
		countryCode: { type: String, required: true },
		publicKey: { type: String, required: true }
	},
	computed: {
		isSubscriptionSelected() {
			return this.paymentMode === 'subscription';
		}
	},
	methods: {
		onPaymentProcessed(paymentIntent) {
			this.$emit('payment-processed', paymentIntent);
		},
		onPaymentRejected(error) {
			this.$emit('payment-error', error);
		},
		onSubscriptionInitialized(e) {
			this.initialSubscription = e;
			this.paymentPlan = e.price;
		},
		async selectSubScription() {
			const subscription = await ApiService.publicApi.createSubscription({
				customerId: this.initialSubscription.customerId,
				items: [{ price: this.initialSubscription.price.id }],
				productId: this.initialSubscription.productId,
				interval: this.recurringInterval,
				transactionId: this.transaction.id
			});
			this.isSubscribed = true;
			this.clientSecretSubscription = subscription.latest_invoice.payment_intent.client_secret;
			console.log(subscription, 'subscriptionnnnnnnnnnnnnnnnnnn');
		}
	},
	components: { TheStripe, SubscriptionPlanItem }
};
</script>

<style></style>
