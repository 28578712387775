<template>
	<v-container class="container">
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-row>
			<v-col cols="12">
				<v-stepper v-model="step">
					<v-stepper-header style="margin-top: 3px; margin-bottom: 3px">
						<v-stepper-step :complete="step > 1" step="1" color="orange">Select Crypto Currency</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step :complete="step > 2" step="2" color="orange">Wallet Information</v-stepper-step>
					</v-stepper-header>
					<v-stepper-items>
						<v-stepper-content step="1">
							<v-row>
								<v-col>
									<input-field
										required
										clearable
										:props="{ items: availableCryptos, styleType: 'outlined' }"
										v-model="selectedCurrency"
										:label="$t('fireblocksBridge.crypto')"
										type="select"
									/>
								</v-col>
							</v-row>
							<br />
							<v-btn color="primary" @click="nextStep" :disabled="!selectedCurrency">Continue</v-btn>
						</v-stepper-content>
						<v-stepper-content step="2">
							<v-snackbar v-model="snackbar">
								Copied
								<template #action="{ attrs }">
									<v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
								</template>
							</v-snackbar>
							<v-tabs vertical-align="top" left v-if="selectedCurrency === 'TRX'">
								<v-tab @click="signMessageDialog = true">
									<small>Sign a Message</small>
								</v-tab>
							</v-tabs>
							<v-divider v-if="selectedCurrency === 'TRX'" />
							<v-tabs vertical-align="top" right>
								<v-tab v-for="(item, index) in addressInfo" :key="index">
									<span v-if="selectedCurrency === 'BTC'">{{ item.addressFormat }}</span>
									<span v-else>{{ item.asset }}</span>
								</v-tab>
								<v-tab-item v-for="(item, index) in addressInfo" :key="index">
									<v-container fluid style="margin: 5px">
										<v-row>
											<v-col cols="12">
												<div class="text-h3 black--text">
													{{ selectedCurrency }}
													<span v-if="selectedCurrency === 'BTC'">({{ item.addressFormat }})</span>
												</div>
												<img :src="item.QrCode" class="my-9" />
											</v-col>
											<v-col cols="12">
												<p class="body-2 black--text mb-5">{{ selectedCurrency }} Wallet Address:</p>
												<v-row class="d-flex justify-center align-center">
													<v-col cols="12">
														<v-text-field
															outlined
															dense
															readonly
															:value="item.address"
															:ref="index"
															append-icon="mdi-content-copy"
															@click:append="copyHandler(index)"
														></v-text-field>
													</v-col>
												</v-row>
												<v-row v-if="selectedCurrency === 'TRX'">
													<v-col cols="12" style="text-align: center" v-if="!isSigned">
														<v-dialog :value="signMessageDialog" persistent max-width="600">
															<v-card>
																<v-card-title class="justify-center mb-5">
																	<span>Sign TRX Wallet Address</span>
																</v-card-title>
																<v-card-text>
																	<v-divider class="my-5" />
																	<v-container>
																		<v-row>
																			<v-col cols="12" v-if="!isSigned">
																				<v-textarea label="Message" v-model="signedMessage" outlined></v-textarea>
																			</v-col>

																			<v-col cols="12">
																				<v-btn color="red" @click="signMessageDialog = false" text>Cancel</v-btn>
																				<v-btn color="primary" :disabled="!signedMessage" @click="createSignMessage" text> Confirm</v-btn>
																			</v-col>
																		</v-row>
																	</v-container>
																</v-card-text>
																<v-card-actions> </v-card-actions>
															</v-card>
														</v-dialog>
													</v-col>
													<v-col cols="12" v-if="isSigned">
														<v-textarea label="Signature" v-model="messageSignature" readonly outlined></v-textarea>
													</v-col>
												</v-row>
												<v-row>
													<v-row v-if="item.note">
														<v-col style="font-size: 18px; color: red" cols="12">{{ $t('fireblocksBridge.notes') }}: {{ item.note }}</v-col>
														<v-col style="font-size: 18px; color: red" cols="12">
															<span class="font-italic">
																Please double-check the network to ensure that you're sending to the correct one. You may lose your transaction if
																any detail is incorrect.
															</span>
														</v-col>
														<v-col cols="12">
															<span class="body-2 grey--text d-block font-weight-bold font-italic">To copy the wallet address quickly,</span>
															<span class="body-2 grey--text d-block font-weight-bold font-italic">Scan QR code and proceed with the payment.</span>
														</v-col>
													</v-row>
													<v-row v-else>
														<v-col cols="12">
															<span class="body-2 grey--text d-block font-weight-bold font-italic">To copy the wallet address quickly,</span>
															<span class="body-2 grey--text d-block font-weight-bold font-italic">Scan QR code and proceed with the payment.</span>
														</v-col>
													</v-row>
												</v-row>
											</v-col>
										</v-row>
									</v-container>
								</v-tab-item>
							</v-tabs>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';
import VueService from '../../services/VueService.js';

export default {
	data() {
		return {
			step: 1,
			selectedCurrency: '',
			addressInfo: null,
			loading: false,
			snackbar: false,
			signedMessage: null,
			isSigned: false,
			messageSignature: null,
			signMessageDialog: false
		};
	},
	props: {
		transactionId: { type: String, required: true },
		availableCryptos: { type: Array, required: true }
	},
	methods: {
		async nextStep() {
			await this.onSubmit();
			this.step++;
		},
		copyHandler(refName) {
			const element = this.$refs[refName][0].$el.querySelector('input');
			element.select();
			document.execCommand('copy');
			this.snackbar = true;
		},
		async onSubmit() {
			try {
				this.loading = true;
				const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
					selectedCurrency: this.selectedCurrency
				});
				this.addressInfo = response;
				this.loading = false;
			} catch (err) {
				this.loading = false;
			}
		},
		async createSignMessage() {
			try {
				this.loading = true;
				this.signMessageDialog = false;
				const response = await ApiService.publicApi.createSignMessageRequest(this.transactionId, {
					message: this.signedMessage,
					address: this.addressInfo[0].address
				});
				this.loading = false;
				if (response.success) {
					this.isSigned = true;
					this.messageSignature = response.data.signature;
					VueService.showToastMessage({ message: 'message signature created', type: 'success' });
				}
			} catch (err) {
				console.log(err);
				this.loading = false;
			}
		}
	}
};
</script>
<style module>
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap');
input {
	text-align: center;
}
.container {
	max-width: 770px;
	margin: auto;
	width: 100%;
	max-height: 70vh;
	overflow-y: auto;
}
</style>
