<template>
	<v-container :class="$style.container">
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-form v-if="!response">
			<v-row>
				<v-col>
					<input-field
						required
						clearable
						:props="{ items: availablePaymentMethods, styleType: 'outlined' }"
						v-model="selectedPaymentMethod"
						type="select"
						label="Select Payment Method"
					/>
				</v-col>
			</v-row>
			<v-row><br /><br /></v-row>

			<v-row v-if="selectedPaymentMethod === 'Multiple Payment'">
				<v-col>
					<v-row>
						<v-col>
							<input-field required clearable :props="{ items: availableChannel, styleType: 'outlined' }" v-model="selectedChannel" type="select" label="Channel" />
						</v-col>
					</v-row>
					<v-row v-if="availableBanks.length > 0">
						<v-col>
							<input-field required clearable :props="{ items: availableBanks, styleType: 'outlined' }" v-model="selectedBank" type="select" label="Bank" />
						</v-col>
					</v-row>
					<v-row>
						<v-col v-if="country === 'Brazil' || country === 'Chile'">
							<p style="color: red"><i>Note: if from Brazil please select (CPF or CPNJ), if from Chile please select RUT.</i></p>
						</v-col>
					</v-row>
					<v-row>
						<v-col v-if="country === 'Brazil' || country === 'Chile'">
							<input-field
								required
								clearable
								:props="{ items: documentTypes, styleType: 'outlined' }"
								v-model="selectedDocuType"
								type="select"
								label="Document Type"
							/>
						</v-col>
					</v-row>
					<v-row><br /><br /></v-row>
					<v-row>
						<v-col v-if="country === 'Brazil' || country === 'Chile'">
							<input-field required v-model="documentNumber" type="input" label="Document Number" />
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row v-if="selectedPaymentMethod === 'Card Payment'">
				<v-row>
					<v-col>
						<input-field required v-model="emailInput" type="special-text" label="Email" />
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<input-field required v-model="adrressInput" type="special-text" label="Address" />
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<input-field required v-model="streetNumInput" type="special-text" label="Street Number" />
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<input-field required v-model="cityInput" type="special-text" label="City" />
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<input-field required v-model="zipCodeInput" type="special-text" label="Zip Code" />
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<input-field required v-model="stateInput" type="special-text" label="State" />
					</v-col>
				</v-row>
			</v-row>

			<v-row justify="space-around" class="mt-5">
				<v-btn text color="primary" @click="onSubmit" :disabled="loading">
					Continue
					<v-icon right>mdi-check</v-icon>
				</v-btn>
			</v-row>
		</v-form>
		<template v-if="response">
			<v-row>
				<embed :src="response" width="900" height="700" />
			</v-row>
		</template>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			response: '',
			selectedPaymentMethod: '',
			selectedDocuType: '',
			selectedChannel: '',
			selectedBank: '',
			documentNumber: '',
			documentTypes: ['CPF', 'CNPJ', 'RUT'],
			availableChannel: [
				{ value: '1', text: 'Online' },
				{ value: '2', text: 'Cash' }
			],
			availableBanks: [],
			loading: false,
			adrressInput: '',
			streetNumInput: '',
			cityInput: '',
			zipCodeInput: '',
			emailInput: '',
			stateInput: ''
		};
	},
	props: {
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true },
		country: { type: String, required: true },
		availablePaymentMethod: { type: Array, required: true }
	},
	computed: {
		availablePaymentMethods() {
			return _.map(this.availablePaymentMethod, (paymentMethod) => ({ text: paymentMethod, value: paymentMethod }));
		}
	},
	methods: {
		async onSubmit() {
			try {
				this.loading = true;
				const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
					selectedPaymentMethod: this.selectedPaymentMethod,
					selectedChannel: this.selectedChannel,
					selectedBank: this.selectedBank,
					selectedDocuType: this.selectedDocuType,
					documentNumber: this.documentNumber,
					adrressInput: this.adrressInput,
					streetNumInput: this.streetNumInput,
					cityInput: this.cityInput,
					zipCodeInput: this.zipCodeInput,
					emailInput: this.emailInput,
					stateInput: this.stateInput
				});
				this.response = response;
				this.loading = false;
			} catch (err) {
				this.loading = false;
			}
		},
		async fetchBankInfo(country, channel) {
			this.loading = true;
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				country,
				selectedChannel: channel,
				getBankData: true
			});
			this.loading = false;
			this.availableBanks = response;
		}
	},
	watch: {
		selectedChannel(val) {
			this.availableBanks = [];
			if (val) {
				this.fetchBankInfo(this.country, val);
			}
		}
	}
};
</script>

<style module>
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap');

.container {
	max-width: 770px;
	margin: auto;
	width: 100%;
}

.cardInfo {
	height: 270px;
}

.ma {
	max-width: 60%;
}
</style>
