<template>
	<div :class="$style['mobile-wrapp']">
		<div>
			<div :class="$style['mobile-header']">
				<div :class="$style['mobile-hello']">Hello</div>
				<div :class="$style['mobile-name']">{{ transactionTotalAmount.leadName }}</div>
			</div>
			<div :class="$style['amount-section']">
				<div :class="$style['amount-text']">Amount</div>
				<div :class="$style['amount']" v-if="Object.keys(transactionTotalAmount).length">
					{{ transactionTotalAmount.currency }} {{ transactionTotalAmount.amount.toFixed(2) }}
				</div>
			</div>
			<div :class="$style['mobile-body']">
				<div v-if="activePaymentMethod === 'default'">
					<div :class="$style['payment-method-pick-text']">Pick your preferred method of payment</div>
					<div :class="$style['border']"></div>
					<div>
						<div :class="[$style['method'], active === 'credit_card' ? $style['active'] : '']" @click="setActivePaymentMethod('credit_card')">Credit Card</div>
						<div v-if="doesMethodExist('StaticInfo')" :class="[$style['method'], active === 'wire' ? $style['active'] : '']" @click="setActivePaymentMethod('wire')">
							Wire
						</div>
						<div
							v-if="doesMethodExist('FireblocksBridge')"
							:class="[$style['method'], active === 'fireblocks_bridge' ? $style['active'] : '']"
							@click="setActivePaymentMethod('fireblocks_bridge')"
						>
							Crypto
						</div>
					</div>
				</div>
				<div v-else>
					<div v-if="hideDisplay && !showPaymentCard">
						<div v-if="activePaymentMethod === 'credit_card'">
							<div :class="$style['payment-method-pick-text']">Pick your preferred card</div>
							<div :class="$style['border']"></div>
							<div
								:class="[$style['card-image-wrap'], activeCard === paymentMethod.id ? $style['active'] : '']"
								@click="setActiveCard(paymentMethod.id)"
								v-for="paymentMethod in paymentMethods.filter((item) => item.psp !== 'StaticInfo' && item.psp !== 'FireblocksBridge')"
								:key="paymentMethod.id"
							>
								<div :class="$style['card-image']">
									<v-img :src="paymentMethod.image" width="100%" height="100%" contain />
								</div>
								<div :class="$style['method-mobile-text']">
									{{ paymentMethod.name }}
								</div>
							</div>
						</div>
						<div v-if="activePaymentMethod === 'wire'">
							<div
								:class="[$style['card-image-wrap'], activeCard === paymentMethod.id ? $style['active'] : '']"
								@click="setActiveCard(paymentMethod.id)"
								v-for="paymentMethod in paymentMethods.filter((item) => item.psp === 'StaticInfo')"
								:key="paymentMethod.id"
							>
								<div :class="$style['card-image']">
									<v-img :src="paymentMethod.image" width="100%" height="100%" contain />
								</div>
								<div :class="$style['method-mobile-text']">
									{{ paymentMethod.name }}
								</div>
							</div>
						</div>
						<div v-if="activePaymentMethod === 'fireblocks_bridge'">
							<div
								:class="[$style['card-image-wrap'], activeCard === paymentMethod.id ? $style['active'] : '']"
								@click="setActiveCard(paymentMethod.id)"
								v-for="paymentMethod in paymentMethods.filter((item) => item.psp === 'FireblocksBridge')"
								:key="paymentMethod.id"
							>
								<div :class="$style['card-image']">
									<v-img :src="paymentMethod.image" width="100%" height="100%" contain />
								</div>
								<div :class="$style['method-mobile-text']">
									{{ paymentMethod.name }}
								</div>
							</div>
						</div>
					</div>
					<div v-if="showPaymentCard">
						<payments-page-mobile
							v-if="paymentPageDetails"
							:payment-page-details="paymentPageDetails"
							@submit="uploadFile"
							@file-selected="onFileSelected"
							@cancel-payment-method="onPaymentMethodCanceled"
						/>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!showPaymentCard" :class="$style['mobile-footer']">
			<div>
				<v-btn @click="select" :class="$style['next-btn']" :large="true">NEXT</v-btn>
			</div>
			<div v-if="activePaymentMethod !== 'default'">
				<v-btn @click="onBack" outlined :class="$style['back-btn']" :large="true">Back</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';
import ApiService from '../services/ApiService.js';
import VueService from '../services/VueService.js';

export default {
	components: {
		PaymentsPageMobile: () => import('./PaymentsPageMobile.vue')
	},
	props: {
		transactionId: { type: String, required: true },
		subSource: { type: String, required: false },
		paymentMethods: { type: Array, required: true },
		transactionTotalAmount: { type: Object, required: false },
		isLatinOnlyInput: { type: Boolean, required: true }
	},
	data() {
		return {
			choosingPaymentMethod: false,
			paymentPageDetails: null,
			pageTemplate: {},
			hideDisplay: true,
			showPaymentCard: false,
			activePaymentMethod: 'default',
			active: null,
			activeCard: null,
			cryptoSite: null
		};
	},
	async mounted() {
		await this.setPageTemplate();
		this.cryptoSite = this.$route.query.subSource;
		this.setIsNewPage(true);
	},
	methods: {
		...mapMutations({
			setIsNewPage: 'setIsNewPage'
		}),
		doesMethodExist(method) {
			return this.paymentMethods.filter((item) => item.psp === method)[0];
		},
		async setPageTemplate() {
			this.pageTemplate = await ApiService.publicApi.getDepositTemplate(this.subSource);
		},
		async choosePaymentMethod(paymentMethodId) {
			try {
				this.choosingPaymentMethod = true;
				this.activePaymentMethod = this.active;
				this.showPaymentCard = true;
				this.paymentPageDetails = await ApiService.publicApi.selectPaymentMethod(this.transactionId, paymentMethodId);
				if (this.subSource) {
					this.paymentPageDetails.subSource = this.subSource;
				}
				this.paymentPageDetails.isLatinOnlyInput = this.isLatinOnlyInput;
			} catch (error) {
				if (process.env.NODE_ENV === 'development') {
					console.log(error);
				}
			}
			this.choosingPaymentMethod = false;
		},
		onPaymentMethodCanceled() {
			this.paymentPageDetails = null;
			this.choosingPaymentMethod = false;
			this.showPaymentCard = false;
		},
		async uploadFile() {
			try {
				await ApiService.publicApi.uploadFile({ transactionId: this.paymentPageDetails.transactionId }, { fileName: this.fileObject.name, base64: this.base64 });
				VueService.showToastMessage({ message: this.$t('staticInfo.fileUpload.success'), type: 'success' });
				this.paymentPageDetails = null;
			} catch (error) {
				console.error(error);
				VueService.showToastMessage({ message: this.$t('staticInfo.fileUpload.error'), type: 'error' });
			}
		},
		async onFileSelected(evt) {
			[this.fileObject] = evt.target.files;
			this.base64 = await this.createBase64(this.fileObject);
		},
		createBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(file);
			});
		},
		setActiveCard(id) {
			this.activeCard = id;
		},
		setActivePaymentMethod(activePaymentMethod) {
			this.active = activePaymentMethod;
		},
		async selectPaymentMethod() {
			this.activePaymentMethod = this.active;
			this.showPaymentCard = false;
			this.hideDisplay = true;
			this.choosingPaymentMethod = false;
			this.paymentPageDetails = null;
		},
		async select() {
			if (this.activeCard && this.active) {
				await this.choosePaymentMethod(this.activeCard);
				return;
			}
			this.selectPaymentMethod();
		},
		onBack() {
			this.activePaymentMethod = 'default';
		}
	}
};
</script>

<style module scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&family=Roboto:wght@100;300;400;500;700&display=swap');

.mobile-wrapp {
	font-family: 'Roboto', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100vh;
}
.mobile-header {
	text-align: left;
	padding: 20px;
	color: #fff;
	text-transform: uppercase;
	background: linear-gradient(179.94deg, #260466 0%, #260492 100%);
	height: 168px;
	width: 100%;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.mobile-body {
	padding: 20px;
	padding-top: 100px;
}

.method {
	display: flex;
	align-items: center;
	justify-content: space-around;
	color: #000;
	border: 2px solid #4910e9;
	border-radius: 10px;
	margin-bottom: 10px;
	height: 60px;
}

.card {
	display: flex;
	align-items: center;
	padding: 10px;
	color: #000;
	border: 2px solid #4910e9;
	border-radius: 10px;
	margin-bottom: 10px;
}

.card-image-wrap {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	color: #000;
	border: 2px solid #4910e9;
	border-radius: 10px;
	margin-bottom: 10px;
	overflow: hidden;
	height: 60px;
}

.card-image {
	width: 25%;
}

.border {
	width: 60%;
	height: 2px;
	border-bottom: 2px solid #260466;
	margin: 0 auto;
	margin-bottom: 32px;
}

.amount-section {
	position: absolute;
	display: flex;
	align-items: flex-start;
	padding: 15px;
	justify-content: space-around;
	flex-direction: column;
	background: linear-gradient(179.94deg, #260466 0%, #260492 100%);
	color: #fff;
	top: 190px;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 85%;
	height: 85px;
	border-radius: 10px;
}

.amount-text {
	font-size: 16px;
	font-weight: 400;
	color: #e1e1e1;
}

.amount {
	font-size: 20px;
	font-weight: 600;
}
.mobile-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	background: #fff;
	height: 123px;
	width: 100%;
}

.payment-method-pick-text {
	font-size: 24px;
	color: #2c007c;
	font-weight: 500;
	margin-bottom: 28px;
}

.method-mobile-text {
	width: 100%;
}

.next-btn {
	background: linear-gradient(92.06deg, #260466 0%, #360492 100%);
	color: #fff !important;
	width: 220px;
}

.back-btn {
	border-color: #3017ac !important;
	color: #3017ac !important;
	width: 220px;
}

.mobile-hello {
	font-weight: 400;
	font-size: 18px;
}

.mobile-name {
	font-weight: 500;
	font-size: 24px;
}

.active {
	color: #fff;
	background: linear-gradient(92.06deg, #260466 0%, #360492 100%);
}

.title-container {
	display: flex;
	width: 100%;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	align-content: center;
	gap: 10px;
}
.title-container > img {
	flex: 0 0 12%;
}
.container {
	max-width: 200px;
	min-width: 100px;
}
.titleWrapper {
	background-image: linear-gradient(135deg, var(--primaryColor), var(--secondaryColor), var(--tertiaryColor)) !important;
}
.text-wrapper {
	background: #d8d8d8;
	height: 90vh;
	width: 100%;
	overflow: hidden;
	border-radius: 20px;
}
.left_col {
	background: linear-gradient(179.94deg, #260466 0%, #260492 100%);
	/* background: green; */
	border-radius: 20px;
	color: #e1e1e1;
	display: flex;
	align-items: center;
	padding: 50px;
	position: relative;
	flex-direction: column;
	justify-content: space-between;
}
.text-title {
	background: linear-gradient(90.55deg, #260466 0%, #260492 100%);
	width: 100%;
	margin: auto;
	color: white;
	text-align: left;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}

.right-col {
	background: linear-gradient(296.83deg, #d8d8d8 0%, rgba(216, 216, 216, 0.6) 100%), linear-gradient(179.71deg, #d8d8d8 0%, rgba(216, 216, 216, 0.6) 100%);
	box-shadow: 0px 4px 4px 0px #00000040;
	padding: 100px;
}

.pick-method {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 20px;
}

.method-text {
	background: linear-gradient(180deg, #270588 0%, #270590 100%);
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	height: 28px;
}

.ft-18 {
	font-size: 18px;
}

.ft-32 {
	font-size: 32px;
}

.br-4 {
	border-radius: 4px;
}

.img {
	display: flex;
	margin-right: 3px;
	text-align: center;
	align-items: center;
	justify-items: center;
}
/* width */
::-webkit-scrollbar {
	width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
</style>
