<template>
	<v-container>
		<v-row
			:style="`--primaryColor:${pageTemplate.primaryColor};--secondaryColor:${pageTemplate.secondaryColor};--tertiaryColor:${pageTemplate.tertiaryColor};`"
			:class="$style.titleWrapper"
		>
			<v-col class="white--text text-h4" cols="4" sm="4" md="4">
				<span v-if="Object.keys(transactionTotalAmount).length" style="font-size: 14px"> {{ transactionTotalAmount.leadName }}</span>
			</v-col>

			<v-col class="white--text text-h4" cols="4" sm="4" md="4">
				<span v-if="pageTemplate.configurations !== null && Object.keys(pageTemplate.configurations).includes(subSource)" style="font-size: 14px">
					{{ pageTemplate.configurations[subSource].name }}</span
				>
				<div :class="$style['title-container']" v-else>
					<img v-if="pageTemplate.logo" :src="pageTemplate.logo" style="object-fit: contain; width: 100px; height: 60px" alt="" />
					<!-- <span>{{ pageTemplate.title || this.$t('home.selectMethodTitle') }}</span> -->
				</div>
			</v-col>
			<v-col class="white--text" cols="4" sm="4" md="4">
				<span v-if="Object.keys(transactionTotalAmount).length" style="font-size: 14px"><i>Amount:</i> {{ transactionTotalAmount.amount.toFixed(2) }}</span>
				<v-divider />
				<span style="font-size: 14px"><i>Currency:</i> {{ transactionTotalAmount.currency }}</span>
			</v-col>
		</v-row>
		<v-row justify="center" style="background-color: ghostwhite">
			<v-col class="white--text text-h4" cols="9" sm="10" md="11">
				<v-divider />
				<div :class="$style['title-container']">
					<span>
						<i>
							<h6 style="color: gray">{{ this.$t('home.selectMethodTitle') }}</h6>
						</i>
					</span>
				</div>
				<v-divider />
			</v-col>
		</v-row>
		<v-row justify="center" style="background-color: ghostwhite">
			<v-col v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" cols="4" md="3">
				<v-container :class="$style.container">
					<v-card
						:loading="choosingPaymentMethod === paymentMethod.id"
						:disabled="!!choosingPaymentMethod"
						:shaped="pageTemplate.shaped"
						:outlined="pageTemplate.outlined"
						:style="pageTemplate.style"
						@click="choosePaymentMethod(paymentMethod.id)"
						:qa-e2e="paymentMethod.name"
					>
						<v-img :src="paymentMethod.image" />
						<v-divider />
						<span class="text-caption">{{ paymentMethod.name }}</span>
					</v-card>
				</v-container>
			</v-col>
		</v-row>
		<v-row
			:style="`--primaryColor:${pageTemplate.primaryColor};--secondaryColor:${pageTemplate.secondaryColor};--tertiaryColor:${pageTemplate.tertiaryColor};`"
			:class="$style.titleWrapper"
		>
			<v-col class="white--text text-caption" v-if="pageTemplate.footerText !== null">
				{{ pageTemplate.footerText }}
				<br />
				{{ $t('home.customerServiceDesc') }}
				<br />
				{{ $t('home.copyright') }}
			</v-col>

			<v-col class="white--text text-caption" v-else>
				<div v-if="pageTemplate.configurations !== null && Object.keys(pageTemplate.configurations).includes(subSource)">
					{{ pageTemplate.configurations[subSource].footer }}
					<br />
					{{ pageTemplate.configurations[subSource].copyright }}
				</div>
				<div v-else>
					{{ $t('home.customerServiceDesc') }}
					<br />
					{{ $t('home.copyright') }}
				</div>
			</v-col>
		</v-row>
		<payments-page
			v-if="paymentPageDetails"
			:payment-page-details="paymentPageDetails"
			@submit="uploadFile"
			@file-selected="onFileSelected"
			@cancel-payment-method="onPaymentMethodCanceled"
		/>
	</v-container>
</template>

<script>
import ApiService from '../services/ApiService.js';
import VueService from '../services/VueService.js';

export default {
	components: { PaymentsPage: () => import('./PaymentsPage.vue') },
	props: {
		transactionId: { type: String, required: true },
		subSource: { type: String, required: false },
		paymentMethods: { type: Array, required: true },
		transactionTotalAmount: { type: Object, required: false },
		isLatinOnlyInput: { type: Boolean, required: true }
	},
	data() {
		return {
			choosingPaymentMethod: false,
			paymentPageDetails: null,
			pageTemplate: {}
		};
	},
	async mounted() {
		await this.setPageTemplate();
	},
	methods: {
		async setPageTemplate() {
			this.pageTemplate = await ApiService.publicApi.getDepositTemplate(this.subSource);
		},
		async choosePaymentMethod(paymentMethodId) {
			try {
				this.choosingPaymentMethod = true;
				this.paymentPageDetails = await ApiService.publicApi.selectPaymentMethod(this.transactionId, paymentMethodId);
				if (this.subSource) {
					this.paymentPageDetails.subSource = this.subSource;
				}
				this.paymentPageDetails.isLatinOnlyInput = this.isLatinOnlyInput;
			} catch (error) {
				if (process.env.NODE_ENV === 'development') {
					console.log(error);
				}
			}
			this.choosingPaymentMethod = false;
		},
		onPaymentMethodCanceled() {
			this.paymentPageDetails = null;
		},
		async uploadFile() {
			try {
				await ApiService.publicApi.uploadFile({ transactionId: this.paymentPageDetails.transactionId }, { fileName: this.fileObject.name, base64: this.base64 });
				VueService.showToastMessage({ message: this.$t('staticInfo.fileUpload.success'), type: 'success' });
				this.paymentPageDetails = null;
			} catch (error) {
				console.error(error);
				VueService.showToastMessage({ message: this.$t('staticInfo.fileUpload.error'), type: 'error' });
			}
		},
		async onFileSelected(evt) {
			[this.fileObject] = evt.target.files;
			this.base64 = await this.createBase64(this.fileObject);
		},
		createBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(file);
			});
		}
	}
};
</script>

<style module>
.title-container {
	display: flex;
	width: 100%;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	align-content: center;
	gap: 10px;
}
.title-container > img {
	flex: 0 0 12%;
}
.container {
	max-width: 200px;
	min-width: 100px;
}
.titleWrapper {
	background-image: linear-gradient(135deg, var(--primaryColor), var(--secondaryColor), var(--tertiaryColor)) !important;
}
</style>
