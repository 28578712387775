<template>
	<v-dialog :value="true" persistent min-width="500" max-width="800">
		<v-card style="background-color: ghostwhite" :class="{ maxHeight: component !== 'FireblocksBridge' }">
			<v-card-title class="pa-0 ma-0 justify-end">
				<v-menu v-if="component === 'CreditCard'" offset-y>
					<template #activator="{ on, attrs }">
						<v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
							<v-icon>mdi-web</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item v-for="(item, index) in languages" :key="index" @click="onLangSelect(item)">
							<v-list-item-title>{{ item.name }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
				<div class="mainTotalAmount" v-if="component === 'CreditCard'">
					<span class="package" v-if="paymentPageDetails.source === 'BrandsMaster'"> Educational Package: </span>
					<span class="amount">{{ paymentPageDetails.amount }} </span>
					<span class="currency">{{ paymentPageDetails.currency }}</span>
				</div>
				<v-btn icon large class="mr-2" @click="closeDialog">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-divider class="mb-3" />
			<v-card-text class="px-0">
				<component
					:key="componentKey"
					:is="component"
					v-bind="paymentPageDetails"
					:response="response"
					@submit="onSubmit"
					@file-selected="onFileSelected"
					@payment-processed="onPaymentProcessed"
					@payment-rejected="onPaymentRejected"
					@payment-error="onPaymentError"
					@payment-submitted="setComponent"
					@paymentProccessedModalDestroy="closeDialog"
				/>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { getPaymentType } from '../shared/paymentPageUtils';
import { getComponents } from '../shared/paymentPageUtils';

export default {
	components: getComponents(),
	props: {
		paymentPageDetails: { type: Object, required: true }
	},
	data() {
		return {
			component: null,
			paymentProcessed: false,
			response: null,
			message: {
				channel: 'PaymentsPro',
				event: ''
			},
			selectedLang: '',
			languages: [
				{ name: 'English', value: 'en' },
				{ name: 'Russian', value: 'ru' },
				{ name: 'Azerbaijanian', value: 'az' },
				{ name: 'Turkish', value: 'tr' },
				{ name: 'French', value: 'fr' },
				{ name: 'Italian', value: 'it' },
				{ name: 'Uzbek', value: 'uz' },
				{ name: 'Portuguese', value: 'pt' },
				{ name: 'Thai', value: 'th' },
				{ name: 'Arabic', value: 'ar' }
			],
			componentKey: 1
		};
	},
	methods: {
		onPaymentProcessed(details) {
			this.paymentProcessed = true;
			this.component = 'PaymentProcessed';
			this.message.event = 'popup-open';
			this.emitMessage();
		},
		onPaymentRejected(details) {
			this.component = 'PaymentRejected';
			this.message.event = 'popup-open';
			this.emitMessage();
		},
		onPaymentError(error) {
			if (process.env.NODE_ENV === 'development') {
				console.log(error);
			}
		},
		setComponent({ pspType, response }) {
			switch (pspType) {
				case 'PayBoutique':
					this.component = 'ThreeDAuthenticationForm';
					break;
				case 'SolidPayments':
					this.component = 'SolidThreeDAuth';
					break;
				case 'Aliumpay':
					this.component = 'AliumThreeDAuth';
					break;
				case 'Virtualpay':
					this.component = 'Virtualpay';
					break;
				default:
					this.component = 'IframeDynamic';
			}
			this.response = response;
		},
		emitMessage() {
			window.parent.postMessage(JSON.stringify(this.message), '*');
		},
		closeDialog() {
			if (this.component === 'PaymentProcessed' || this.component === 'PaymentRejected') {
				this.message.event = 'popup-closed';
				this.emitMessage();
			}
			this.$emit('cancel-payment-method');
		},
		onSubmit() {
			this.$emit('submit');
		},
		onFileSelected(file) {
			this.$emit('file-selected', file);
		},
		onLangSelect(item) {
			this.selectedLang = item.value;
			this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, language: this.selectedLang } });
			this.$i18n.locale = this.selectedLang;
			this.componentKey += 1;
		}
	},
	mounted() {
		this.component = getPaymentType(this.paymentPageDetails);
		window.onbeforeunload = () => {
			if (this.component === 'PaymentProcessed' || this.component === 'PaymentRejected') {
				this.message.event = 'popup-closed';
				this.emitMessage();
			}
		};
	}
};
</script>

<style scoped>
span.currency {
	font-size: 14px;
	margin-top: 0.3em;
}
span.amount {
	font-size: 25px;
}
.mainTotalAmount {
	margin: 0 auto;
}
.maxHeight {
	max-height: 800;
}
span.package {
	font-size: 15px;
	margin-top: 0.3em;
}
</style>
