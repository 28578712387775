<template>
	<v-container :class="$style.container">
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-row v-if="showFrame">
			<p>Payment Page is being loaded in new tab</p>
		</v-row>
		<v-row v-else style="col-lg-12 col-md-12 col-sm-12 text-xs-center">
			<v-card class="mx-auto">
				<br />
				<v-col>
					<input-field required clearable :props="{ items: languages, styleType: 'outlined' }" v-model="languageCode" label="Language" type="select" />
				</v-col>
				<v-col v-if="this.method === 'pix'">
					<input-field required v-model="documentNumber" type="special-text" label="Identity Document Number" />
				</v-col>
				<br />

				<v-card-actions>
					<v-btn text color="deep-purple accent-4" @click="onSubmit" qa-e2e="continueButton"> Continue </v-btn>
				</v-card-actions>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			response: '',
			loadingMessage: 'We are still working on it...',
			loading: false,
			showFrame: false,
			languageCode: 'en',
			documentNumber: ''
		};
	},
	computed: {
		languages() {
			const languageCodes = ['en', 'es', 'pt', 'zh'];
			return _.map(languageCodes, (languageCode) => ({ text: languageCode, value: languageCode }));
		}
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true },
		method: { type: String, required: true }
	},
	methods: {
		async onSubmit() {
			try {
				this.loading = true;
				const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
					languageCode: this.languageCode,
					documentNumber: this.documentNumber
				});
				this.response = response;
				this.loading = false;
				this.showFrame = true;
				window.open(this.response);
			} catch (err) {
				this.loading = false;
				this.showFrame = false;
			}
		}
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 500px;
}
.container {
	max-width: 570px;
	margin: auto;
	width: 100%;
}
</style>
