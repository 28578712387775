<template>
	<v-container>
		<v-overlay v-if="isLoading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-row>
			<v-card class="mx-auto">
				<v-col>
					<p>Please wait while payment page is being loaded on new tab</p>
				</v-col>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true }
	},
	data() {
		return {
			isLoading: false
		};
	},
	methods: {
		openPopUp(urlToOpen) {
			const popup_window = window.open(
				urlToOpen,
				'myWindow',
				'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, fullscreen=yes'
			);
			try {
				popup_window.focus();
			} catch {
				alert('Pop-up Blocker is enabled! Please add this site to your exception list.');
			}
		},
		async loadCheckoutPage() {
			const checkoutUrl = await ApiService.publicApi.createPaymentRequest(this.transactionId);
			const delay = (ms) => new Promise((res) => setTimeout(res, ms));
			this.openPopUp(checkoutUrl);
			await delay(5000);
		}
	},
	async created() {
		this.isLoading = true;
		await this.loadCheckoutPage();
		this.isLoading = false;
	}
};
</script>
