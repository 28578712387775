<template>
	<div :class="$style['mobile-wrapp']">
		<div>
			<div :class="$style['mobile-header']">
				<div @click="onBack" :class="[$style['mobile-hello'], progressValue === 100 ? $style['w-100'] : '', progressValue === 100 ? 'text-center' : '']">
					<i v-if="progressValue !== 100" class="fa fa-chevron-left" /> <span class="ml-3" :class="$style['ft-18']">Checkout</span>
				</div>
				<div v-if="progressValue !== 100" :class="[$style['mobile-name'], $style['ft-18']]">
					{{ transactionTotalAmount.amount.toFixed(2) }} {{ transactionTotalAmount.currency }}
				</div>
			</div>
			<div :class="$style['progress']">
				<div :class="$style['method-icon']">
					<div class="relative">
						<Icon width="1.4em" height="1.4em" :class="$style['progress-icon-1']" icon="iconoir:credit-cards" />
						<div :class="$style['method-progress-text']">Method</div>
					</div>
				</div>
				<div :class="$style['method-icon-1']">
					<div class="relative">
						<Icon width="1.5em" height="1.5em" :class="[$style['progress-icon-2'], progressValue >= 50 ? $style['activated'] : '']" icon="solar:card-2-linear" />
						<div :class="$style['method-progress-text']">Information</div>
					</div>
				</div>
				<div :class="$style['method-icon-2']">
					<div class="relative">
						<Icon
							width="1.5em"
							height="1.5em"
							:class="[$style['progress-icon-3'], progressValue === 100 ? $style['activated'] : '']"
							class="mr-2"
							icon="mynaui:bookmark-check"
						/>
						<div :class="$style['method-progress-text']">Status</div>
					</div>
				</div>
				<v-progress-linear color="#6814E8" :value="progressValue"></v-progress-linear>
			</div>
			<v-container v-if="loading && activePaymentMethod === 'wire'" :class="$style['mt-150']" class="d-flex align-center">
				<v-row class="justify-space-around">
					<v-col cols="auto">
						<v-progress-circular :class="$style['blue']" class="mb-5" indeterminate size="50" />
						<br />
						<h2 :class="$style['loading-info']" style="text-align: center">Loading....</h2>
						<p :class="$style['loading-info']" style="text-align: center">Please wait, It will take a moment</p>
					</v-col>
				</v-row>
			</v-container>

			<div v-else :class="$style['mobile-body']">
				<div v-if="activePaymentMethod === 'default'">
					<div class="text-left" :class="[$style['payment-method-pick-text'], $style['ft-20']]">Payment method</div>
					<div class="mb-5" :class="[$style['.preffred-method'], $style['ft-18']]">Pick your preferred method of payment</div>
					<div>
						<div :class="[$style['method'], active === 'credit_card' ? $style['active'] : '']" @click="setActivePaymentMethod('credit_card')">Credit Card</div>
						<div v-if="doesMethodExist('StaticInfo')" :class="[$style['method'], active === 'wire' ? $style['active'] : '']" @click="setActivePaymentMethod('wire')">
							Wire
						</div>
						<div
							v-if="doesMethodExist('FireblocksBridge')"
							:class="[$style['method'], active === 'fireblocks_bridge' ? $style['active'] : '']"
							@click="setActivePaymentMethod('fireblocks_bridge')"
						>
							Crypto
						</div>
					</div>
				</div>
				<div v-else>
					<div v-if="hideDisplay && !showPaymentCard">
						<div v-if="activePaymentMethod === 'credit_card'">
							<div class="text-left mb-5" :class="[$style['payment-method-pick-text'], $style['ft-20']]">Payment method</div>
							<div class="text-left mb-5" :class="$style['payment-method']">{{ getPaymentMethod }}</div>
							<v-row class="mb-5">
								<v-col>
									<v-card class="pl-5 pr-5">
										<v-row class="align-center">
											<v-col class="text-left d-flex align-center">
												<Icon class="mb-1" color="#777777" icon="solar:card-linear" width="2em" height="2em" />
												<span :class="[$style['card-text-color'], $style['ft-16']]" class="ml-2">{{ getPaymentMethod }}</span>
											</v-col>
											<v-col class="text-right">
												<span @click="onChange" :class="[$style['purple-color'], $style['fw-500'], $style['pointer'], $style['ft-18']]" class="pointer">{{
													$t('paymentOptions.change')
												}}</span>
											</v-col>
										</v-row>
									</v-card>
								</v-col>
							</v-row>
							<div
								:class="[$style['card-image-wrap'], activeCard === paymentMethod.id ? $style['active'] : '', index % 2 === 0 ? 'mr-1' : 'ml-1']"
								@click="setActiveCard(paymentMethod.id)"
								v-for="(paymentMethod, index) in paymentMethods.filter((item) => item.psp !== 'StaticInfo' && item.psp !== 'FireblocksBridge')"
								:key="paymentMethod.id"
							>
								<div :class="$style['card-image']">
									<v-img :src="paymentMethod.image" width="100%" height="100%" contain />
								</div>
								<div :class="$style['method-mobile-text']">
									{{ paymentMethod.name }}
								</div>
							</div>
						</div>
						<div v-if="activePaymentMethod === 'wire'">
							<div class="text-left mb-5" :class="[$style['payment-method-pick-text'], $style['ft-20']]">Payment method</div>
							<div class="text-left mb-5" :class="$style['payment-method']">{{ getPaymentMethod }}</div>
							<v-row class="mb-5">
								<v-col>
									<v-card class="pl-5 pr-5">
										<v-row class="align-center">
											<v-col class="text-left d-flex align-center">
												<Icon class="mb-1" color="#777777" icon="solar:card-linear" width="2em" height="2em" />
												<span :class="[$style['card-text-color'], $style['ft-16']]" class="ml-2">{{ getPaymentMethod }}</span>
											</v-col>
											<v-col class="text-right">
												<span @click="onChange" :class="[$style['purple-color'], $style['fw-500'], $style['pointer'], $style['ft-18']]" class="pointer">{{
													$t('paymentOptions.change')
												}}</span>
											</v-col>
										</v-row>
									</v-card>
								</v-col>
							</v-row>
							<div
								:class="[$style['card-image-wrap'], activeCard === paymentMethod.id ? $style['active'] : '', index % 2 === 0 ? 'mr-1' : 'ml-1']"
								@click="setActiveCard(paymentMethod.id)"
								v-for="(paymentMethod, index) in paymentMethods.filter((item) => item.psp === 'StaticInfo')"
								:key="paymentMethod.id"
							>
								<div :class="$style['card-image']">
									<v-img :src="paymentMethod.image" width="100%" height="100%" contain />
								</div>
								<div :class="$style['method-mobile-text']">
									{{ paymentMethod.name }}
								</div>
							</div>
						</div>
						<div v-if="activePaymentMethod === 'fireblocks_bridge'">
							<div class="text-left mb-5" :class="[$style['payment-method-pick-text'], $style['ft-20']]">Payment method</div>
							<div class="text-left mb-5" :class="$style['payment-method']">{{ getPaymentMethod }}</div>
							<v-row class="mb-5">
								<v-col>
									<v-card class="pl-5 pr-5">
										<v-row class="align-center">
											<v-col class="text-left">
												<v-icon class="mb-1" size="24">mdi-credit-card</v-icon>
												<span :class="[$style['card-text-color'], $style['ft-16']]" class="ml-2">{{ getPaymentMethod }}</span>
											</v-col>
											<v-col class="text-right">
												<span @click="onChange" :class="[$style['purple-color'], $style['fw-500'], $style['pointer'], $style['ft-18']]" class="pointer">{{
													$t('paymentOptions.change')
												}}</span>
											</v-col>
										</v-row>
									</v-card>
								</v-col>
							</v-row>
							<div
								:class="[$style['card-image-wrap'], activeCard === paymentMethod.id ? $style['active'] : '', index % 2 === 0 ? 'mr-1' : 'ml-1']"
								@click="setActiveCard(paymentMethod.id)"
								v-for="(paymentMethod, index) in paymentMethods.filter((item) => item.psp === 'FireblocksBridge')"
								:key="paymentMethod.id"
							>
								<div :class="$style['card-image']">
									<v-img :src="paymentMethod.image" width="100%" height="100%" contain />
								</div>
								<div :class="$style['method-mobile-text']">
									{{ paymentMethod.name }}
								</div>
							</div>
						</div>
					</div>
					<div v-if="showPaymentCard">
						<div v-if="paymentPageDetails && !loading && paymentPageDetails.pspType === 'StaticInfo'">
							<div v-if="!statusPending" class="text-left" :class="[$style['payment-method-pick-text'], $style['ft-20']]">Payment Information</div>
							<v-row v-if="!statusPending">
								<v-col class="pl-0 pr-0">
									<v-row>
										<v-col class="pr-0 pt-2" cols="2">
											<Icon icon="solar:shield-check-linear" width="1.8em" height="1.8em" color="#37D476" />
										</v-col>
										<v-col class="text-left pt-1 pl-0">
											<div v-if="paymentPageDetails.pspType !== 'StaticInfo'" :class="[$style['ft-20'], $style['grey']]">
												{{ $t('paymentOptions.creditCard') }}
											</div>
											<div v-else :class="[$style['ft-20'], $style['grey']]">{{ $t('paymentOptions.wireTransfer') }}</div>
											<div :class="$style['psp-text']">
												{{ $t('paymentOptions.using') }} {{ paymentPageDetails.pspType }} {{ $t('paymentOptions.system') }}
											</div>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
							<v-row v-if="paymentPageDetails.pspType !== 'StaticInfo' && !statusPending">
								<v-col class="text-left pb-0">
									<span :class="[$style['ft-16'], $style['black']]">{{ $t('paymentOptions.pleaseEnterCardInfo') }}</span>
								</v-col>
							</v-row>
						</div>
						<payments-page-mobile
							:key="key"
							v-if="paymentPageDetails"
							:payment-page-details="paymentPageDetails"
							@submit="uploadFile"
							@file-selected="onFileSelected"
							@cancel-payment-method="onPaymentMethodCanceled"
							@payment-finished="onPaymentFinished"
							@payment-submited="onPaymentSubmit"
						/>
						<component v-else :is="getComponent" />
					</div>
				</div>
			</div>
		</div>
		<div v-if="!showPaymentCard" :class="$style['mobile-footer']">
			<div style="width: 90%">
				<v-btn @click="select" :class="$style['next-btn']" :large="true">Continue</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';
import ApiService from '../services/ApiService.js';
import { Icon } from '@iconify/vue2';

export default {
	components: {
		PaymentsPageMobile: () => import('./PaymentsPageMobileTemplate3.vue'),
		PaymentProcessed: () => import('./PaymentResponses/PaymentProcessedTemplate3.vue'),
		PaymentRejected: () => import('./PaymentResponses/PaymentRejectedTemplate3.vue'),
		Icon
	},
	props: {
		transactionId: { type: String, required: true },
		subSource: { type: String, required: false },
		paymentMethods: { type: Array, required: true },
		transactionTotalAmount: { type: Object, required: false },
		isLatinOnlyInput: { type: Boolean, required: true }
	},
	data() {
		return {
			choosingPaymentMethod: false,
			paymentPageDetails: null,
			pageTemplate: {},
			hideDisplay: true,
			showPaymentCard: false,
			activePaymentMethod: 'default',
			active: null,
			activeCard: null,
			cryptoSite: null,
			step: 0,
			key: 0,
			statusPending: false,
			progressValue: 15,
			loading: false,
			paymentProcessed: false,
			paymentRejected: false
		};
	},
	async mounted() {
		await this.setPageTemplate();
		this.cryptoSite = this.$route.query.subSource;
		this.setIsNewPage(true);
	},
	computed: {
		getPaymentMethod() {
			if (this.activePaymentMethod === 'credit_card') {
				return 'Credit card';
			}
			if (this.activePaymentMethod === 'wire') {
				return 'Wire transfer';
			}
			return '';
		},
		getComponent() {
			if (this.paymentProcessed) {
				return 'PaymentProcessed';
			}
			if (this.paymentRejected) {
				return 'PaymentRejected';
			}
			return '';
		}
	},
	methods: {
		...mapMutations({
			setIsNewPage: 'setIsNewPage'
		}),
		doesMethodExist(method) {
			return this.paymentMethods.filter((item) => item.psp === method)[0];
		},
		async setPageTemplate() {
			this.pageTemplate = await ApiService.publicApi.getDepositTemplate(this.subSource);
		},
		async choosePaymentMethod(paymentMethodId, setPending = false) {
			if (setPending) {
				this.statusPending = true;
			}
			try {
				this.paymentPageDetails = await ApiService.publicApi.selectPaymentMethod(this.transactionId, paymentMethodId);
				this.key++;
				this.choosingPaymentMethod = true;
				this.activePaymentMethod = this.active;
				this.showPaymentCard = true;
				if (this.subSource) {
					this.paymentPageDetails.subSource = this.subSource;
				}
				this.paymentPageDetails.isLatinOnlyInput = this.isLatinOnlyInput;
			} catch (error) {
				if (process.env.NODE_ENV === 'development') {
					console.log(error);
				}
			}
			this.choosingPaymentMethod = false;
		},
		onPaymentMethodCanceled() {
			this.paymentPageDetails = null;
			this.choosingPaymentMethod = false;
			this.showPaymentCard = false;
		},
		async uploadFile() {
			this.loading = true;
			this.statusPending = true;
			try {
				await ApiService.publicApi.uploadFile({ transactionId: this.paymentPageDetails.transactionId }, { fileName: this.fileObject.name, base64: this.base64 });
				//	VueService.showToastMessage({ message: this.$t('staticInfo.fileUpload.success'), type: 'success' });
				this.paymentPageDetails = null;
				this.paymentProcessed = true;
				this.loading = false;
				this.progressValue = 100;
			} catch (error) {
				console.error(error);
				//	VueService.showToastMessage({ message: this.$t('staticInfo.fileUpload.error'), type: 'error' });
				this.loading = false;
				this.paymentRejected = true;
			}
		},
		async onFileSelected(evt) {
			[this.fileObject] = evt.target.files;
			this.base64 = await this.createBase64(this.fileObject);
		},
		createBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(file);
			});
		},
		setActiveCard(id) {
			this.activeCard = id;
		},
		setActivePaymentMethod(activePaymentMethod) {
			this.active = activePaymentMethod;
		},
		async selectPaymentMethod() {
			this.activePaymentMethod = this.active;
			this.showPaymentCard = false;
			this.hideDisplay = true;
			this.choosingPaymentMethod = false;
			this.paymentPageDetails = null;
		},
		async select() {
			if (this.activeCard && this.active) {
				await this.choosePaymentMethod(this.activeCard);
				this.step = 2;
				this.progressValue = 75;
				return;
			}
			this.step = 1;
			this.progressValue = 50;
			this.activeCard = false;
			this.selectPaymentMethod();
		},
		onBack() {
			if (this.activeCard && this.active && this.step === 2) {
				this.step = 1;
				this.progressValue = 50;
				this.showPaymentCard = false;
				this.activeCard = false;
				this.statusPending = false;
				return;
			}
			this.step = 0;
			this.progressValue = 15;
			this.activePaymentMethod = 'default';
			this.showPaymentCard = false;
			this.activeCard = false;
			this.active = false;
			this.statusPending = false;
		},
		onPaymentFinished(details) {
			this.step = 2;
			this.statusPending = true;
			this.progressValue = 100;
		},
		onPaymentSubmit() {
			this.statusPending = true;
			this.progressValue = 100;
			this.loading = false;
		},
		onChange() {
			this.step = 0;
			this.progressValue = 15;
			this.activePaymentMethod = 'default';
			this.showPaymentCard = false;
			this.activeCard = false;
			this.active = false;
			this.statusPending = false;
		}
	}
};
</script>

<style module scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&family=Roboto:wght@100;300;400;500;700&display=swap');

.mobile-wrapp {
	font-family: 'Roboto', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100vh;
}
.mobile-header {
	text-align: left;
	padding: 20px;
	color: #fff;
	background: linear-gradient(285.21deg, #6814e8 0%, #9756fb 100%);
	height: 83px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mobile-body {
	padding: 20px;
}

.method {
	display: flex;
	align-items: center;
	justify-content: space-around;
	color: #000;
	border-radius: 10px;
	margin-bottom: 10px;
	height: 60px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	padding: 20px;
}

.card {
	display: flex;
	align-items: center;
	padding: 10px;
	color: #000;
	border: 2px solid #4910e9;
	border-radius: 10px;
	margin-bottom: 10px;
}

.card-image-wrap {
	display: inline-block;
	color: #000;
	border-radius: 10px;
	margin-bottom: 10px;
	overflow: hidden;
	height: 110px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	width: 48%;
	padding: 10px 15px;
}

.card-image {
	object-fit: cover;
	width: 90%;
	height: 80%;
}

.border {
	width: 60%;
	height: 2px;
	border-bottom: 2px solid #260466;
	margin: 0 auto;
	margin-bottom: 32px;
}

.amount-section {
	position: absolute;
	display: flex;
	align-items: flex-start;
	padding: 15px;
	justify-content: space-around;
	flex-direction: column;
	background: linear-gradient(179.94deg, #260466 0%, #260492 100%);
	color: #fff;
	top: 190px;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 85%;
	height: 85px;
	border-radius: 10px;
}

.amount-text {
	font-size: 16px;
	font-weight: 400;
	color: #e1e1e1;
}

.amount {
	font-size: 20px;
	font-weight: 600;
}
.mobile-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	background: #fff;
	height: 123px;
	width: 100%;
}

.payment-method-pick-text {
	color: #6814e8;
	font-weight: 500;
	margin-bottom: 28px;
}

.payment-method {
	font-size: 18px;
	color: #666666;
}

.preffred-method {
	color: #777777;
	font-weight: 500;
	margin-bottom: 28px;
}

.method-mobile-text {
	width: 100%;
}

.next-btn {
	background: linear-gradient(285.21deg, #6814e8 0%, #9756fb 100%);
	color: #fff !important;
	width: 100%;
}

.back-btn {
	border-color: #3017ac !important;
	color: #3017ac !important;
	width: 220px;
}

.mobile-hello {
	font-weight: 400;
	font-size: 18px;
}

.mobile-name {
	font-weight: 500;
	font-size: 24px;
	background: linear-gradient(285.21deg, #6f1eec 0%, #8637ff 100%);
	padding: 12px;
}

.active {
	color: #fff;
	background: linear-gradient(285.21deg, #6814e8 0%, #9756fb 100%);
}

.title-container {
	display: flex;
	width: 100%;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	align-content: center;
	gap: 10px;
}
.title-container > img {
	flex: 0 0 12%;
}
.container {
	max-width: 200px;
	min-width: 100px;
}
.titleWrapper {
	background-image: linear-gradient(135deg, var(--primaryColor), var(--secondaryColor), var(--tertiaryColor)) !important;
}
.text-wrapper {
	background: #d8d8d8;
	height: 90vh;
	width: 100%;
	overflow: hidden;
	border-radius: 20px;
}
.left_col {
	background: linear-gradient(179.94deg, #260466 0%, #260492 100%);
	/* background: green; */
	border-radius: 20px;
	color: #e1e1e1;
	display: flex;
	align-items: center;
	padding: 50px;
	position: relative;
	flex-direction: column;
	justify-content: space-between;
}
.text-title {
	background: linear-gradient(90.55deg, #260466 0%, #260492 100%);
	width: 100%;
	margin: auto;
	color: white;
	text-align: left;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}

.right-col {
	background: linear-gradient(296.83deg, #d8d8d8 0%, rgba(216, 216, 216, 0.6) 100%), linear-gradient(179.71deg, #d8d8d8 0%, rgba(216, 216, 216, 0.6) 100%);
	box-shadow: 0px 4px 4px 0px #00000040;
	padding: 100px;
}

.pick-method {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 20px;
}

.method-text {
	background: linear-gradient(180deg, #270588 0%, #270590 100%);
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	height: 28px;
}

.loading-info {
	color: #5e5e5e;
}

.blue {
	color: #7b61ff;
}

.ft-18 {
	font-size: 18px;
}

.ft-20 {
	font-size: 20px;
}

.ft-32 {
	font-size: 32px;
}

.br-4 {
	border-radius: 4px;
}

.grey {
	color: #777777;
}

.ft-16 {
	font-size: 16px;
}

.ft-20 {
	font-size: 20px;
}

.black {
	color: #000;
}

.mt-150 {
	margin-top: 150px;
}

.progress {
	position: relative;
	padding: 30px;
	margin: 0 15px;
}

.method-icon {
	position: absolute;
	top: 20px;
	left: 0px;
	z-index: 1;
}

.method-icon-1 {
	position: absolute;
	top: 20px;
	left: calc(50% - 40px);
	z-index: 1;
}

.method-icon-2 {
	position: absolute;
	top: 20px;
	right: 0;
	z-index: 1;
}

.method-progress-text {
	font-size: 12px;
}

.progress-icon-1 {
	color: #ffffff;
	background: #6814e8;
	padding: 7px;
	border-radius: 50%;
	z-index: 1;
}
.progress-icon-2 {
	color: #858585;
	padding: 7px;
	border-radius: 50%;
	background: #fff;
	z-index: 1;
}
.progress-icon-3 {
	color: #858585;
	padding: 7px;
	border-radius: 50%;
	background: #fff;
	z-index: 1;
}

.activated {
	background: #6814e8;
	color: #ffffff;
}

.card-text-color {
	color: #777777 !important;
}

.fw-500 {
	font-weight: 500;
}

.purple-color {
	color: #6814e8;
}

.w-100 {
	width: 100%;
}

.img {
	display: flex;
	margin-right: 3px;
	text-align: center;
	align-items: center;
	justify-items: center;
}

.psp-text {
	color: #9999;
	line-height: 10px;
	font-size: 14px;
}
/* width */
::-webkit-scrollbar {
	width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
</style>
