<!-- We need to change this in the next sprint -->
<template>
	<yapily-auth />
</template>

<script>
export default {
	components: {
		YapilyAuth: () => import('../../components/Yapily/YapilyAuth.vue')
	}
};
</script>
