import englishTranslations from './en.json';
import russianTranslations from './ru.json';
import arabicTranslations from './ar.json';
import azerbaijaniTranslations from './az.json';
import germanTranslations from './de.json';
import spanishTranslations from './es.json';
import frenchTranslations from './fr.json';
import italianTranslations from './it.json';
import portugueseTranslations from './pt.json';
import thaiTranslations from './th.json';
import turkishTranslations from './tr.json';
import ukrainianTranslations from './uk.json';
import kazakhTranslations from './kk.json';
import jspanishTranslations from './ja.json';
import uzbekTranslations from './uz.json';

export default {
	en: { ...englishTranslations },
	ar: { ...arabicTranslations },
	az: { ...azerbaijaniTranslations },
	de: { ...germanTranslations },
	es: { ...spanishTranslations },
	fr: { ...frenchTranslations },
	it: { ...italianTranslations },
	pt: { ...portugueseTranslations },
	ru: { ...russianTranslations },
	th: { ...thaiTranslations },
	tr: { ...turkishTranslations },
	uk: { ...ukrainianTranslations },
	kk: { ...kazakhTranslations },
	ja: { ...jspanishTranslations },
	uz: { ...uzbekTranslations }
};