<template>
	<!-- <iframe v-if="response" :src="response"></iframe> -->
	<div>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<div v-if="!hideSelection">
			<input-field
				class="mb-3"
				required
				v-model="cardType"
				type="radio"
				:label="$t('dsgIntl.ChooseCardType')"
				:props="{
					radios: [
						{ label: $t('dsgIntl.masterCard'), value: 'masterCard' },
						{ label: $t('dsgIntl.visaCard'), value: 'visaCard' }
					],
					styleType: 'outlined'
				}"
			/>

			<v-btn text color="primary" @click="sendTheForm" :disabled="!cardType">
				{{ $t('debitCard.submit') }}
				<v-icon right>mdi-check</v-icon>
			</v-btn>
		</div>

		<div>
			<form method="POST" :action="transactionDetails.endPoint" ref="form" target="_blank">
				<fieldset class="forFotm">
					<input type="hidden" name="memberId" v-model="transactionDetails.memberId" />
					<input type="hidden" name="checksum" v-model="transactionDetails.checksum" />
					<input type="hidden" name="totype" v-model="transactionDetails.totype" />
					<input type="hidden" name="merchantTransactionId" v-model="transactionDetails.merchantTransactionId" />
					<input type="hidden" name="amount" v-model="transactionDetails.amount" />
					<input type="hidden" name="TMPL_AMOUNT" v-model="transactionDetails.TMPL_AMOUNT" />
					<input type="hidden" name="orderDescription" v-model="transactionDetails.orderDescription" />
					<input type="hidden" name="merchantRedirectUrl" v-model="transactionDetails.merchantRedirectUrl" />
					<input type="hidden" name="notificationUrl" v-model="transactionDetails.notificationUrl" />
					<input type="hidden" name="country" v-model="transactionDetails.country" />
					<input type="hidden" name="city" v-model="transactionDetails.city" />
					<input type="hidden" name="state" v-model="transactionDetails.state" />
					<input type="hidden" name="postcode" v-model="transactionDetails.postcode" />
					<input type="hidden" name="street" v-model="transactionDetails.street" />
					<input type="hidden" name="telnocc" v-model="transactionDetails.telnocc" />
					<input type="hidden" name="phone" v-model="transactionDetails.phone" />
					<input type="hidden" name="email" v-model="transactionDetails.email" />
					<input type="hidden" name="ip" v-model="transactionDetails.ip" />
					<input type="hidden" name="currency" v-model="transactionDetails.currency" />
					<input type="hidden" name="TMPL_CURRENCY" v-model="transactionDetails.TMPL_CURRENCY" />
					<input type="hidden" name="terminalid" v-model="transactionDetails.terminalid" />
					<input type="hidden" name="reservedField1" v-model="transactionDetails.reservedField1" />
					<input type="hidden" name="paymentMode" v-model="transactionDetails.paymentMode" />
					<input type="hidden" name="paymentBrand" v-model="transactionDetails.paymentBrand" />
				</fieldset>
			</form>
			<p v-if="transactionDetails.memberId">The payment page loaded on new tab.</p>
		</div>
	</div>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			transactionDetails: {},
			loading: false,
			cardType: '',
			hideSelection: false
		};
	},
	props: {
		transactionId: { type: String, required: true }
	},

	methods: {
		handleDisplayErrorMessage(message) {
			const { errorCard } = this.$refs;
			const errorCardMessage = errorCard?.querySelector('.message');
			errorCard.classList.add('errorCard.visible');
			errorCardMessage.innerText = message;
		},
		async onSubmit() {
			try {
				this.loading = true;
				this.transactionDetails = await ApiService.publicApi.createPaymentRequest(this.transactionId, null, this.cardType);
			} catch (error) {
				this.loading = false;
			}
		},
		async sendTheForm() {
			await this.onSubmit();
			this.loading = false;
			this.hideSelection = true;
			this.$refs.form.submit();
		}
	}
};
</script>

<style>
.dsgIntl * {
	font-family: Inter, Open Sans, Segoe UI, sans-serif;
	font-size: 16px;
	font-weight: 500;
}

.dsgIntl .container {
	background-color: #fff;
	box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	padding: 3px;
}

.errorCard {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin-top: 20px;
	padding: 0 15px;
	font-size: 13px;
	opacity: 0;
	transform: translateY(10px);
	transition-property: opacity, transform;
	transition-duration: 0.35s;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.errorCard.visible {
	opacity: 1;
	transform: none;
}
fieldset.displayNone {
	display: none;
}
.forFotm {
	display: none;
}
</style>
