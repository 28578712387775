<template>
	<v-container>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<iframe class v-if="response" :src="response"></iframe>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			response: null,
			loading: true
		};
	},
	props: {
		transactionId: { type: String, required: true }
	},
	async created() {
		this.response = await ApiService.publicApi.createPaymentBilletRequest(this.transactionId);
		this.loading = false;
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 500px;
}
</style>
