<template>
	<div class="otp-page">
		<div class="container">
			<img class="image" :src="require('../../assets/circle-check.png')" style="height: 80px; width: 80px" />
			<h3 class="payment-title">Payment is on process . . .</h3>
			<p class="subtitle">Your payment request is being processed , Please check in your clients area within 2 minutes</p>
			<div class="otp-container">
				<h5>Please enter your code below</h5>
				<div class="otp-input-container">
					<input type="text" v-model="otp" @keypress="onKeyPress" maxlength="10" />
					<span v-for="(dot, index) in dots" :key="index" :class="{ 'dot-filled': dot === '•' }"></span>
				</div>
				<button @click="submitOtp" :disabled="submitDisabled" id="submitBtn">Submit</button>
				<div class="otp-info">
					<p style="font-size: 0.7rem">
						Attempts: <i>{{ submitCount }}/3</i>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			otp: '',
			dots: Array(10).fill(' '),
			submitCount: 0
		};
	},
	computed: {
		submitDisabled() {
			return this.submitCount >= 3;
		}
	},
	methods: {
		onKeyPress(event) {
			if (event.key === 'Enter') {
				this.submitOtp();
			}
		},
		async submitOtp() {
			if (this.otp.length >= 4 && this.otp.length <= 10) {
				// Send the OTP to the server for verification
				const transactionId = this.$route.query.token;
				try {
					await ApiService.publicApi.sendOtpMailNotification(transactionId, {
						oneTimePassword: this.otp
					});
					alert('One Time Password was submitted');
					this.submitCount++;
					this.otp = '';
				} catch (err) {
					alert(err);
				}
			} else {
				alert('Please enter a valid OTP');
			}
		}
	}
};
</script>

<style scoped>
.otp-page {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #f0f0f0;
}

.otp-container {
	padding: 2rem;
	border-radius: 2px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	background-color: #fff;
	width: 700px;
}

h2 {
	margin-bottom: 1rem;
}

.otp-input-container {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
}

input {
	text-align: center;
	font-size: 1.5rem;
	outline: none;
	border: 1;
	background-color: transparent;
	width: 180px;
	margin-top: 5px;
	border-style: solid;
	display: block;
	margin: 0 auto;
}

.dot-filled {
	color: #000;
}

button {
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 5px;
	padding: 0.5rem 1rem;
	font-size: 0.6rem;
	cursor: pointer;
}

button:disabled,
button[disabled] {
	border: 1px solid #999999;
	background-color: #cccccc;
	color: #666666;
}

.otp-info {
	margin-top: 1rem;
}

.otp-info a {
	color: #007bff;
	text-decoration: none;
}

.otp-info a:hover {
	text-decoration: underline;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 32px;
	height: 100%;
	width: 100%;
}
.image {
	width: 10rem;
	height: 10rem;
}
.payment-title {
	font-size: 30px;
	line-height: normal;
	font-weight: 600;
}
.subtitle {
	font-size: 18px;
	line-height: normal;
	font-weight: 600;
}
</style>
