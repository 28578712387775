import { render, staticRenderFns } from "./PaymentRejectedNew.vue?vue&type=template&id=86d3c6e8&scoped=true"
var script = {}
import style0 from "./PaymentRejectedNew.vue?vue&type=style&index=0&id=86d3c6e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86d3c6e8",
  null
  
)

export default component.exports