import Vue from 'vue';
import Vuex from 'vuex';
import Vuetify from 'vuetify';
import VueHead from 'vue-head';
import 'vuetify/dist/vuetify.min.css';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import MessageSystem from '@utopiatechcorp/message-system';
import InputFields from '@utopiatechcorp/input-fields';
import App from './App.vue';
import { createStore } from './store/index.js';
import { createRouter } from './router/index.js';
import Translations from './locales/index.js';
import VueService from './services/VueService.js';
import './services/ApiService.js';

const main = async () => {
	Vue.config.productionTip = false;
	Vue.use(Vuex);
	Vue.use(VueRouter);
	Vue.use(Vuetify);
	Vue.use(VueI18n);
	Vue.use(VueHead);
	Vue.use(MessageSystem);
	Vue.use(InputFields);

	const store = createStore();
	VueService.store = store;
	const router = await createRouter();
	const i18n = new VueI18n({ locale: 'en', messages: Translations });
	const vue = new Vue({ router, store, render: (h) => h(App), vuetify: new Vuetify(), i18n });
	VueService.loadVueInstance(vue);
	vue.$mount('#app');
};

main();
