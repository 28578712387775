var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.loading)?_c('v-overlay',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}})],1):_vm._e(),(_vm.showFrame)?_c('v-row',[_c('v-card',{staticClass:"mx-auto"},[_c('v-col',{attrs:{"col":"12"}},[_c('p',[_vm._v("Payment page is being loaded on new tab.")])])],1)],1):_c('v-row',{staticStyle:{}},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"width":"600px"}},[_c('v-col',{staticStyle:{"margin-top":"30px"}},[_c('VTooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('input-field',_vm._g(_vm._b({attrs:{"required":"","clearable":"","props":{ items: _vm.paymentMethods, styleType: 'outlined' },"label":"Payment Method","type":"select"},model:{value:(_vm.customer.paymentMethod),callback:function ($$v) {_vm.$set(_vm.customer, "paymentMethod", $$v)},expression:"customer.paymentMethod"}},'input-field',attrs,false),on))]}}])},[_vm._v(" Select preferred payment method below: ")])],1),_c('v-col',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('input-field',_vm._g(_vm._b({attrs:{"type":"special-text","label":"Account","props":{ items: _vm.paymentMethods, styleType: 'outlined' }},model:{value:(_vm.customer.financialAccount),callback:function ($$v) {_vm.$set(_vm.customer, "financialAccount", $$v)},expression:"customer.financialAccount"}},'input-field',attrs,false),on))]}}])},[_vm._v(" Financial account number (papara account, IBAN, etc.) ")])],1),(_vm.customer.paymentMethod === 'upi')?_c('v-col',[_c('VTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('input-field',_vm._g(_vm._b({attrs:{"type":"special-text","label":"IFSC","props":{ items: _vm.paymentMethods, styleType: 'outlined' }},model:{value:(_vm.customer.ifsc),callback:function ($$v) {_vm.$set(_vm.customer, "ifsc", $$v)},expression:"customer.ifsc"}},'input-field',attrs,false),on))]}}],null,false,3011981145)},[_vm._v(" Indian Financial System Code. It's an 11-digit code written in an alphanumeric format, and it identifies the branches in the National Electronic Funds Transfer (NEFT) network. ")])],1):_vm._e(),_c('v-card-actions',[_c('v-col',[_c('v-btn',{attrs:{"text":"","color":"deep-purple accent-4","disabled":!_vm.customer.paymentMethod},on:{"click":_vm.onSubmit}},[_vm._v(" Continue ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }