<template>
	<v-app :class="$style.app">
		<v-main>
			<router-view />
		</v-main>
		<message-container />
	</v-app>
</template>

<script>
export default {
	created() {
		this.initSession();
	},
	methods: {
		initSession() {
			this.$i18n.locale = this.$route?.query?.language;
		}
	}
};
</script>

<style module>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
.app {
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

body::-webkit-scrollbar {
	display: none;
}
</style>
