<template>
	<v-container>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>

		<v-row v-if="!showFrame" style="col-lg-12 col-md-12 col-sm-12 text-xs-center">
			<v-card class="mx-auto" v-if="method === 'apm'">
				<v-col>
					<input-field required clearable :props="{ items: currencies, styleType: 'outlined' }" v-model="selectedCurrency" label="Deposit Currency" type="select" />
				</v-col>
				<v-col>
					<input-field required clearable :props="{ items: depositChannels, styleType: 'outlined' }" v-model="depositChannel" label="Deposit Channel" type="select" />
				</v-col>
				<v-card-actions>
					<v-btn text color="deep-purple accent-4" @click="onSubmit" qa-e2e="continueButton"> Continue </v-btn>
				</v-card-actions>
			</v-card>
			<v-card class="mx-auto" v-if="method === 'crypto'">
				<v-col>
					<input-field required clearable :props="{ items: cryptoChannel, styleType: 'outlined' }" v-model="depositChannel" label="Deposit Channel" type="select" />
				</v-col>
				<v-col>
					<input-field required clearable :props="{ items: currencies, styleType: 'outlined' }" v-model="selectedCurrency" label="Deposit Currency" type="select" />
				</v-col>
				<v-card-actions>
					<v-btn text color="deep-purple accent-4" @click="onSubmit" qa-e2e="continueButton"> Continue </v-btn>
				</v-card-actions>
			</v-card>
		</v-row>
		<!-- Response Block -->
		<v-row v-else style="col-lg-12 col-md-12 col-sm-12 text-xs-center">
			<v-card class="mx-auto">
				<v-col>
					<p>Please wait while payment page is being loaded on new tab</p>
				</v-col>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			response: '',
			selectedCurrency: '',
			depositChannel: '',
			loading: false,
			showFrame: false,
			allCurrencies: ['THB', 'VND', 'INR'],
			depositChannelss: [
				{ text: 'eBanking', value: '1' },
				{ text: 'Promptpay QR', value: '3' },
				{ text: 'Momo Pay', value: '10' },
				{ text: 'True Wallet', value: '22' },
				{ text: 'VN QR Pay', value: '12' },
				{ text: 'UPI', value: '27' }
			],
			cryptoChannel: [
				{ text: 'USDT-ERC', value: '6' },
				{ text: 'USDT-TRC20', value: '13' }
			]
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true },
		method: { type: String, required: true },
		source: { type: String, required: true }
	},
	computed: {
		depositChannels() {
			const chosenCurrency = this.selectedCurrency;
			switch (chosenCurrency) {
				case 'THB': {
					return [
						{ text: 'eBanking', value: '1' },
						{ text: 'Promptpay QR', value: '3' },
						{ text: 'True Wallet', value: '22' }
					];
				}
				case 'VND': {
					return [
						{ text: 'eBanking', value: '1' },
						{ text: 'Momo Pay', value: '10' }
					];
				}
				case 'INR': {
					return [{ text: 'UPI', value: '27' }];
				}
				default:
					return [{ text: 'eBanking', value: '1' }];
			}
		},
		currencies() {
			return _.map(this.allCurrencies, (currency) => ({ text: currency, value: currency }));
		}
	},
	methods: {
		async onSubmit() {
			this.loading = true;
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, { selectedCurrency: this.selectedCurrency, depositChannels: this.depositChannel });
			this.response = response;
			this.loading = false;
			this.showFrame = true;
			window.open(this.response);
		}
	},
	created() {
		if (this.source === 'Cryptos') {
			this.allCurrencies = ['INR'];
		}
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 500px;
}
</style>
