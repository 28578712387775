<template>
	<v-container>
		<v-overlay v-if="loading">
			<v-progress-circular indeterminate size="50" />
		</v-overlay>
		<v-row>
			<v-card class="mx-auto">
				<v-col>
					<p>Please wait while payment page is being loaded on new tab</p>
				</v-col>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import ApiService from '../../services/ApiService.js';

export default {
	data() {
		return {
			response: '',
			loading: false
		};
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true }
	},
	methods: {
		async setUrlResponse() {
			this.loading = true;
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId);
			this.response = response;
			this.openPopUp(this.response);
			this.loading = false;
		},
		// eslint-disable-next-line space-before-function-paren
		openPopUp: function (urlToOpen) {
			const popup_window = window.open(
				urlToOpen,
				'myWindow',
				'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, fullscreen=yes'
			);
			try {
				popup_window.focus();
			} catch (e) {
				alert('Pop-up Blocker is enabled! Please add this site to your exception list.');
			}
		}
	},
	created() {
		this.setUrlResponse();
	}
};
</script>

<style module>
iframe {
	width: 100%;
	height: 500px;
}
</style>
