<template>
	<!-- <v-dialog :value="true" persistent min-width="500" max-width="800">
		
	</v-dialog> -->

	<!-- <v-card-title class="pa-0 ma-0 justify-end">
			<div class="mainTotalAmount" v-if="component === 'CreditCard'">
				<span class="package" v-if="paymentPageDetails.source === 'BrandsMaster'"> Educational Package: </span>
				<span class="amount">{{ paymentPageDetails.amount }} </span>
				<span class="currency">{{ paymentPageDetails.currency }}</span>
			</div>
			<v-btn icon large class="mr-2" @click="closeDialog">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-card-title> -->
	<!-- <v-divider class="mb-3" /> -->

	<div class="px-0">
		<component
			:is="component"
			v-bind="paymentPageDetails"
			:response="response"
			@submit="onSubmit"
			@file-selected="onFileSelected"
			@payment-processed="onPaymentProcessed"
			@payment-rejected="onPaymentRejected"
			@payment-error="onPaymentError"
			@payment-submitted="setComponent"
			@paymentProccessedModalDestroy="closeDialog"
			@cancel-payment-method="onPaymentMethodCanceled"
			@loading="onLoading"
		/>
		<!--<div class="mobile-footer">
			<div>
				<v-btn @click="onPaymentMethodCanceled" outlined class="back-btn" :large="true">Back</v-btn>
			</div>
		</div> -->
	</div>
</template>

<script>
import { getPaymentType } from '../shared/paymentPageUtils';
import { getComponents } from '../shared/paymentPageUtils';

export default {
	components: getComponents(),
	props: {
		paymentPageDetails: { type: Object, required: true }
	},
	data() {
		return {
			component: null,
			paymentProcessed: false,
			response: null,
			message: {
				channel: 'PaymentsPro',
				event: ''
			}
		};
	},
	methods: {
		onPaymentProcessed(details) {
			this.paymentProcessed = true;
			this.component = 'PaymentProcessedTemplate3';
			this.message.event = 'popup-open';
			this.emitMessage(details);
		},
		onPaymentRejected(details) {
			this.component = 'PaymentRejectedTemplate3';
			this.message.event = 'popup-open';
			this.emitMessage(details);
		},
		onPaymentError(error) {
			if (process.env.NODE_ENV === 'development') {
				console.log(error);
			}
		},
		setComponent({ pspType, response }) {
			if (response && response.includes('OneTimePassword')) {
				response = response.replace('OneTimePassword', 'OneTimePasswordTemplate3');
			}
			switch (pspType) {
				case 'PayBoutique':
					this.component = 'ThreeDAuthenticationForm';
					break;
				case 'SolidPayments':
					this.component = 'SolidThreeDAuth';
					break;
				case 'Aliumpay':
					this.component = 'AliumThreeDAuth';
					break;
				case 'Virtualpay':
					this.component = 'Virtualpay';
					break;
				default:
					this.component = 'IframeDynamic';
			}
			this.$emit('payment-submited');
			this.$emit('loading', false);
			if (response === '/paymentProcessed') {
				response = '/paymentProcessedTemplate3';
			}
			if (response === '/paymentRejected') {
				response = '/paymentRejectedTemplate3';
			}
			this.response = response;
		},
		emitMessage(details) {
			window.parent.postMessage(JSON.stringify(this.message), '*');
			this.$emit('payment-finished', details);
		},
		closeDialog() {
			if (this.component === 'PaymentProcessedTemplate3' || this.component === 'PaymentRejectedTemplate3') {
				this.message.event = 'popup-closed';
				this.emitMessage();
			}
			this.$emit('cancel-payment-method');
		},
		onSubmit() {
			this.$emit('submit');
		},
		onFileSelected(file) {
			this.$emit('file-selected', file);
		},
		onPaymentMethodCanceled() {
			this.$emit('cancel-payment-method');
		},
		onBack() {
			this.$emit('cancel-payment-method');
		},
		onLoading(isLoading) {
			this.$emit('loading', isLoading);
		}
	},
	mounted() {
		this.component = getPaymentType(this.paymentPageDetails);
		window.onbeforeunload = () => {
			if (this.component === 'PaymentProcessedTemplate3' || this.component === 'PaymentRejectedTemplate3') {
				this.message.event = 'popup-closed';
				this.emitMessage();
			}
		};
		window.addEventListener('message', (event) => {
			if (event.data === 'otpSubmitted') {
				this.onPaymentProcessed();
			}
		});
		//	this.component = 'PaymentRejectedNew';
	}
};
</script>

<style scoped>
span.currency {
	font-size: 14px;
	margin-top: 0.3em;
}
.theme--light.v-sheet {
	background: transparent !important;
}
span.amount {
	font-size: 25px;
}
.mainTotalAmount {
	margin: 0 auto;
}
.maxHeight {
	max-height: 800;
}
span.package {
	font-size: 15px;
	margin-top: 0.3em;
}

.back-btn {
	border-color: #3017ac !important;
	color: #3017ac !important;
	width: 220px;
}

.mobile-footer {
	margin-top: 30px;
	display: flex;
	justify-content: space-around;
}
</style>
