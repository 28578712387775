<template>
	<v-container>
		<form method="POST" :action="data.endpoint" ref="form">
			<fieldset>
				<input type="hidden" name="PaReq" v-model="data.PaReq" />
				<input type="hidden" name="MD" v-model="data.MD" />
				<input type="hidden" name="TermUrl" v-model="data.TermUrl" />
			</fieldset>
		</form>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			data: null
		};
	},
	props: {
		response: { type: Object, required: true }
	},
	methods: {
		async transformSource() {
			this.data = this.response;
		}
	},
	async created() {
		await this.transformSource();
		this.$refs.form.submit();
	}
};
</script>
