import { render, staticRenderFns } from "./QrPayments.vue?vue&type=template&id=6392efe6"
var script = {}
import style0 from "./QrPayments.vue?vue&type=style&index=0&id=6392efe6&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports