<template>
	<v-container class="container">
		<div class="inner">

			<!-- <img class="image" :src="require('../../assets/circle-check.png')" /> -->
			<Icon icon="fluent:shield-task-24-regular" width="8em" height="8em" style="color: #37D476"  />
			<h3 class="payment-title mt-5 mb-5">{{ $t('paymentProcessed.congratulations') }}</h3>
			<div class="subtitle mb-5">{{ $t('paymentProcessed.transactionSubmitted') }}</div>
			<!--<div class="subtitle mb-5">{{ $t('paymentProcessed.emailSent') }} <strong>email@gmail.com</strong></div> -->
			<!-- <h3 class="payment-title">{{ $t('paymentProcessed.title') }}</h3> -->
			<!-- <p class="subtitle">{{ $t('paymentProcessed.description') }}</p> -->

		</div>
	</v-container>
</template>

<script>
import { Icon } from '@iconify/vue2';

export default {
	components: {
		Icon
	}
}

</script>

<style scoped> 
.container {
	position: relative;
}

.inner {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	width: 100%;
}

.payment-title {
	color: #5E5E5E;
	font-size: 28px;
	line-height: normal;
}
.subtitle {
	color: #5E5E5E;
	font-size: 20px;
	margin: 0;
}
.gradient {
	background: linear-gradient(92deg, #260466 0%, #360492 100%);
}

button {
	background: linear-gradient(285.21deg, #6814E8 0%, #9756FB 100%);
	color: #fff;
	padding: 10px;
	border-radius: 5px;
	font-size: 20px;
	width: 293px;
}

@media screen and (max-width: 450px) {
	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 32px;
		background-color: transparent;
		height: 100%;
		width: 100%;
		text-wrap: balance;
	}
	.image {
		width: 5rem;
		height: 5rem;
	}
	.subtitle {
		font-size: 16px;
		font-weight: 400;
	}
}
</style>
