<template>
	<v-container>
		<!-- Loading Screen -->
		<v-row v-if="loading">
			<v-col>
				<v-container>
					<v-row>
						<v-col cols="12" align="center">
							<v-progress-circular indeterminate color="green" size="150"></v-progress-circular>
						</v-col>
						<v-col>
							<h4 class="payment-title" style="margin: 10px">Please wait for a while...</h4>
							<p class="subtitle">We are waiting to hear back from {{ bankName }} Please wait a second.</p>
						</v-col>
					</v-row>
				</v-container>
			</v-col>
		</v-row>
		<v-container v-if="!error && !loading">
			<!-- LOG IN SCREEN -->
			<v-row align="center" style="margin: 10px" v-if="step === 1">
				<v-col>
					<v-card>
						<v-container>
							<v-row>
								<v-col>
									<img :src="href" height="210px" width="100%" />
								</v-col>
								<v-col>
									<h4 class="payment-title" style="margin: 10px">Connect to your {{ bankName }} account.</h4>
									<v-text-field type="text" outlined dense label="Username" v-model="paymentDetails.userCredentials.id" :rules="[rules.required]"></v-text-field>
									<v-text-field
										type="password"
										outlined
										dense
										label="Password"
										v-model="paymentDetails.userCredentials.password"
										:rules="[rules.required]"
									></v-text-field>
									<v-btn text color="primary" @click="nextStep" :disabled="isDisabled">LOGIN</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
			</v-row>
			<!-- INPUT SCREEN -->
			<v-row v-if="step === 2">
				<v-col>
					<v-card>
						<v-container>
							<v-row>
								<v-col cols="12" align="center">
									<v-icon size="100">mdi-badge-account</v-icon>
								</v-col>
								<v-col>
									<h4 class="payment-title" style="margin: 10px">Please enter your bank information</h4>
									<v-text-field type="text" outlined dense label="IBAN" v-model="paymentDetails.iban" :rules="[rules.required]"> </v-text-field>
									<p class="subtitle">A payment of {{ amount.toFixed(2) }} {{ currency }} from your {{ bankName }} account will be initiated in the next step</p>
									<v-btn text color="primary" @click="embeddedAuth" :disabled="!paymentDetails.iban"> Initiate Payment </v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
			</v-row>
			<!-- CHOOSE SCA SCREEN -->
			<v-row v-if="scaStatus === 'AWAITING_SCA_METHOD'">
				<v-col>
					<v-card>
						<v-container>
							<v-row>
								<v-col cols="12" align="center">
									<v-icon size="100">mdi-shield-lock-outline</v-icon>
								</v-col>
								<v-col cols="12">
									<h4 class="payment-title" style="margin: 10px">Select your authentication method</h4>
								</v-col>
								<v-col>
									<v-container>
										<v-row>
											<v-col col="12">
												<v-expansion-panels>
													<v-expansion-panel v-for="(item, index) in scaMethods" :key="index">
														<v-expansion-panel-header @click="updateScaMethod(item)">
															<b>{{ item.type }}</b
															><br />{{ item.description }}</v-expansion-panel-header
														>
													</v-expansion-panel>
												</v-expansion-panels>
											</v-col>
										</v-row>
									</v-container>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
			</v-row>
			<!-- OTP SCREEN -->
			<v-row v-if="scaStatus === 'AWAITING_SCA_CODE'">
				<v-col>
					<v-card>
						<v-container>
							<v-row>
								<v-col cols="12" align="center">
									<v-icon size="100">mdi-shield-lock-outline</v-icon>
								</v-col>
								<v-col>
									<h4 class="payment-title" style="margin: 5px">Verification Code</h4>
									<v-text-field type="text" outlined dense label="Code" v-model="paymentDetails.scaCode" :rules="[rules.required]"> </v-text-field>
									<p class="subtitle" style="margin: 5px; font-size: 12px">
										Please confirm this connection via the verification code sent by your bank. It may have arrived by per email, SMS, push notification, or any
										different method.
									</p>
									<v-btn text color="primary" @click="updateScaCode" :disabled="!paymentDetails.scaCode">Authenticate</v-btn>
								</v-col>
							</v-row>
						</v-container>
					</v-card>
				</v-col>
			</v-row>

			<v-row v-if="scaStatus === 'AWAITING_DECOUPLED_AUTHORIZATION'">
				<v-col>
					<decoupled-auth :transactionId="transactionId" :id="paymentDetails.consentId" @success="handleDecoupledAuth" />
				</v-col>
			</v-row>
		</v-container>
		<v-container v-if="completed">
			<status-page :transactionId="transactionId" :consent="paymentDetails.consent" :id="paymentDetails.consentId" />
		</v-container>
		<v-container v-if="error">
			<error-page :transactionId="transactionId" :error="errorInfo" />
		</v-container>
	</v-container>
</template>

<script>
import _ from 'lodash';
import ApiService from '../../services/ApiService.js';

export default {
	props: {
		transactionId: { type: String, required: false },
		amount: { type: Number, required: false },
		currency: { type: String, required: false },
		reference: { type: String, required: false },
		bank: { type: String, required: false },
		payee: { type: Object, required: false },
		bankItems: { type: Array, required: false }
	},
	data() {
		return {
			step: 1,
			completed: false,
			error: false,
			errorInfo: {},
			loading: false,
			scaMethods: [],
			scaStatus: null,
			paymentDetails: {
				userCredentials: {
					id: null,
					password: null
				},
				iban: null,
				scaCode: null,
				consentId: null,
				consent: null,
				selectedScaMethod: null
			},
			rules: {
				required: (value) => !!value || 'Required.'
			}
		};
	},
	components: {
		'status-page': () => import('./StatusPage.vue'),
		'error-page': () => import('./ErrorPage.vue'),
		'decoupled-auth': () => import('./DecoupledAuth.vue')
	},
	computed: {
		href() {
			return _.find(this.bankItems, { value: this.bank })?.icon;
		},
		bankName() {
			return _.find(this.bankItems, { value: this.bank })?.text;
		},
		isDisabled() {
			return !this.paymentDetails.userCredentials.id || !this.paymentDetails.userCredentials.password;
		}
	},
	methods: {
		nextStep() {
			this.step++;
		},
		async updateScaMethod(item) {
			this.loading = true;
			this.paymentDetails.selectedScaMethod = item;
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				selectedInstitutions: this.bank,
				action: 'update_scamethod',
				paymentDetails: this.paymentDetails
			});
			if (!response.success) {
				this.error = true;
				this.errorInfo = response.error;
				this.loading = false;
				return;
			}
			this.scaStatus = response.data.status;
			this.paymentDetails.scaCode = response.data.code;
			this.loading = false;
		},
		async updateScaCode() {
			this.loading = true;
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				selectedInstitutions: this.bank,
				action: 'update_scacode',
				paymentDetails: this.paymentDetails
			});
			if (!response.success) {
				this.error = true;
				this.errorInfo = response.error;
				this.loading = false;
				return;
			}
			this.paymentDetails.consent = response.data.consentToken;
			this.scaStatus = response.data.status;
			if (response.data.status === 'AUTHORIZED') await this.createSinglePayment(this.paymentDetails.consent);
			this.loading = false;
		},
		async embeddedAuth() {
			this.loading = true;
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				selectedInstitutions: this.bank,
				action: 'create_embedded_authorization',
				paymentDetails: this.paymentDetails
			});
			if (!response.success) {
				this.error = true;
				this.errorInfo = response.error;
				this.loading = false;
				return;
			}
			this.scaStatus = response.data.status;
			this.paymentDetails.consentId = response.data.id;
			this.scaMethods = response.data.scaMethods;
			this.step++;
			this.loading = false;
		},
		async createSinglePayment(consent) {
			const response = await ApiService.publicApi.createPaymentRequest(this.transactionId, {
				selectedInstitutions: this.bank,
				action: 'single_payment',
				paymentDetails: this.paymentDetails
			});
			this.paymentDetails.consentId = response.data.id;
			this.completed = true;
			return response;
		},
		async handleDecoupledAuth(authorizationDetails) {
			const { status, consentToken } = authorizationDetails;
			if (status === 'AUTHORIZED') {
				this.scaStatus = status;
				this.paymentDetails.consent = consentToken;
				this.loading = true;
				await this.createSinglePayment();
				this.loading = false;
			}
		}
	}
};
</script>
