<template>
	<v-container>
		<v-row>
			<v-col>
				<p style="text-align: left; font-size: 12px">
					We have partnered with Yapily Connect to securely initiate a payment from your account at <b>{{ bankName }}</b
					>.
				</p>
				<v-divider></v-divider>
				<v-container>
					<v-row>
						<v-col cols="2" style="text-align: left">
							<v-container>
								<v-row>
									<v-col cols="12" align="center">
										<v-icon size="60">mdi-cash-check</v-icon>
									</v-col>
								</v-row>
							</v-container>
						</v-col>
						<v-col cols="10">
							<p style="text-align: left; font-size: 15px; margin: 1px"><b>Payment Details:</b></p>
							<p style="text-align: left; font-size: 13px; margin: 1px">Total Amount: {{ amount.toFixed(2) }} {{ currency }}</p>
							<p style="text-align: left; font-size: 13px; margin: 1px">Payment Reference: {{ reference }}</p>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="2" style="text-align: left">
							<v-container>
								<v-row>
									<v-col cols="12" align="center">
										<v-icon size="65">mdi-bank-transfer</v-icon>
									</v-col>
								</v-row>
							</v-container>
						</v-col>
						<v-col cols="10" style="text-align: left" v-if="currency === 'EUR'">
							<p style="text-align: left; font-size: 15px; margin: 1px"><b>Beneficiary Details:</b></p>
							<p style="text-align: left; font-size: 13px; margin: 1px">Beneficiary Name: {{ payeeDetails.beneficiaryName }}</p>
							<p style="text-align: left; font-size: 13px; margin: 1px">IBAN: {{ payeeDetails.iban }}</p>
						</v-col>
						<v-col cols="10" style="text-align: left" v-if="currency === 'GBP'">
							<p style="text-align: left; font-size: 15px; margin: 1px"><b>Beneficiary Details:</b></p>
							<p style="text-align: left; font-size: 13px; margin: 1px">Beneficiary Name: {{ payeeDetails.beneficiaryName }}</p>
							<p style="text-align: left; font-size: 13px; margin: 1px">Account Number: {{ payeeDetails.accountNumber }}</p>
							<p style="text-align: left; font-size: 13px; margin: 1px">Sortcode: {{ payeeDetails.sortCode }}</p>
						</v-col>
					</v-row>
				</v-container>
				<v-divider></v-divider>
				<p style="text-align: left; font-size: 11px; margin-top: 15px">
					By using the service, you agree to Yapily Connect's <a :href="terms" target="_blank">Terms & Conditions</a> and
					<a :href="privacy" target="_blank">Privacy Notice</a>.
				</p>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import _ from 'lodash';

export default {
	props: {
		transactionId: { type: String, required: false },
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		bank: { type: String, required: false },
		bankItems: { type: Array, required: false },
		payee: { type: Object, required: false },
		reference: { type: String, required: false }
	},
	data() {
		return {
			response: null
		};
	},
	computed: {
		bankName() {
			return _.find(this.bankItems, { value: this.bank })?.text;
		},
		payeeDetails() {
			return _.omit(this.payee, ['beneficiaryCountry', 'beneficiaryZip', 'beneficiaryCity', 'swift', 'bankName', 'bankAddress']);
		},
		terms() {
			return 'https://www.yapily.com/legal/end-user-terms';
		},
		privacy() {
			return 'https://www.yapily.com/legal/privacy-policy';
		}
	}
};
</script>
