<template>
	<v-container fluid>
		<v-row style="margin-bottom: 25px">
			<v-card class="mx-auto" style="width: 750px">
				<img :src="require('../../assets/open-bank.png')" style="width: 200px; height: 70px; margin-top: 10px; margin-bottom: 10px" />
			</v-card>
		</v-row>
		<v-row>
			<v-card class="mx-auto" style="width: 750px">
				<v-stepper v-model="step">
					<v-stepper-header style="margin: 10px">
						<v-stepper-step :complete="step > 1" step="1" color="green">Bank Selection</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step :complete="step > 2" step="2" color="green">Consent</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step :complete="step > 3" step="3" color="green">Payment Processing</v-stepper-step>
					</v-stepper-header>
					<v-stepper-items>
						<v-stepper-content step="1">
							<p>
								<i>Please select your Bank</i>
							</p>
							<v-autocomplete
								append-icon="mdi-magnify"
								v-model="selectedInstitutions"
								:items="institutions"
								densed
								autofocus
								clearable
								item-text="text"
								item-value="value"
								auto-select-first
							>
								<template v-slot:item="{ item }">
									<v-list-item-avatar>
										<img :src="item.icon" alt="Item image" />
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>{{ item.text }}</v-list-item-title>
									</v-list-item-content>
								</template>
								<v-divider slot="prepend-item" class="mt-2" />
							</v-autocomplete>

							<v-btn color="primary" @click="nextStep" :disabled="!selectedInstitutions">Continue</v-btn>
						</v-stepper-content>
						<v-stepper-content step="2">
							<v-container>
								<consent-screen
									:transactionId="transactionId"
									:payee="beneficiaryDetails"
									:reference="reference"
									:bank="selectedInstitutions"
									:bankItems="institutions"
									:amount="amount"
									:currency="currency"
								/>
							</v-container>
							<v-btn color="warning" @click="prevStep" style="margin: 5px">Cancel</v-btn>
							<v-btn color="primary" @click="nextStep" style="margin: 5px">Confirm</v-btn>
						</v-stepper-content>
						<v-stepper-content step="3">
							<v-container v-if="isRedirectFlow && !isPreAuthorization">
								<single-payments :bank="selectedInstitutions" :transactionId="transactionId" :amount="amount" :currency="currency" :reference="reference" />
							</v-container>
							<v-container v-if="!isRedirectFlow && !isPreAuthorization">
								<embedded-payments
									:bank="selectedInstitutions"
									:transactionId="transactionId"
									:amount="amount"
									:currency="currency"
									:reference="reference"
									:bankItems="institutions"
								/>
							</v-container>
							<v-container v-if="isPreAuthorization">
								<pre-authorization :bank="selectedInstitutions" :transactionId="transactionId" :amount="amount" :currency="currency" :reference="reference" />
							</v-container>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			step: 1,
			response: null,
			loading: false,
			selectedInstitutions: null,
			isRedirectFlow: false,
			isPreAuthorization: false
		};
	},
	watch: {
		selectedInstitutions(val) {
			this.isRedirectFlow = this.institutions.filter((bank) => bank.value === val).some((bank) => bank.features.includes('INITIATE_DOMESTIC_SINGLE_PAYMENT'));
			this.isPreAuthorization = val === 'n26' ? true : false;
		}
	},
	components: {
		'single-payments': () => import('../Yapily/SinglePayments.vue'),
		'embedded-payments': () => import('../Yapily/EmbeddedPayments.vue'),
		'consent-screen': () => import('../Yapily/ConsentScreen.vue'),
		'pre-authorization': () => import('../Yapily/PreAuthorization.vue')
	},
	props: {
		amount: { type: Number, required: true },
		currency: { type: String, required: true },
		transactionId: { type: String, required: true },
		pspType: { type: String, required: true },
		institutions: { type: Array, required: false },
		country: { type: String, required: true },
		reference: { type: String, required: true },
		beneficiaryDetails: { type: Object, required: true }
	},
	methods: {
		nextStep() {
			this.step++;
		},
		prevStep() {
			this.step--;
		}
	}
};
</script>
